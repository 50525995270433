//Start a React component
import React, { useEffect, useState } from 'react';
import { getStatusFromOpter } from '../databasefunctions/matches';
import { Tag, TagLabel } from '@chakra-ui/react';

type Props = {
    tracking_number: string;
};

type StatusInfo = {
    status: string;
    orderId: string;
    message: string;
    statusDate: string;
    statusDescription: string;
}

function MyComponent(props: Props) {

    const [status, setStatus] = useState("");
    const { tracking_number } = props;

  // Use Effect Hook to fetch data when the component mounts and updates
  useEffect(() => {
    (async () => {
      await getStatusFromOpter(
        tracking_number
      )
        .then(
          (response) => {
            console.log("Status info", response?.data);
            if(response && 'orderId' in response.data) {
                setStatus(response.data.status);
            } else {
                setStatus('');
            }
          }
        )
        .catch((error) => {
     
          console.log(error)
        });
    })();
  }, [tracking_number]);


  return (
    <span>
      {status !== '' ? (<Tag size={'md'} variant='outline' colorScheme='blue'>
      <TagLabel>{status}</TagLabel></Tag>) : ''}
    </span>
  );
}

export default MyComponent;
