import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import UserInfo from "./UserInfo";
/**********************************************************
/ NAME: Footer()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a footer with links to the 
 * About and Contact pages, user information, and a copyright notice.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * - Box: A container that wraps the entire footer
 * - Container: A container that wraps the footer content
 * - Stack: A container that wraps the links and the copyright notice
 * - Link: A component that renders a link to a specified URL
 * - Text: A component that renders text
 * - UserInfo: A component that renders user information
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a footer.
/*********************************************************/
export const Footer = () => (
  <Box
    bg={useColorModeValue("gray.50", "gray.900")}
    color={useColorModeValue("gray.700", "gray.200")}
  >
    <Container
      as={Stack}
      maxW={"6xl"}
      py={4}
      direction={{ base: "column", md: "row" }}
      spacing={4}
      justify={{ base: "center", md: "space-between" }}
      align={{ base: "center", md: "center" }}
    >
      <Stack direction={"row"} spacing={6}>
        <Link href="/about">About</Link>
        <Link href="/contact">Contact</Link>
      </Stack>
      <Stack>
        <Text fontSize="sm" color="primary">
          <UserInfo />
        </Text>
        <Text fontSize="sm" color="primary">
          &copy; {new Date().getFullYear()} Always Cargo AS All rights reserved.
        </Text>
      </Stack>
    </Container>
  </Box>
);
