import {
  Box,
  useColorModeValue,
  Stack,
  Image,
  IconButton,
  SimpleGrid,
  Center,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import logo from "../../assets/logo.png";
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

interface HeaderProps {
  disclosureProps: any;
}

/**********************************************************
/ NAME: Header()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a header with a logo and 
 * a color mode switcher. The header is responsive and adjusts 
 * its layout based on the device's screen size.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following props:
 * - disclosureProps: Properties for controlling the visibility of a modal or popover
 * 

/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * - Box: A container that wraps the entire header
 * - SimpleGrid: A container that arranges its children in a grid
 * - IconButton: A button that, when clicked, navigates to the home page
 * - Image: A component that renders an image
 * - Stack: A container that wraps the color mode switcher
 * - ColorModeSwitcher: A button that toggles between light and dark color modes
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a header.
/*********************************************************/
const Header: React.FC<HeaderProps> = (HeaderProps): JSX.Element => {
  //const disclosureProps = getDisclosureProps();
  ////console.log(disclosureProps);

  return (
    <>
      <BrowserView>
        <Box bg={useColorModeValue("header", "#1A202C")} px={4}>
          <SimpleGrid
            margin="0"
            columns={3}
            h={100}
            width="95vw"
            justifyItems={"center"}
          >
            <Box></Box>

            <IconButton
              aria-label="Home"
              variant="notification"
              icon={<Image src={logo} height="5vh" />}
              h="6vh"
              //w="14"
              padding={2}
              justifySelf={"center"}
              alignSelf={"center"}
              mt="2"
              mr="-2"
            ></IconButton>

            <Stack
              direction={"row"}
              spacing={7}
              mt="6"
              padding="2"
              justifyContent="flex-end"
            >
              <ColorModeSwitcher justifySelf="end" />
            </Stack>
          </SimpleGrid>
        </Box>
      </BrowserView>
      <MobileView>
        <Box bg={useColorModeValue("header", "green.900")} px={4}>
          <SimpleGrid columns={3} h={100} width="95vw">
            <Center>
              <IconButton
                aria-label="Notifications"
                variant="notification"
                icon={<Image src={logo} height="6vh" />}
                h="7vh"
                //w="14"
                padding={2}
                justifySelf={"center"}
                alignSelf={"center"}
                mt="2"
              ></IconButton>
            </Center>
            <Center>
              <Stack
                direction={"row"}
                spacing={7}
                padding="0"
                justifyContent="flex-end"
              >
                <ColorModeSwitcher justifySelf="end" />
              </Stack>
            </Center>
          </SimpleGrid>
        </Box>
      </MobileView>
    </>
  );
};
export default Header;
