import { Center, Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import { Footer } from "./Footer";
/**********************************************************
/ NAME: LoginLayout()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a layout for the login page. 
 * It includes a center-aligned main content area and a footer.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following props:
 * - children: The components to be rendered in the main content area
 * 

/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * - Grid: A container that arranges its children in a grid
 * - GridItem: A component that is placed inside a Grid container
 * - Center: A container that centers its children
 * - Footer: A component that renders a footer
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a layout for the login page.
/*********************************************************/
function LoginLayout(props: any) {
  return (
    <Grid minH="100vh" templateRows="100vh 20vh">
      <GridItem h={"100vh"}>
        <Center pt={"10vh"}>{props.children}</Center>
      </GridItem>
      <GridItem alignSelf={"flex-end"}>
        <Footer />
      </GridItem>
    </Grid>
  );
}

export default LoginLayout;
