/**********************************************************
/ NAME: RegisterUser()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a registration form. 
 * When the form is submitted, it calls a function to create a new user.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * handlePasswordChange() - Updates the password and checks if it is valid
 * handlePhoneChange() - Updates the phone number and checks if it is valid
 * onSubmit() - Creates a new user if the password and phone number are valid
 * 
/********************************************************** 
 * COMPONENTS:
 * - Input: A component that renders an input field
 * - VStack: A container that stacks its children vertically
 * - Button: A button that submits the form when clicked
 * - Link: A component that renders a link to a specified URL
 * - Text: A component that renders text
 * - Image: A component that renders an image
 * - Alert: A component that renders an alert
 * - AlertIcon: A component that renders an icon in an alert
 * - AlertDescription: A component that renders a description in an alert
 * - PasswordInput: A component that renders a password input field with a show/hide button
 * - PhoneInput: A component that renders a phone input field
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a registration form.
/*********************************************************/
import {
  Box,
  ButtonGroup,
  VStack,
  Image,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
  Text,
  FormControl,
} from "@chakra-ui/react";
import { Formik } from "formik";
import {
  CheckboxContainer,
  CheckboxControl,
  SubmitButton,
} from "formik-chakra-ui";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUser, logIn } from "../databasefunctions/users";

import logo from "../../assets/logo.png";
import "react-phone-input-2/lib/style.css";
import PasswordInput from "./PasswordInput";
import PhoneInput from "./PhoneInput";
const initialValues = {
  name: "",
  email: "",
  password: "",
  phoneNumber: "",
  carrier: false,
  cargoOwner: false,
};

function RegisterUser() {
  const navigate = useNavigate();
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [isPhoneValid, setPhoneValid] = useState(true);
  const [phone, setPhone] = useState("");
  const [isPasswordValid, setPasswordValid] = useState(true);
  let isError = false;

  const handlePasswordChange = (
    newPassword: string,
    isPasswordValid: boolean
  ) => {
    setPassword(newPassword);
    setPasswordValid(isPasswordValid);
  };
  const handlePhoneChange = (newPhone: string, isPhoneValid: boolean) => {
    setPhone(newPhone);
    setPhoneValid(isPhoneValid);
  };
  const onSubmit = async (values: any) => {
    if (isPasswordValid && isPhoneValid) {
      await createUser(
        values.name,
        values.email,
        password,
        phone,
        values.carrier,
        values.cargoOwner
      )
        .then(() => {
          isError = false;
        })
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError)
        });

      if (isError === true) {
        setHasError(true);
      } else {
        await logIn("", values.email, password, "", "", "");
        navigate("/verify", {
          state: {
            userCarrier: values.carrier,
            userCargoOwner: values.cargoOwner,
          },
        });
      }
    }
  };

  return (
    <>
      <VStack>
        <Image src={logo} boxSize="15vw" marginTop="2vh" />
        {hasError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // validationSchema={validationSchema}
        >
          {({ handleSubmit, values, errors }) => (
            <Box
              borderWidth="2px"
              rounded="lg"
              borderColor="blackAlpha.400"
              shadow="2px 2px 4px rgba(0,0,0,0.3)"
              maxWidth="90vw"
              minWidth="30vw"
              p={6}
              m="10px auto"
              as="form"
              onSubmit={handleSubmit as any}
            >
              <Text variant="label">For- og etternavn</Text>
              <FormControl isRequired>
                <Input
                  variant="standardTextInput"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Ola Nordmann"
                  onChange={(e) => (values.name = e.target.value)}
                />
              </FormControl>
              <Text variant="label">E-post</Text>
              <FormControl isRequired>
                <Input
                  variant="standardTextInput"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-post"
                  onChange={(e) => (values.email = e.target.value)}
                />
              </FormControl>

              <Text variant="label">Passord</Text>
              <PasswordInput
                value={password}
                onChange={handlePasswordChange}
                placeholder="Passord"
              />
              <Text variant="label">Telefonnummer</Text>
              <PhoneInput
                value={phone}
                onChange={handlePhoneChange}
                placeholder="1234"
              />
              <Text variant="label">Brukertype</Text>
              <CheckboxContainer name="carrier" mt={4}>
                <CheckboxControl name="carrier" value="carrier">
                  Transportør
                </CheckboxControl>
              </CheckboxContainer>
              <CheckboxContainer name="cargoOwner" mt={4}>
                <CheckboxControl name="cargoOwner" value="cargoOwner">
                  Godseier
                </CheckboxControl>
              </CheckboxContainer>

              <ButtonGroup mt={4}>
                <SubmitButton variant="primary">Opprett bruker</SubmitButton>
              </ButtonGroup>
            </Box>
          )}
        </Formik>
      </VStack>
    </>
  );
}

export default RegisterUser;
