import { Box, Container, Stack, Text, Link, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import UserInfo from './UserInfo';

export const Footer = () => (
  <Box bg={useColorModeValue('gray.50', 'gray.900')} color={useColorModeValue('gray.700', 'gray.200')}>
    <Container
      as={Stack}
      maxW={'6xl'}
      py={4}
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      justify={{ base: 'center', md: 'space-between' }}
      align={{ base: 'center', md: 'center' }}
    >
      <Stack direction={'row'} spacing={6}>
        <Link href='/about'>About</Link>
        <Link href='/contact'>Contact</Link>
      </Stack>
      <Stack>
        <Text fontSize='sm' color='primary'>
          &copy; {new Date().getFullYear()} Always Cargo AS All rights reserved.
        </Text>
      </Stack>
    </Container>
  </Box>
);
