import * as React from "react";
import {
  useColorMode,
  useColorModeValue,
  IconButton,
  IconButtonProps,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">;
/**********************************************************
/ NAME: ColorModeSwitcher()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a button that toggles 
 * between light and dark color modes.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following props:
 * - IconButtonProps: The properties of the IconButton component 
 * from Chakra UI, excluding "aria-label"
 * 

/********************************************************** 
 * FUNCTIONS:
 * toggleColorMode() - Toggles between light and dark color modes
 * 
/********************************************************** 
 * COMPONENTS:
 * - IconButton: A button that, when clicked, calls toggleColorMode
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing an IconButton.
/*********************************************************/
export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <IconButton
      size="md"
      fontSize="lg"
      variant="ghost"
      color="current"
      marginLeft="2"
      onClick={toggleColorMode}
      icon={<SwitchIcon />}
      aria-label={`Switch to ${text} mode`}
      {...props}
    />
  );
};
