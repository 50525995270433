/**********************************************************
/ NAME: CargoRow()
/********************************************************** 
 * SUMMARY: 
 * This component represents a single row in the upcoming 
 * cargo list. It displays the details of a single cargo item.
 * 
/********************************************************** 
 * INPUT: 
 * It takes a cargo object as a prop which contains the 
 * details of the cargo.
 * 

/********************************************************** 
 * FUNCTIONS:
 * findMatch() - Finds a match for the cargo
 * EvaluateOffer() - Sends the user to a page to evaluate the offer
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns a jsx element that represents a single row in 
 * the upcoming cargo list.
/*********************************************************/
import { Box, Button, Grid, Divider, Center } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MdOutlineFindReplace } from "react-icons/md";
import { findCargoMatch } from "../databasefunctions/matches";
import { isMobile } from "react-device-detect";
interface CargoRowProps {
  cargo: {
    tracking_number: string;
    name: string;
    pick_up_point: string;
    earliest_start_time: string;
    latest_start_time: string;
    earliest_end_time: string;
    latest_end_time: string;
    drop_of_point: string;
    vehicle_pk: string;
    package_height_cm: string;
    package_length_cm: string;
    package_width_cm: string;
    package_weight_kg: string;
    loads_types: [string];
    distance_m: string;
    duration_min: string;
    note: string;
    loading_requirements: [string];
    icoterm: {
      code: string;
      discription: string;
    };
    pending_matches: {
      car_reg_number: string;
      offer: string;
      status: string;
      note: string;
    };
  };
}
export default function CargoRow(props: CargoRowProps) {
  const {
    tracking_number,
    pick_up_point,
    earliest_start_time,
    latest_start_time,
    earliest_end_time,
    latest_end_time,
    icoterm,
    loads_types,
    drop_of_point,
    package_height_cm,
    package_length_cm,
    package_width_cm,
    package_weight_kg,
    pending_matches,
    distance_m,
    duration_min,
    loading_requirements,
    note,
  } = props.cargo;
  const navigate = useNavigate();
  const [renderMe] = useState<boolean>(true);
  const [searchMatch, setSearchMatch] = useState<boolean>(false);

  let buttonSize = "md";
  if (isMobile) {
    buttonSize = "sm";
  }
  let MatchButton = (
    <Center>
      <Button
        ml="-5"
        variant="primary"
        size={buttonSize}
        leftIcon={<MdOutlineFindReplace />}
        isActive
        onClick={() => findMatch()}
      >
        Søk
      </Button>
    </Center>
  );
  if (pending_matches["status"] === "Pending") {
    MatchButton = (
      <Center>
        <Button
          size={buttonSize}
          leftIcon={<MdOutlineFindReplace />}
          isDisabled
          onClick={() => findMatch()}
        >
          Match
        </Button>
      </Center>
    );
  } else if (pending_matches["status"] === "Offer Recieved") {
    MatchButton = (
      <Center>
        <Button
          variant="verify"
          size={buttonSize}
          onClick={() => EvaluateOffer()}
        >
          Evaluer
        </Button>
      </Center>
    );
  } else if (
    pending_matches["status"] === "Accepted" ||
    pending_matches["status"] === "Bid Accepted"
  ) {
    MatchButton = (
      <Center>
        <Button
          size={buttonSize}
          colorScheme={"green"}
          isDisabled
          onClick={() => EvaluateOffer()}
        >
          Akseptert
        </Button>
      </Center>
    );
  }

  const findMatch = async () => {
    setSearchMatch(true);
    await findCargoMatch(tracking_number);
    //console.log(Match?.data?.details?.distance_diff)
  };
  useEffect(() => {
    //console.log(searchMatch)
  }, [searchMatch, tracking_number]);

  const EvaluateOffer = async () => {
    navigate("/evaluate-offer-Cargo-Owner", {
      state: {
        distance_package: distance_m,
        duration_package: duration_min,
        package_height: package_height_cm,
        package_length: package_length_cm,
        package_width: package_width_cm,
        package_weight: package_weight_kg,
        pick_up_point: pick_up_point,
        drop_off_point: drop_of_point,
        earliest_start_time: earliest_start_time,
        latest_start_time: latest_start_time,
        earliest_end_time: earliest_end_time,
        latest_end_time: latest_end_time,
        load_types: loads_types,
        incoterms: icoterm,
        offer: pending_matches["offer"],
        tracking_number: tracking_number,
        car_reg_number: pending_matches["car_reg_number"],
        loading_requirements: loading_requirements,
        note: note,
        noteTrip: pending_matches["note"],
      },
    });
  };

  //console.log(pending_matches)

  let dateString = earliest_start_time.split("T");
  //console.log(dateString[0])

  //console.log(pending_matches?.status)

  if (!renderMe) {
    return <></>;
  }
  return (
    <>
      <Grid ml="-1" mb={2} templateColumns="32% 32% 36%" gap={2}>
        <Box fontSize={"1.0rem"}>
          {dateString[0]} kl: {dateString[1]}
        </Box>
        <Box fontSize={"1.0rem"}>{pick_up_point}</Box>

        {!searchMatch && MatchButton}
      </Grid>
      <Divider variant="thick" mt="1" mb="1" width={"95%"}></Divider>
    </>
  );
}
