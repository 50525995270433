/**********************************************************
/ NAME: Dashboard()
/********************************************************** 
 * SUMMARY: 
 * Dashboard populates the dashboard with the information
 * from the database for the user. This includes information
 * about the number of cargos and trucks and trips.
 * 
/********************************************************** 
 * INPUT: 
 * No Inputs
 * 
/********************************************************** 
 * FUNCTIONS:
 * getUser(): Gets the user information from the database
 * setViewInfo(): Sets the information the user can see
 * 
/********************************************************** 
 * COMPONENTS:
 * UpComingCargoOverview: Displays the upcoming cargos
 * NeedMatchesCargoOverview: Displays the cargos that need matches
 * UpComingTripsOverview: Displays the upcoming trips
 * NeedMatchesTripsOverview: Displays the trips that need matches
 * NumberofCargo: Displays the number of cargos
 * NumberofTrucks: Displays the number of trucks
 * Statistics: Displays the statistics
 * 
/*********************************************************/

import { SimpleGrid, Container } from "@chakra-ui/react";
import OneColumnLayout from "../layout/OneColLayout";
import { useState, useEffect } from "react";

import { findInfo } from "../databasefunctions/storeandfind";
import { isMobile } from "react-device-detect";
import { NumberofCargo, NumberofTrucks, Statistics } from "./getInformation";
import { getUser } from "../databasefunctions/users";
import { Steps, Hints, Step } from "intro.js-react";
import UpComingCargoOverview from "./UpComingCargos";
import NeedMatchesCargoOverview from "./NeedMatchesCargo";
import UpComingTripsOverview from "./UpComingTrips";
import NeedMatchesTripsOverview from "./NeedMatchesTrips";

function Dashboard() {
  const [uCarrier, setUCarrier] = useState<Boolean>(false);
  const [uCargoOwner, setUCargoOwner] = useState<Boolean>(false);
  const [user, setUser] = useState<any>();

  async function setViewInfo() {
    setUser(getUser());
    const cargoOwner = await findInfo("cargo_owner");
    if (cargoOwner === "true") {
      setUCargoOwner(true);
    }

    const carrier = await findInfo("carrier");
    if (carrier === "true") {
      setUCarrier(true);
    }
  }
  useEffect(() => {
    if (!user) {
      setViewInfo();
    }
  }, [user]);

  //console.log(user)
  let nColCO = 3;
  if (isMobile) {
    nColCO = 1;
  }
  let regTrucks = NumberofTrucks();

  let cargo = NumberofCargo();
  //console.log(uCargoOwner,uCarrier)

  const [showTour, setShowTour] = useState(false);
  const [startStep, _setStartStep] = useState(0);
  const stepsCargoOwner: Step[] = [
    {
      element: '#cargolist',
      intro: 'Liste over dine oppdrag som venter på match.',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: "#addCargoBtn",
      intro: 'Legg til en nytt oppdrag med denne knappen.',
    },
    {
      element: '#matchlist',
      intro: 'Denne listen vil inneholde dine oppdrag som har en match med en eller flere registrerte ledig kapasitet'
    }
  ];

  const stepsCarrier: Step[] = [
    {
      element: '#transportList',
      intro: 'Liste over dine kjøreruter som venter på match.'
    },
    {
      element: "#addTransportBtn",
      intro: 'Legg til en ny transport / rute med denne knappen.',
    },
    {
      element: '#carrierMatchlist',
      intro: 'Denne listen vil inneholde oppdrag som har en match med en eller flere av dine registrerte ledige turer'
    },
    {
      element: '#truckList',
      intro: 'Her er statistikk om dine registrerte kjøretøy'
    },
    {
      element: '#openTruckList',
      intro: 'Klikk her for å komme til siden hvor flere kjøretøy kan registreres, eller kjøretøy kan endres.'
    }
  ];

  return (
    <>

    <OneColumnLayout
      sectionLabel="Dashboard"
      sectionHeading=""
      sectionIntro=""
      content={
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: nColCO }}
          columnGap={10}
          rowGap={5}
        >
          {uCarrier && (
            <Container variant="dashboardComponents" id="transportList">
              <NeedMatchesTripsOverview />
            </Container>
          )}
          {uCarrier && (
            <Container variant="dashboardComponents" id="carrierMatchlist">
              <UpComingTripsOverview />
            </Container>
          )}
          {uCarrier && (
            <Container variant="dashboardComponents"  id="truckList">
              <NumberofTrucks />
            </Container>
          )}
          {uCargoOwner && (
            <Container variant="dashboardComponents" id="cargolist">
              <NeedMatchesCargoOverview />
            </Container>
          )}
          {uCargoOwner && (
            <Container variant="dashboardComponents" id="matchlist">
              <UpComingCargoOverview />
            </Container>
          )}

          
          <Container variant="dashboardComponents">
            <Statistics />
          </Container>
        </SimpleGrid>
      }
      showTour={() => setShowTour(true)}
    />
    <Steps
      enabled={showTour}
      steps={ uCargoOwner && !uCarrier ? stepsCargoOwner : (!uCargoOwner && uCarrier ? stepsCarrier : ([...stepsCargoOwner, ...stepsCarrier]))}
      initialStep={startStep}
      onExit={() => setShowTour(false)}
    />
  </>
  );
}

export default Dashboard;
