import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,

  Spinner,
  Box,

  Text
} from "@chakra-ui/react";
import { useRef, useState } from "react";


//import { PDFViewer,Document, Page  } from '@react-pdf/renderer';
import { SubmitButton } from "formik-chakra-ui";
interface AlertProps {
  onConfirm: (values: any) => void;
  subject: any
}

export const Terms = (props: AlertProps) => {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clicked] = useState<boolean>(false);
  function onConfirm() {  
    //setClicked(true);
    onClose();
    props.onConfirm(props.subject);
  }

  const btnRef = useRef<HTMLButtonElement>(null);

  function handleClick(){
    onOpen()
  }
  
  
  return (
    <>
      <Button  variant="primary" onClick={handleClick}>
        {!clicked ? (
          <Box >Registrer</Box>
        ) : (
          <Spinner size={"sm"} />
        )}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={btnRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
             <AlertDialogHeader fontSize="lg" fontWeight="bold">
             Brukervilkår Always Cargo AS
            </AlertDialogHeader>

            <AlertDialogBody> 
            
            <Text mb="2">
            1. Ingen månedlig avgift, eller skjulte kostnader.
            </Text>
            <Text mb="2">
            2. 4 % av oppdragets verdi basert på frakt-papirer/forsikrings- eller tolldokument, for godstransport med lastebil over 3,5 tonn, med max grense
oppad og minimum 100 kr per oppdrag transportøren får via oss.
            </Text>
            <Text mb="2">
            3. 4 % av oppdragets verdi basert på frakt-papirer/forsikrings- eller tolldokument, for varetransport/distribusjon med liten lastebil under 3,5 tonn, med
max grense oppad og minimum 50 kr.
            </Text>
            <Text mb="2">
            4. Feil og mangler som oppstår gjennom eksklusive avtaler mellom brukerene kan ikke lastes Always Cargo.
            </Text>
            <Text mb="2">
            5. Avtalen skjer direkte mellom brukene og oppgjør for transport skjer direkte mellom partene, ikke via Always Cargo.
            </Text>
            <Text mb="2">
            7. Nyttig informasjon/opplæring i verktøyet vil bil tilgjengelig via digitale ressurser internt og eksternt (Youtube)
            </Text>
            </AlertDialogBody>
            


            <AlertDialogFooter>
              <Button variant="return" ref={btnRef} onClick={onClose}>
                Tilbake
              </Button>
              <SubmitButton variant="primary" onClick={onConfirm} ml={3}>
                Bekreft
              </SubmitButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
