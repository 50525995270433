import {
  Input,
  Box,
  Button,
  Text,
  SimpleGrid,
  FormControl,
  ButtonGroup,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { SetStateAction, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OneColumnLayout from "../layout/OneColLayout";
import {
  getCollaboratorNames,
  getCompanyInformation,
  saveCargoOwner,
  saveCarrier,
} from "../databasefunctions/company";
import { findInfo } from "../databasefunctions/storeandfind";
// import { Tooltip } from "@mui/material";

import {
  AddCollaborators,
  getUser,
  storeFile,
} from "../databasefunctions/users";
import { Formik } from "formik";
import { ResetButton, SubmitButton } from "formik-chakra-ui";
import Createable from "../layout/Createable";
interface UserState {
  regNumber: string;
  contactmail: string;
  Accountantmail: string;
  tlfNumber: string;
  compAdress: string;
  compName: string;
  userCarrier: boolean;
  userCargoOwner: boolean;
}

interface Option {
  label: string;
  value: string;
}
/**********************************************************
  / NAME: UpdateCompany()
  /********************************************************** 
   * SUMMARY: 
   * This function component renders a form for updating company 
   * information. It allows the user to input new company details 
   * and updates the information when the form is submitted.
   * 
  /********************************************************** 
   * INPUT: 
   * It does not take any props.
   * 

  /********************************************************** 
   * FUNCTIONS:
   * findInfo() - Finds the company information
   * AddCollaborators() - Adds collaborators to the company
   * getUser() - Retrieves the user information
   * storeFile() - Stores a file
   * 
  /********************************************************** 
   * COMPONENTS:
   * - useState: A React hook that lets you add state to function components
   * - useLocation: A hook that returns the current location object which contains information about the current URL
   * - useNavigate: A hook that returns a function that navigates to different pages
   * - Formik: A form library for React
   * - ResetButton: A component that renders a reset button
   * - SubmitButton: A component that renders a submit button
   * - Createable: A component that renders a createable input field
   * 
  /********************************************************** 
   * RETURN:
   * Returns a JSX element containing a form for updating company information.
  /*********************************************************/
function UpdateCompany() {
  const { state } = useLocation();
  const {
    userCarrier,
    userCargoOwner,
    regNumber,
    contactmail,
    Accountantmail,
    tlfNumber,
    compAdress,
    compName,
  } = state as UserState;
  const [uCarrier] = useState(userCarrier);
  const [uCaroOwner] = useState(userCargoOwner);
  const [registrationNumber, setRegistrationNumber] = useState(regNumber);
  const [contactEmail, setContactEmail] = useState(contactmail);
  const [AccountantEmail, setAccountantEmail] = useState(Accountantmail);
  const [phoneNumber, setPhoneNumber] = useState(tlfNumber);
  const [companyAdress, setCompanyAdress] = useState(compAdress);
  const [name, setCompanyName] = useState(compName);
  const [collaboratorName, setCollaboratorName] = useState<any>(null);
  const [insuranceFile, setInsuranceFile] = useState<File>();
  const [ecFile, setFileEC] = useState<File>();
  const [collaborators, setCollaborators] = useState<Option[]>([]);
  const navigate = useNavigate();
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  let isError = false;

  const initialValues = {
    registrationNumber: registrationNumber,
    contactEmail: contactEmail,
    AccountantEmail: AccountantEmail,
    phoneNumber: phoneNumber,
    companyAdress: companyAdress,
    name: name,
    insuranceFile: "",
    environmentalCertificateFile: "",
    otherFile: "",
  };
  const getInformation = async () => {
    if (registrationNumber !== "") {
      await getCompanyInformation(registrationNumber)
        .then(() => {
          isError = false;
        })
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError)
        });
    } else {
      isError = true;
      setErrorMessage("Skriv inn org. nummer her");
    }
    if (isError === true) {
      setHasError(true);
    } else setHasError(false);
    let companyName = findInfo("companyName");

    let companyAdressInput = await findInfo("companyAddress");
    setCompanyAdress(companyAdressInput);

    //console.log(companyName);
    setCompanyName(`${companyName}`);
  };

  const onSubmit = async (values: any) => {
    //console.log("Hei")
    //console.log(values.name)
    if (ecFile) {
      await storeFile(ecFile, "Environmental certificate", false);
    }
    if (uCaroOwner) {
      if (!insuranceFile) return;
      await storeFile(insuranceFile, "Liability insurance", true);
    }

    if (uCarrier === true) {
      await saveCarrier(
        registrationNumber,
        AccountantEmail,
        companyAdress,
        name
      );
    }
    if (uCaroOwner === true) {
      await saveCargoOwner(
        registrationNumber,
        AccountantEmail,
        companyAdress,
        name
      );
    }
    let collabs: any[] = [];
    collaborators.forEach((element: any) => {
      collabs.push(`${element.value}`);
    });
    await AddCollaborators(collabs);
    await getUser();
    navigate("/dashboard");
  };
  //console.log(registrationNumber,contactEmail,AccountantEmail,phoneNumber)
  //console.log(uCaroOwner,uCarrier)
  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setRegistrationNumber(e.target.value);
    setFieldValue("registrationNumber", e.target.value);
  };
  function handleReset() {
    setRegistrationNumber("");
    setCompanyName("");
    setCompanyAdress("");
    setContactEmail("");
    setAccountantEmail("");
    setPhoneNumber("");
  }
  const handleFileUploadInsurance = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInsuranceFile(event.target.files![0]);
  };
  const handleFileUploadEC = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileEC(event.target.files![0]);
  };
  const getCollabNames = async (): Promise<void> => {
    let collabNames: any[] = [];
    const promises = collaborators.map((element: any) =>
      getCollaboratorNames(element.value)
    );
    const resolvedNames = await Promise.all(promises);
    collabNames = resolvedNames;
    //console.log(collabNames)
    setCollaboratorName(collabNames);
  };
  let collaboratorNameString = <Text></Text>;
  if (collaboratorName) {
    collaboratorNameString = <Text>{collaboratorName}</Text>;
  }

  return (
    <OneColumnLayout
      sectionLabel="Register Company"
      sectionHeading="Registrer informasjon om din bedrift her"
      sectionIntro=""
      content={
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          //validationSchema={validationSchema}
          onReset={handleReset}
        >
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <>
              {/* <Text fontSize="2xl">Truck</Text> */}
              <Box
                borderWidth="2px"
                rounded="lg"
                borderColor="blackAlpha.400"
                shadow="2px 2px 4px rgba(0,0,0,0.3)"
                width="80vw"
                maxWidth="500"
                minWidth="30vw"
                p={6}
                onSubmit={handleSubmit as any}
                m="10px auto"
                as="form"
              >
                <Text variant="label">Orgnr</Text>
                {hasError && (
                  <Alert status="error" mt="4" mb="-4">
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                )}
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    name="registrationNumer"
                    value={registrationNumber}
                    placeholder="Orgnr"
                    onChange={handleChange}
                  />
                </FormControl>

                <Button
                  variant="verify"
                  onClick={getInformation}
                  sx={{ m: 1 }}
                  margin="10px"
                >
                  Bekrefte
                </Button>

                <Text variant="label">Navn</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={name}
                    placeholder="Navn"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Adresse</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={companyAdress}
                    placeholder="Adresse"
                    onChange={(e) => setCompanyAdress(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Firma E-post</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    type="email"
                    value={contactEmail}
                    placeholder="E-post"
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Regnskapsfører E-post</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={AccountantEmail}
                    placeholder="E-post"
                    type="email"
                    onChange={(e) => setAccountantEmail(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Firma telefonnummer</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={phoneNumber}
                    placeholder="telefonnummer"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type="tel"
                  />
                </FormControl>
                <SimpleGrid columns={2} columnGap="2">
                  <Text variant="label">Forsikringsbevis</Text>
                  <Text variant="label">Miljøsertifikat</Text>
                  <Box
                    borderWidth="1px"
                    rounded="lg"
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    mt="4"
                    mb="4"
                    p={2}
                  >
                    <FormControl isRequired>
                      <Input
                        type="file"
                        name="file"
                        onChange={handleFileUploadInsurance}
                      ></Input>
                    </FormControl>
                  </Box>
                  <Box
                    borderWidth="1px"
                    rounded="lg"
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    mt="4"
                    mb="4"
                    p={2}
                  >
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileUploadEC}
                    ></input>
                  </Box>
                </SimpleGrid>
                {/* <Text variant="label" >Annet</Text>
          <Box borderWidth="1px"
                  rounded="lg"
                  shadow="1px 1px 3px rgba(0,0,0,0.3)"
                  mt="4"
                  mb="4"
                  p={2}>      
          <input type="file" name="file" onChange={(e)=>setOtherFile(e.target.files)} accept="image/*,.pdf"></input>
          </Box> */}
                <Text mb="-2" variant="label">
                  Org. nummer på samarbeidspartnere
                </Text>
                <Createable
                  setCreateableValue={setCollaborators}
                  CreateableValue={collaborators}
                  options={collaborators}
                />
                <Button
                  variant="verify"
                  onClick={getCollabNames}
                  sx={{ m: 1 }}
                  margin="10px"
                >
                  Valider
                </Button>
                {collaboratorNameString}
                <ButtonGroup mt={4}>
                  <SubmitButton variant="primary">Lagre</SubmitButton>
                  <ResetButton>Tilbakestill</ResetButton>
                </ButtonGroup>
              </Box>
            </>
          )}
        </Formik>
      }
    />
  );
}

export default UpdateCompany;
function setFieldValue(arg0: string, value: any) {
  throw new Error("Function not implemented.");
}
