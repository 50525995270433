/**********************************************************
/ NAME: TrucksOverview()
/********************************************************** 
 * SUMMARY: 
 * This function component fetches the data of the registered 
 * trucks using the getTrucks function, and then lists them 
 * using the TruckRow component. It also allows users to move 
 * to the create a new truck page.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * getTrucks() - Retrieves the trucks
 * 
/********************************************************** 
 * COMPONENTS:
 * - useState: A React hook that lets you add state to function components
 * - useEffect: A React hook that lets you perform side effects in function components
 * - OneColumnLayout: A component that renders a one column layout
 * - TruckRow: A component that renders a truck row
 * - Box: A component that renders a box
 * - Button: A component that renders a button
 * - Grid: A component that renders a grid
 * - Heading: A component that renders a heading
 * - Stack: A container that stacks its children
 * - Divider: A component that renders a divider
 * - Center: A component that centers its children
 * - MdKeyboardReturn: A component that renders a return icon
 * - BrowserView: A component that renders its children only on desktop devices
 * - MobileView: A component that renders its children only on mobile devices
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a list of registered trucks.
/*********************************************************/

import {
  Box,
  Button,
  Grid,
  Heading,
  Stack,
  Divider,
  Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import OneColumnLayout from "../layout/OneColLayout";
import TruckRow from "./TruckRow";
import { getTrucks } from "../databasefunctions/vehicles";
import { MdKeyboardReturn } from "react-icons/md";
import { BrowserView, MobileView } from "react-device-detect";
function TrucksOverview() {
  const [trucks, setTrucks] = useState<any>(null);

  useEffect(() => {
    if (!trucks) {
      getTrucks()?.then((p) => setTrucks(p));
    }
  }, [trucks]);

  //console.log(trucks);

  return (
    <OneColumnLayout
      sectionLabel="All trucks"
      sectionHeading="Registrerte kjøretøy"
      sectionIntro=""
      content={
        <>
          <BrowserView>
            <Grid mb={6} templateColumns="10vw 17vw 14vw 14vw 14vw 5vw" gap={2}>
              <Box></Box>
              <Box>
                <Heading fontSize={"1.2rem"}> Registreringsnummer</Heading>
              </Box>
              <Box>
                <Heading fontSize={"1.2rem"}> Modell</Heading>
              </Box>
              <Box>
                <Heading fontSize={"1.2rem"}> Merke</Heading>
              </Box>
              <Box>
                <Heading fontSize={"1.2rem"}> Tilhenger</Heading>
              </Box>
            </Grid>
            <Divider
              variant="thick"
              width="80vw"
              marginTop={"-2"}
              ml="-2"
              marginBottom="2"
            ></Divider>
            {trucks?.map((truck: any) => (
              <TruckRow key={truck.car_reg_number} truck={truck} />
            ))}
            <Stack direction="row" spacing={4} align="center">
              <Button variant="primary" as="a" href="/register-trucks">
                Registrer kjøretøy
              </Button>
            </Stack>
            <Stack mt="4" direction="row" spacing={4} align="center">
              <Button
                leftIcon={<MdKeyboardReturn />}
                variant="return"
                as="a"
                href="/dashboard"
              >
                Tilbake
              </Button>
            </Stack>
          </BrowserView>
          <MobileView>
            <Grid mb={6} templateColumns="26vw 26vw 26vw" gap={2}>
              <Box></Box>
              <Center>
                <Heading fontSize={"1.2rem"}> Reg. nummer</Heading>
              </Center>
              <Center>
                <Heading fontSize={"1.2rem"}> Modell</Heading>
              </Center>
            </Grid>
            <Divider
              variant="thick"
              width="85vw"
              marginTop={"-2"}
              marginBottom="2"
              ml="-2"
            ></Divider>
            {trucks?.map((truck: any) => (
              <TruckRow key={truck.car_reg_number} truck={truck} />
            ))}
            <Center mt="8" mb="4">
              <Button variant="primary" as="a" href="/register-trucks">
                Registrer kjøretøy
              </Button>
            </Center>
            <Stack direction="row" spacing={4} align="center" mt="4">
              <Button
                leftIcon={<MdKeyboardReturn />}
                variant="return"
                as="a"
                href="/dashboard"
              >
                Tilbake
              </Button>
            </Stack>
          </MobileView>
        </>
      }
    />
  );
}
export default TrucksOverview;
