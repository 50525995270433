/**********************************************************
/ NAME: NewTrip
/********************************************************** 
 * SUMMARY: 
 * NewTrip creates a new parcell of cargo to be sent from
 * A to B, it allows the user to add all relevant information
 * to make it possible for a transport company to provide
 * an offer on the transport
/********************************************************** 
 * INPUT: 
 * No Inputs
 * 
/********************************************************** 
 * FUNCTIONS:
 * createCargo(): Creates the cargo and stores it in the db
 * update the information about the truck
 * deleteTruck(): lets the user delete the truck
 * 
/********************************************************** 
 * COMPONENTS:
 * 
 * 
*/
import {
  SimpleGrid,
  AspectRatio,
  FormControl,
  Alert,
  AlertIcon,
  AlertDescription,
  Center,
  Switch,
  NumberInput,
  NumberInputField,
  FormErrorMessage,
  IconButton,
  Heading,
  Link,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { Box, ButtonGroup, Input, Text, Button } from "@chakra-ui/react";
import { Formik } from "formik";
import { SubmitButton, TextareaControl } from "formik-chakra-ui";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
//import * as Yup from "yup";
import Map from "../trip/Map";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import {
  ReactSelectMultiStyles,
  ReactSelectSingleStyles,
} from "../Styles/components/ReactSelectStyles";
import { useState, useEffect } from "react";
import { Route } from "../../types/RoutePoint";
import OneColumnLayout from "../layout/OneColLayout";
import { useNavigate } from "react-router-dom";
import {
  createCargo,
  getIncoterms,
  storePackageFiles,
  getLoadingRequirements,
} from "../databasefunctions/cargos";
import { getLoadTypes } from "../databasefunctions/cargos";
import AsyncSelect from "react-select/async";
import { MdKeyboardReturn } from "react-icons/md";
import { GOOGLE_API_KEY } from "../../assets/static";
import { Spinner } from "@chakra-ui/spinner";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import { BrowserView, MobileView } from "react-device-detect";
import ParameterSlider from "../layout/Slider";
import { HiInformationCircle } from "react-icons/hi";
import { ExternalLinkIcon } from "@chakra-ui/icons";
const initialValues = {
  packageName: "",
  requireCooling: false,
  requireToxic: false,
  requiredHeightcm: 0,
  requiredLengthcm: 0,
  requiredWidthcm: 0,
  requiredWeightkg: 0,
  firstStartDate: "",
  lastStartDate: "",
  firstEndDate: "",
  lastEndDate: "",
  origin: "",
  destination: "",
  notes: "",
};

export default function NewTrip() {
  const navigate = useNavigate();
  // const { projectID } = state as LandTransportScenarioState;
  const [LoadType, setLoadType] = useState<{ value: string; label: string }>();
  const [incoTerm, setIncoTerms] = useState<{
    value: string;
    label: string;
    description: string;
  }>({ value: "", label: "", description: "" });
  const [LoadingRequirements, setLoadingRequirements] = useState<
    [{ value: string; label: string }]
  >([{ value: "", label: "Ingen spesielle behov" }]);
  const [renderMe, setRenderMe] = useState<boolean>(false);
  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [FreightPaperFile, setFreightPaperFile] = useState<File>();
  const [optFile1, setOptFile1] = useState<File>();
  const [optFile2, setOptFile2] = useState<File>();
  const [minGoodsDamageScore, setMinGoodsDamageScore] = useState(4);
  const [weightedGoodsDamageScore, setWeightedGoodsDamageScore] = useState(4);
  const [minPunctualityScore, setMinPunctualityScore] = useState(4);
  const [weightedPunctualityScore, setWeightedPunctualityScore] = useState(4);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [weightedGroupMembership, setWeightedGroupMembership] = useState(2);
  const [environmentalCertificate, setEnvironmentalCertificate] =
    useState(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });
  let isError = false;

  let waypoints: string[] = ["[]"];

  // This function is run when the user presses the Submit button
  const onSubmit = async (values: any) => {
    //Structures the input to the db-format
    let loadTypes: any[] = [];
    if (LoadType) {
      loadTypes.push(`${LoadType.value}`);
    }
    let LoadingReq: any[] = [];
    LoadingRequirements.forEach((element: any) => {
      if (element.value !== "") {
        LoadingReq.push(`${element.value}`);
      }
    });

    //Checking for errors in the start and end dates
    let firstStartDate = new Date(values.firstStartDate).getTime();
    let firstEndDate = new Date(values.firstEndDate).getTime();
    let lastStartDate = new Date(values.lastStartDate).getTime();
    let lastEndDate = new Date(values.lastEndDate).getTime();
    let firstArrivalDateError = false;
    let lastArrivalDateError = false;
    let departureDateError = false;
    if (lastStartDate <= firstStartDate) {
      departureDateError = true;
    }
    if (firstEndDate < firstStartDate) {
      firstArrivalDateError = true;
    }
    if (
      lastEndDate <= firstEndDate ||
      lastEndDate <= firstStartDate ||
      lastEndDate <= lastStartDate
    ) {
      lastArrivalDateError = true;
    }
    if (departureDateError) {
      isError = true;
      setErrorMessage("Siste avgangstid må være etter første avgangstid");
    } else if (firstArrivalDateError) {
      isError = true;
      setErrorMessage("Første ankomsttid må være etter første avgangstid");
    } else if (lastArrivalDateError) {
      isError = true;
      setErrorMessage(
        "Siste ankomsttid må være etter første avgangstid, siste avgangstid og første ankomsttid"
      );
    } else {
      //Sending the cargo details to the database
      if (incoTerm.value !== "") {
        await createCargo(
          values.packageName,
          values.requiredHeightcm,
          values.requiredLengthcm,
          values.requiredWidthcm,
          values.requiredWeightkg,
          values.firstStartDate,
          values.lastStartDate,
          values.firstEndDate,
          values.lastEndDate,
          waypoints,
          origin,
          destination,
          loadTypes,
          incoTerm.value,
          minGoodsDamageScore,
          weightedGoodsDamageScore,
          minPunctualityScore,
          weightedPunctualityScore,
          weightedGroupMembership,
          environmentalCertificate,
          LoadingReq,
          values.notes
        )
          .then((response) => {
            //console.log(response)
            if (FreightPaperFile) {
              storePackageFiles(
                response.package.tracking_number,
                FreightPaperFile
              );
            }
            if (optFile1) {
              storePackageFiles(response.package.tracking_number, optFile1);
            }
            if (optFile2) {
              storePackageFiles(response.package.tracking_number, optFile2);
            }
          })
          .catch((error) => {
            isError = true;
            setErrorMessage(error.data.detail);
            //console.log(error.data.detail)
            //console.log(hasError);
          });
      } else {
        isError = true;
        setErrorMessage("Incoterm mangler");
      }
    }
    if (isError) {
      setHasError(true);
    } else {
      navigate("/cargos", {
        state: {
          notes: values.notes,
          scenarioName: values.scenarioName,
          // projectID: projectID,
        },
      });
    }
  };

  //Stores the freight papers in the database
  const handleFileUploadFreightPapers = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFreightPaperFile(event.target.files![0]);
  };
  //Stores the optional files in the database
  const handleFileUploadOptional1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptFile1(event.target.files![0]);
  };
  //Stores the optional files in the database
  const handleFileUploadOptional2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptFile2(event.target.files![0]);
  };
  //Calculates the route on the map
  function newRoute(
    route: Route,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    setFieldValue("tripName", route.scenarioName);
    setFieldValue("origin", route.startPoint.name);
    setFieldValue("destination", route.endPoint.name);
  }
  // Resets the form
  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
  }
  //Sets the load type based on the user input
  function handleSelectLoad(data: any) {
    setLoadType(data);
  }
  //Sets the loading requirment based on the user input
  function HandleSelectLoadingRequirements(data: any) {
    setLoadingRequirements(data);
  }
  //Sets the Incoterms based on the user input
  function HandleSelectInco(data: any) {
    setIncoTerms(data);
    setRenderMe(true);
    //console.log(incoTerm)
  }
  // Makes the load type input searchable
  const filterOptions = (option: { label: string }, searchString: string) => {
    if (!searchString) {
      return true;
    }
    return option.label.toLowerCase().includes(searchString.toLowerCase());
  };
  useEffect(() => {
    //console.log(incoTerm)
  }, [incoTerm, origin, destination]);
  const loadOptionsLoads: any = (callback: Function) => {
    const LoadTypes = getLoadTypes();
    return LoadTypes;
  };
  // Sets the styling of the async-select
  const customStyles = ReactSelectMultiStyles();
  // Gets the options for the incoterms from the database
  const loadOptionsInco: any = (callback: Function) => {
    const Incoterms = getIncoterms();
    return Incoterms;
  };
  // Gets the options for the loading requirements from the database
  const loadOptionsLoading: any = (callback: Function) => {
    const LoadingRequirements = getLoadingRequirements();
    return LoadingRequirements;
  };
  // Sets the styling of the async-select
  const customStylesInco = ReactSelectSingleStyles();

  // VISUAL
  return (
    <OneColumnLayout
      sectionLabel="Create Cargo"
      sectionHeading=""
      sectionIntro=""
      content={
        <>
          <Heading>
            Registrer gods
            <Tooltip
              label="Mer informasjon om bestilling av transport"
              fontSize="md"
              bg="white"
              color="black"
              border="1px solid #333"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
              borderRadius="md"
            >
              <Link
                isExternal
                href="https://www.altinn.no/starte-og-drive/e-guide-for-godstransport-pa-vei/"
              >
                <IconButton
                  aria-label="User"
                  variant="notification"
                  icon={<HiInformationCircle />}
                ></IconButton>
              </Link>
            </Tooltip>
          </Heading>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width="full">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              //validationSchema={validationSchema}
              onReset={handleReset}
              enableReinitialize
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                <>
                  {/* {!hasResult && ( */}

                  <Box
                    borderWidth="1px"
                    borderColor="blackAlpha.600"
                    rounded="lg"
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    p={6}
                    m="10px auto"
                    as="form"
                    onSubmit={handleSubmit as any}
                    maxWidth="87vw"
                    width="full"
                  >
                    {hasError && (
                      <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>{errorMessage}</AlertDescription>
                      </Alert>
                    )}

                    <BrowserView>
                      <SimpleGrid columns={2} columnGap="4">
                        <Text variant="label">Fra</Text>
                        <Text variant="label">Til</Text>

                        {isLoaded ? (
                          <StandaloneSearchBox>
                            <FormControl isRequired>
                              <Input
                                variant="standardTextInput"
                                name="origin"
                                placeholder="Adresse"
                                onChange={(e) => setOrigin(e.target.value)}
                                onBlur={(e) => {
                                  setOrigin(e.target.value);
                                }}
                                // onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                  setOrigin(e.target.value);
                                }}
                              />
                            </FormControl>
                          </StandaloneSearchBox>
                        ) : (
                          <Box>
                            <Spinner size="xl" />
                          </Box>
                        )}

                        {isLoaded ? (
                          <StandaloneSearchBox>
                            <FormControl isRequired>
                              <Input
                                variant="standardTextInput"
                                type="text"
                                placeholder="Adresse"
                                name="destination"
                                onBlur={(e) => {
                                  setDestination(e.target.value);
                                }}
                                onChange={(e) => setDestination(e.target.value)}
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                  setDestination(e.target.value);
                                }}
                              />
                            </FormControl>
                          </StandaloneSearchBox>
                        ) : (
                          <Box>
                            <Spinner size="xl" />
                          </Box>
                        )}
                      </SimpleGrid>
                    </BrowserView>
                    <MobileView>
                      <Text variant="label">Fra</Text>
                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              name="origin"
                              placeholder="Adresse"
                              onChange={(e) => setOrigin(e.target.value)}
                              onBlur={(e) => {
                                setOrigin(e.target.value);
                              }}
                              // onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                setOrigin(e.target.value);
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}
                      <Text variant="label">Til</Text>
                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              type="text"
                              placeholder="Adresse"
                              name="destination"
                              onChange={(e) => setDestination(e.target.value)}
                              onBlur={(e) => {
                                setDestination(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                setDestination(e.target.value);
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}
                    </MobileView>
                    <Text
                      textTransform="uppercase"
                      variant="label"
                      mb="0"
                      fontWeight="bold"
                    >
                      Sendingsdato
                    </Text>
                    <BrowserView>
                      <SimpleGrid columns={2} columnGap={2}>
                        <Text variant="label">Tidligst</Text>
                        <Text variant="label">Siste</Text>

                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="datetime-local"
                            name="firstStartDate"
                            onChange={(e) =>
                              (values.firstStartDate = e.target.value)
                            }
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="datetime-local"
                            name="lastStartDate"
                            onChange={(e) => {
                              values.lastStartDate = e.target.value;
                            }}
                          />
                        </FormControl>
                      </SimpleGrid>
                    </BrowserView>
                    <MobileView>
                      <Text variant="label">Tidligst</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="firstStartDate"
                          onChange={(e) =>
                            (values.firstStartDate = e.target.value)
                          }
                        />
                      </FormControl>
                      <Text variant="label">Siste</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="lastStartDate"
                          onChange={(e) =>
                            (values.lastStartDate = e.target.value)
                          }
                        />
                      </FormControl>
                    </MobileView>

                    <Text textTransform="uppercase" variant="label" mb="0">
                      Ankomstdato
                    </Text>
                    <BrowserView>
                      <SimpleGrid columns={2} columnGap={2}>
                        <Text variant="label">Tidligst</Text>
                        <Text variant="label">Siste</Text>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="datetime-local"
                            name="firstEndDate"
                            onChange={(e) =>
                              (values.firstEndDate = e.target.value)
                            }
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <Input
                            type="datetime-local"
                            name="lastEndDate"
                            onChange={(e) =>
                              (values.lastEndDate = e.target.value)
                            }
                            variant="standardTextInput"
                          />
                        </FormControl>
                      </SimpleGrid>
                    </BrowserView>
                    <MobileView>
                      <Text variant="label">Tidligst</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="firstEndDate"
                          onChange={(e) =>
                            (values.firstEndDate = e.target.value)
                          }
                        />
                      </FormControl>
                      <Text variant="label">Siste</Text>
                      <FormControl isRequired>
                        <Input
                          type="datetime-local"
                          name="lastEndDate"
                          onChange={(e) =>
                            (values.lastEndDate = e.target.value)
                          }
                          variant="standardTextInput"
                        />
                      </FormControl>
                    </MobileView>
                    <Text textTransform="uppercase" variant="label" mb="0">
                      Dimensjoner
                    </Text>
                    <SimpleGrid columns={3} columnGap="2">
                      <Text variant="label">Lengde [cm]</Text>
                      <Text variant="label">Bredde [cm]</Text>
                      <Text variant="label">Høyde [cm]</Text>
                      
                      <FormControl isRequired>
                        <NumberInput
                          name="requiredLengthcm"
                          variant="primary"
                          max={1360}
                          onChange={(value) =>
                            (values.requiredLengthcm = Number(value))
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </FormControl>
                      <FormControl isRequired>
                        <NumberInput
                          name="requiredWidthcm"
                          variant="primary"
                          max={240}
                          onChange={(value) =>
                            (values.requiredWidthcm = Number(value))
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </FormControl>
                      <FormControl isRequired>
                        <NumberInput
                          name="requiredHeightcm"
                          variant="primary"
                          max={300}
                          onChange={(value) =>
                            (values.requiredHeightcm = Number(value))
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </FormControl>
                      
                    </SimpleGrid>
                    <Text variant="label">Vekt [kg]</Text>
                    <FormControl isRequired>
                      <NumberInput
                        name="requiredWeightkg"
                        variant="primary"
                        max={50000}
                        onChange={(value) =>
                          (values.requiredWeightkg = Number(value))
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </FormControl>
                    <Text variant="label" mb="-2">
                      Varetype
                    </Text>

                    <AsyncSelect
                      defaultOptions
                      loadOptions={loadOptionsLoads}
                      value={LoadType}
                      onChange={(selectedOptions) =>
                        handleSelectLoad(selectedOptions)
                      }
                      filterOption={filterOptions}
                      styles={customStyles}
                    />
                    <Text variant="label" mb="-2">
                      Incoterms
                    </Text>
                    <AsyncSelect
                      defaultOptions
                      loadOptions={loadOptionsInco}
                      value={incoTerm}
                      onChange={(selectedOptions) =>
                        HandleSelectInco(selectedOptions)
                      }
                      filterOption={filterOptions}
                      styles={customStylesInco}
                    />
                    {renderMe && (
                      <Box
                        borderWidth="1px"
                        rounded="lg"
                        shadow="1px 1px 3px rgba(0,0,0,0.3)"
                        p={6}
                        m="5px auto"
                      >
                        <Text mt="-4" fontSize="md" fontWeight="semibold">
                          Description
                        </Text>
                        <Text mt="0" mb="-4" ml="-4" mr="-4" fontSize="sm">
                          {incoTerm.description}
                        </Text>
                      </Box>
                    )}
                    <Text variant="label" mb="-2">
                      Prioritering av samarbeidspartnere
                    </Text>
                    <ParameterSlider
                      sliderValue={weightedGroupMembership}
                      setSliderValue={setWeightedGroupMembership}
                      ariaLabel="Minimum-Goods-Damage-Score"
                    />
                    <Text variant="label" mb="-2">
                      Skal ha Miljøsertifikat
                    </Text>
                    <Switch
                      mt="2"
                      size="md"
                      onChange={() =>
                        setEnvironmentalCertificate(!environmentalCertificate)
                      }
                    ></Switch>
                    <Text variant="label" mb="-2">
                      Vedlegg til avtale
                    </Text>
                    <FormControl>
                      <Input
                        variant="standardTextInput"
                        height="-moz-min-content"
                        p="1"
                        mt="2"
                        type="file"
                        name="file"
                        onChange={handleFileUploadFreightPapers}
                      ></Input>
                    </FormControl>
                    <Text variant="label" mb="-2">
                      Tilleggsfiler
                    </Text>
                    <FormControl>
                      <Input
                        variant="standardTextInput"
                        height="-moz-min-content"
                        p="1"
                        mt="2"
                        type="file"
                        name="file"
                        onChange={handleFileUploadOptional1}
                      ></Input>
                    </FormControl>
                    <FormControl>
                      <Input
                        variant="standardTextInput"
                        height="-moz-min-content"
                        p="1"
                        mt="2"
                        type="file"
                        name="file"
                        onChange={handleFileUploadOptional2}
                      ></Input>
                    </FormControl>
                    <Text variant="label" mb="-2">
                      Hvilke laste/losteutstyr trenger forsendelsen?
                    </Text>
                    <AsyncSelect
                      defaultOptions
                      loadOptions={loadOptionsLoading}
                      value={LoadingRequirements}
                      isMulti
                      onChange={(selectedOptions) =>
                        HandleSelectLoadingRequirements(selectedOptions)
                      }
                      styles={customStyles}
                    />

                    <TextareaControl
                      name="notes"
                      label="Ytterligere merknader"
                      helperText="Åpningstider for hente- og leveransested noteres her. I tillegg hvis det er spesifikke ting som transportøren burde få vite om"
                    />

                    <SimpleGrid rowGap={8}>
                      <ButtonGroup mt={4}>
                        <SubmitButton variant="primary">Lagre</SubmitButton>
                        {/* <ResetButton>Tilbakestill</ResetButton> */}
                      </ButtonGroup>
                      <Button
                        mt="6"
                        leftIcon={<MdKeyboardReturn />}
                        variant="return"
                        as="a"
                        href="/cargos"
                      >
                        {" "}
                        Tilbake
                      </Button>
                    </SimpleGrid>
                  </Box>
                  <AspectRatio ratio={16 / 9}>
                    <Map
                      onRouteChange={(route) => newRoute(route, setFieldValue)}
                      origin={origin}
                      destination={destination}
                      // key={mapKey}
                      key={`${origin}-${destination}`}
                    />
                  </AspectRatio>
                </>
              )}
            </Formik>
          </SimpleGrid>
        </>
      }
    />
  );
}
