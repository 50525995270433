/**********************************************************
/ NAME: containerStyles
/********************************************************** 
 * SUMMARY: 
 * containerStyles is an object that contains the base styles, 
 * sizes, variants, and default properties for containers used 
 * in the application. It includes styles for dashboardComponents.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any inputs.
 * 

/********************************************************** 
 * FUNCTIONS:
 * No functions are defined within this object.
 * 
/********************************************************** 
 * COMPONENTS:
 * No components are used within this object.
 * 
/********************************************************** 
 * RETURN:
 * Returns an object containing styles for containers.
/*********************************************************/
export const containerStyles = {
  baseStyle: {},

  sizes: {},

  variants: {
    dashboardComponents: {
      width: "30vw",
      maxWidth: "500px",
      minWidth: "300px",
      borderWidth: "1px",
      borderColor: "iconColour",
      rounded: "lg",
      shadow: "1px 1px 3px rgba(0,0,0,0.3)",
      pt: "3",
      pb: "6",
      m: "10px auto",
      height: "25vh",
      minHeight: "350px",
      bg: "subtle",

      // shadow: "2px 2px 6px rgba(0,0,0,0.3)",
      _hover: {
        //color: "white",
        //transform: "scale(1.02)",
        boxShadow: "lg",
        shadowColor: "primary",
      },
      _dark: {
        borderColor: "white",
        bg: "subtleDark",
      },
    },
  },

  defaultProps: {},
};
