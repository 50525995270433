import axios from "axios";
import { BACKEND_URL } from "../../assets/static";
import { findToken } from "../login/Token";
/**********************************************************
/ NAME: CreatePaymentLink()
/********************************************************** 
 * SUMMARY: 
 * This function creates a payment link for a specific cargo 
 * using the cargo's tracking number, 
 * the carrier's registration number, and the amount to be paid.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - trackingNumber: The tracking number of the cargo
 * - regNumber: The registration number of the carrier
 * - amount: The amount to be paid
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the payment link.
/*********************************************************/
async function CreatePaymentLink(
  trackingNumber: string,
  regNumber: string,
  amount: number
) {
  const token: string = findToken();

  let urlstring;
  urlstring = `${BACKEND_URL}/payment?tracking_number=${trackingNumber}&car_reg_number=${regNumber}&amount=${amount}`;

  return await axios
    .get(urlstring, { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      // //console.log(res);
      // //console.log(res.data);
      // //console.log(res.data.url)

      return res.data.url;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: getPaymentStatus()
/********************************************************** 
 * SUMMARY: 
 * This function fetches the payment status for a specific
 *  cargo using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - trackingNumber: The tracking number of the cargo
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the payment status.
/*********************************************************/
async function getPaymentStatus(trackingNumber: string) {
  const token: string = findToken();

  let urlstring;
  urlstring = `${BACKEND_URL}/check_payment?tracking_number=${trackingNumber}`;

  return await axios
    .get(urlstring, { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      // //console.log(res);
      // //console.log(res.data);
      // //console.log(res.data.url)
      //console.log(res.data)

      return res.data.paid;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

export { CreatePaymentLink, getPaymentStatus };
