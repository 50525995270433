/**********************************************************
/ NAME: PaymentStatus()
/********************************************************** 
 * SUMMARY: 
 * PaymentStatus is a component that checks the payment status of a cargo match. It uses an interval to repeatedly call a function that checks the payment status until the status is true.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props. It uses the useLocation hook to get the state passed from the previous route.
 * 

/********************************************************** 
 * FUNCTIONS:
 * getPaymentStatus() - Checks the payment status of a cargo match
 * getPaymentData() - Calls getPaymentStatus and sets the status state
 * 
/********************************************************** 
 * COMPONENTS:
 * - useState: A React hook that lets you add state to function components
 * - useEffect: A React hook that lets you perform side effects in function components
 * - useLocation: A hook that returns the current location object which contains information about the current URL
 * - useNavigate: A hook that returns a function that navigates to different pages
 * - Button: A component that renders a button
 * - Text: A component that renders text
 * - VStack: A container that stacks its children vertically
 * - OneColumnLayout: A component that renders a one column layout
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a message about the payment status.
/*********************************************************/
// Importing necessary libraries and components
import { Button, Text, VStack } from "@chakra-ui/react";
import OneColumnLayout from "../layout/OneColLayout";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPaymentStatus } from "../databasefunctions/Payments";
import { dbAcceptMatch } from "../databasefunctions/matches";

// Defining the state interface
interface CargoMatchState {
  tracking_number: string;
  car_reg_number: string;
}

// PaymentStatus component
function PaymentStatus() {
  // Using hooks to get and set necessary states
  const { state } = useLocation();
  const { tracking_number, car_reg_number } = state as CargoMatchState;
  const [trackingNumber] = useState(tracking_number);
  const [regNumber] = useState(car_reg_number);
  const [status, setStatus] = useState<Boolean>(false);
  const navigate = useNavigate();
  let intervalId: number | null = null;
  let calledAccept = 0;

  // Function to get payment data
  const getPaymentData = async () => {
    let res = await getPaymentStatus(trackingNumber);
    setStatus(res);
  };

  // Using useEffect to call getPaymentData every 5 seconds until status is true
  useEffect(() => {
    if (!status) {
      intervalId = window.setInterval(getPaymentData, 5000);
    }
    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [status]);

  // Function to accept match and navigate to dashboard
  const AcceptMatch = async () => {
    await dbAcceptMatch(trackingNumber, regNumber).then(() => {
      navigate("/dashboard");
    });
  };

  // If status is true and AcceptMatch has not been called, call AcceptMatch
  if (status && calledAccept === 0) {
    calledAccept += 1;
    AcceptMatch();
  }

  // Render the component
  return (
    <OneColumnLayout
      sectionLabel="Dashboard"
      sectionHeading="Betalingsstatus"
      sectionIntro=""
      content={
        <VStack>
          {status && (
            <>
              <Text fontSize="2xl">Betalingen er vellykket</Text>
              <Text>
                Du vil nå få fraktpapirer og kontrakt tilsendt på epost.
              </Text>
              <Button variant="return" as="a" href="/dashboard">
                Tilbake
              </Button>
            </>
          )}
          {!status && (
            <>
              <Text fontSize="2xl">Betalingen er ikke registrert</Text>
              <Text>
                Dersom du har stengt betalingsvinduet kan du gå tilbake til
                oversikten og akseptere tilbudet på nytt.
              </Text>
              <Button variant="return" as="a" href="/dashboard">
                Tilbake
              </Button>
            </>
          )}
        </VStack>
      }
    />
  );
}

// Exporting the component
export default PaymentStatus;
