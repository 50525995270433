/**********************************************************
/ NAME: findToken()
/********************************************************** 
 * SUMMARY: 
 * This function retrieves a token from the session storage.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any input.
 * 

/********************************************************** 
 * FUNCTIONS:
 * sessionStorage.getItem("token") - Retrieves a token from the session storage
 * 
/********************************************************** 
 * RETURN:
 * Returns a string containing the token if it exists, or an empty string otherwise.
/*********************************************************/
function findToken(): string {
  let token = sessionStorage.getItem("token");
  if (token === null) {
    token = "";
  }
  return token;
}
/**********************************************************
/ NAME: saveTokenToLS()
/********************************************************** 
 * SUMMARY: 
 * This function saves a token to the session storage.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following input:
 * - token: The token to be saved
 * 

/********************************************************** 
 * FUNCTIONS:
 * sessionStorage.setItem("token", token) - Saves a token to the session storage
 * 
/********************************************************** 
 * RETURN:
 * It does not return any value.
/*********************************************************/
function saveTokenToLS(token: string) {
  sessionStorage.setItem("token", token);
}
/**********************************************************
/ NAME: deleteTokenFromLS()
/********************************************************** 
 * SUMMARY: 
 * This function deletes a token from the session storage.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any input.
 * 

/********************************************************** 
 * FUNCTIONS:
 * sessionStorage.removeItem("token") - Deletes a token from the session storage
 * 
/********************************************************** 
 * RETURN:
 * It does not return any value.
/*********************************************************/
function deleteTokenFromLS() {
  sessionStorage.removeItem("token");
}
export { findToken, saveTokenToLS, deleteTokenFromLS };
