import {
  Input,
  Box,
  Button,
  Text,
  FormControl,
  ButtonGroup,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { SetStateAction, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OneColumnLayout from "../layout/OneColLayoutNewUser";
import {
  getCollaboratorNames,
  getCompanyInformation,
  saveCargoOwner,
} from "../databasefunctions/company";
import { findInfo } from "../databasefunctions/storeandfind";
// import { Tooltip } from "@mui/material";

import {
  AddCollaborators,
  createUser,
  getUser,
  logIn,
} from "../databasefunctions/users";
import { Formik } from "formik";
import { ResetButton, SubmitButton } from "formik-chakra-ui";

import Createable from "../layout/Createable";
import { createCargo } from "../databasefunctions/cargos";
import PasswordInput from "./PasswordInput";
import PhoneInput from "./PhoneInput";
interface CargoState {
  cargoName: string;
  cargoPickUpPoint: string;
  cargoEarliestStartDate: string;
  cargoLatestStartDate: string;
  cargoEarliestEndDate: string;
  cargoLatestEndDate: string;
  cargoDropOfPoint: string;
  cargoIncoTerms: {
    value: string;
    label: string;
    description: string;
  };
  cargoLoadTypes: [string];
  cargoVehiclePk: string;
  cargoPackageHeightcm: string;
  cargoPackageLengthcm: string;
  cargoPackageWidthcm: string;
  cargoPackageWeightkg: string;
  cargoTrackingNumber: string;
  cargoNote: string;
}
const initialValues = {
  password: "",
  email: "",
  registrationNumber: "",
  contactEmail: "",
  AccountantEmail: "",
  phoneNumber: "",
  companyAdress: "",
  name: "",
  insuranceFile: "",
  environmentalCertificateFile: "",
  otherFile: "",
};
interface Option {
  label: string;
  value: string;
}
/**********************************************************
/ NAME: RegisterCompanyFromCargo()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a registration form for a 
 * company after they have registered a package. 
 * When the form is submitted, it calls a function to create a new company.
 * After the company is created, it calls a function to create a new cargo.
 * 
/********************************************************** 
 * INPUT: 
 * CargoState: The state of the cargo that was registered
 * 

/********************************************************** 
 * FUNCTIONS:
 * handlePasswordChange(newPassword: string, isPasswordValid: boolean) - Updates the password and checks if it is valid
 * handleEmailChange(newEmail: string) - Updates the email
 * handleUserNameChange(newUserName: string) - Updates the user name
 * handleContactEmailChange(newContactEmail: string) - Updates the contact email
 * handleAccountantEmailChange(newAccountantEmail: string) - Updates the accountant email
 * handlePhoneNumberChange(newPhoneNumber: string) - Updates the phone number
 * handleCompanyAdressChange(newCompanyAdress: string) - Updates the company address
 * handleCompanyNameChange(newCompanyName: string) - Updates the company name
 * handleCollaboratorNameChange(newCollaboratorName: string) - Updates the collaborator name
 * onSubmit(values: any) - Creates a new company if the password is valid
 * 
/********************************************************** 
 * COMPONENTS:
 * - Input: A component that renders an input field
 * - VStack: A container that stacks its children vertically
 * - Button: A button that submits the form when clicked
 * - Link: A component that renders a link to a specified URL
 * - Text: A component that renders text
 * - Image: A component that renders an image
 * - Alert: A component that renders an alert
 * - AlertIcon: A component that renders an icon in an alert
 * - AlertDescription: A component that renders a description in an alert
 * - PasswordInput: A component that renders a password input field with a show/hide button
 * - EmailInput: A component that renders an email input field
 * - UserNameInput: A component that renders a user name input field
 * - ContactEmailInput: A component that renders a contact email input field
 * - AccountantEmailInput: A component that renders an accountant email input field
 * - PhoneNumberInput: A component that renders a phone number input field
 * - CompanyAdressInput: A component that renders a company address input field
 * - CompanyNameInput: A component that renders a company name input field
 * - CollaboratorNameInput: A component that renders a collaborator name input field
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a registration form for a company.
/*********************************************************/
function RegisterCompanyFromCargo() {
  const { state } = useLocation();
  const {
    cargoName,
    cargoPickUpPoint,
    cargoEarliestStartDate,
    cargoLatestStartDate,
    cargoEarliestEndDate,
    cargoLatestEndDate,
    cargoDropOfPoint,
    cargoIncoTerms,
    cargoLoadTypes,
    cargoVehiclePk,
    cargoPackageHeightcm,
    cargoPackageLengthcm,
    cargoPackageWidthcm,
    cargoPackageWeightkg,
    cargoTrackingNumber,
    cargoNote,
  } = state as CargoState;

  const [registrationNumber, setRegistrationNumber] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [AccountantEmail, setAccountantEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyAdress, setCompanyAdress] = useState("");
  const [name, setCompanyName] = useState("");
  const [collaboratorName, setCollaboratorName] = useState<any>(null);
  const [isPasswordValid, setPasswordValid] = useState(true);
  const [collaborators, setCollaborators] = useState<Option[]>([]);
  const navigate = useNavigate();
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPhoneValid, setPhoneValid] = useState(true);
  let isError = false;
  const getInformation = async () => {
    if (registrationNumber !== "") {
      await getCompanyInformation(registrationNumber)
        .then(() => {
          isError = false;
        })
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError)
        });
    } else {
      isError = true;
      setErrorMessage("Skriv inn org. nummer her");
    }
    if (isError === true) {
      setHasError(true);
    } else setHasError(false);
    let companyName = findInfo("companyName");

    let companyAdressInput = findInfo("companyAddress");
    setCompanyAdress(companyAdressInput);

    //console.log(companyName);
    setCompanyName(`${companyName}`);
  };
  const handlePasswordChange = (
    newPassword: string,
    isPasswordValid: boolean
  ) => {
    setPassword(newPassword);
    setPasswordValid(isPasswordValid);
  };
  const handlePhoneChange = (newPhone: string, isPhoneValid: boolean) => {
    setPhoneNumber(newPhone);
    setPhoneValid(isPhoneValid);
  };
  const onSubmit = async (values: any) => {
    if (isPasswordValid && isPhoneValid) {
      await createUser(userName, email, password, phoneNumber, false, true)
        .then()
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError)
        });
      if (isError === true) {
        setHasError(true);
      } else {
        await logIn("", email, password, "", "", "");

        await saveCargoOwner(
          registrationNumber,
          AccountantEmail,
          companyAdress,
          name
        );
        await createCargo(
          cargoName,
          cargoPackageHeightcm,
          cargoPackageLengthcm,
          cargoPackageWidthcm,
          cargoPackageWeightkg,
          cargoEarliestStartDate,
          cargoLatestStartDate,
          cargoEarliestEndDate,
          cargoLatestEndDate,
          [],
          cargoPickUpPoint,
          cargoDropOfPoint,
          cargoLoadTypes,
          cargoIncoTerms.value,
          2,
          4,
          2,
          4,
          4,
          false,
          null,
          cargoNote
        )
          .then((response) => {})
          .catch((error) => {
            isError = true;
            setErrorMessage(error.data.detail);
            //console.log(error.data.detail)
            //console.log(hasError);
          });

        let collabs: any[] = [];
        collaborators.forEach((element: any) => {
          collabs.push(`${element.value}`);
        });
        await AddCollaborators(collabs);
        await getUser();
        navigate("/dashboard");
      }
    }
  };
  //console.log(cargoOwner,carrier)
  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setRegistrationNumber(e.target.value);
    setFieldValue("registrationNumber", e.target.value);
  };
  function handleReset() {
    setRegistrationNumber("");
    setCompanyName("");
    setCompanyAdress("");
    setContactEmail("");
    setAccountantEmail("");
    setPhoneNumber("");
  }

  const getCollabNames = async (): Promise<void> => {
    let collabNames: any[] = [];
    const promises = collaborators.map((element: any) =>
      getCollaboratorNames(element.value)
    );
    const resolvedNames = await Promise.all(promises);
    collabNames = resolvedNames;
    //console.log(collabNames)
    setCollaboratorName(collabNames);
  };
  let collaboratorNameString = <Text></Text>;
  if (collaboratorName) {
    collaboratorNameString = <Text>{collaboratorName}</Text>;
  }

  return (
    <OneColumnLayout
      sectionLabel="Register Company"
      sectionHeading="Registrer informasjon om din bedrift her"
      sectionIntro=""
      content={
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          //validationSchema={validationSchema}
          onReset={handleReset}
        >
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <>
              {/* <Text fontSize="2xl">Truck</Text> */}
              <Box
                borderWidth="2px"
                rounded="lg"
                borderColor="blackAlpha.400"
                shadow="2px 2px 4px rgba(0,0,0,0.3)"
                width="80vw"
                maxWidth="500"
                minWidth="30vw"
                p={6}
                onSubmit={handleSubmit as any}
                m="10px auto"
                as="form"
              >
                {hasError && (
                  <Alert status="error" mt="4" mb="-4">
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                )}
                <FormControl isRequired>
                  <Text variant="label">Navn</Text>
                  <Input
                    variant="standardTextInput"
                    name="userName"
                    value={userName}
                    type="text"
                    placeholder="Ola Nordmann"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Text variant="label">e-post</Text>
                  <Input
                    variant="standardTextInput"
                    name="email"
                    value={email}
                    type="email"
                    placeholder="ola@nordmann.no"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">passord</Text>
                <PasswordInput
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Passord"
                />
                <Text variant="label">Orgnr</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    name="registrationNumer"
                    value={registrationNumber}
                    placeholder="Orgnr"
                    onChange={handleChange}
                  />
                </FormControl>

                <Button
                  variant="verify"
                  onClick={getInformation}
                  sx={{ m: 1 }}
                  margin="10px"
                >
                  Bekrefte
                </Button>

                <Text variant="label">Navn</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={name}
                    placeholder="Navn"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Adresse</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={companyAdress}
                    placeholder="Adresse"
                    onChange={(e) => setCompanyAdress(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Firma E-post</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    type="email"
                    value={contactEmail}
                    placeholder="E-post"
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Regnskapsfører E-post</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={AccountantEmail}
                    placeholder="E-post"
                    type="email"
                    onChange={(e) => setAccountantEmail(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Firma telefonnummer</Text>
                <PhoneInput
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  placeholder="1234"
                />

                {/* <Text variant="label" >Annet</Text>
          <Box borderWidth="1px"
                  rounded="lg"
                  shadow="1px 1px 3px rgba(0,0,0,0.3)"
                  mt="4"
                  mb="4"
                  p={2}>      
          <input type="file" name="file" onChange={(e)=>setOtherFile(e.target.files)} accept="image/*,.pdf"></input>
          </Box> */}
                <Text mb="-2" variant="label">
                  Org. nummer på samarbeidspartnere
                </Text>
                <Createable
                  setCreateableValue={setCollaborators}
                  CreateableValue={collaborators}
                  options={collaborators}
                />
                <Button
                  variant="verify"
                  onClick={getCollabNames}
                  sx={{ m: 1 }}
                  margin="10px"
                >
                  Valider
                </Button>
                {collaboratorNameString}
                <ButtonGroup mt={4}>
                  <SubmitButton variant="primary">Lagre</SubmitButton>
                  <ResetButton>Tilbakestill</ResetButton>
                </ButtonGroup>
              </Box>
            </>
          )}
        </Formik>
      }
    />
  );
}

export default RegisterCompanyFromCargo;
function setFieldValue(arg0: string, value: any) {
  throw new Error("Function not implemented.");
}
