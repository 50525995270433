/**********************************************************
/ NAME: NeedMatchesCargoOverview()
/********************************************************** 
 * SUMMARY: 
 * NeedMatchesCargoOverview fetches all the cargos that need matches
 * 
 * 
 * 
/********************************************************** 
 * INPUT: 
 * No Inputs
 * 
/********************************************************** 
 * FUNCTIONS:
 * getNeedMatchesCargo(): Fetches all the cargos that need matches from the database
 * 
 * 
/********************************************************** 
 * COMPONENTS:
 * CargoRow: The component that displays the information about the cargo
 * 
 * 
 * 
 * 
 * 
 * 
 * 
/*********************************************************/
import { Button, Grid, Heading, Divider, Center, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getNeedMatchesCargo } from "../databasefunctions/cargos";
import { auth } from "../../firestore/firebaseConfig";
import { GoPackage } from "react-icons/go";
import CargoRow from "./UpComingCargoRow";

export default function NeedMatchesCargoOverview() {
  const [cargos, setCargo] = useState<any>(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!cargos) {
      getNeedMatchesCargo()?.then((p) => setCargo(p));
      //console.log(cargos)
    }
  }, [cargos, user]);

  return (
    <>
      <Grid templateColumns="10% 80% 10%" gap={2}>
        <Icon as={GoPackage} w={10} h={10} />

        <Center>
          <Heading fontSize={"1.4rem"}> Oppdrag</Heading>
        </Center>
      </Grid>
      <Grid mb={2} templateColumns="32% 32% 36%" gap={2}>
        <Center alignSelf={"center"}>
          <Heading fontSize={"1.2rem"}> Dato</Heading>
        </Center>
        <Center alignSelf={"center"}>
          <Heading fontSize={"1.2rem"}> Sted</Heading>
        </Center>

        <Center alignSelf={"center"}>
          <Heading fontSize={"1.2rem"}>Match</Heading>
        </Center>
      </Grid>
      <Divider variant="thick" mt="1" mb="1" width={"95%"}></Divider>
      {cargos?.map((cargo: any) => (
        <CargoRow key={cargo.id} cargo={cargo} />
      ))}
      <Center>
        <Button variant="primary" as="a" href="/create-cargo" id="addCargoBtn">
          Registrer
        </Button>
      </Center>
    </>
  );
}
