/**********************************************************
/ NAME: PhoneInput()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a phone input field. 
 * It also validates the phone number and calls a function when the phone number changes.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following props:
 * - value: The current value of the phone input field
 * - onChange: A function that is called when the phone number changes
 * - placeholder: The placeholder text for the phone input field
 * 

/********************************************************** 
 * FUNCTIONS:
 * validatePhone(phone: string) - Checks if the phone number is valid
 * handleChange() - Updates the phone number and checks if it is valid
 * 
/********************************************************** 
 * COMPONENTS:
 * - Input: A component that renders an input field
 * - FormControl: A component that provides context such as isInvalid and isRequired to form elements
 * - FormErrorMessage: A component that renders an error message
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a phone input field.
/*********************************************************/
// Importing necessary libraries and components
import { Input, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useState, ChangeEvent } from "react";

// Defining the props for the PhoneInput component
interface PhoneInputProps {
  value: string;
  onChange: (newValue: string, isValid: boolean) => void;
  placeholder?: string;
}

// PhoneInput component
const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  // Using hooks to get and set necessary states
  const [isPhoneValid, setPhoneValid] = useState(true);

  // Function to validate the phone number
  const validatePhone = (phone: string) => {
    // Check if the phone number does not contain any uppercase or lowercase letters.
    const hasNoUppercase = !/[A-Z]/.test(phone);
    const hasNoLowercase = !/[a-z]/.test(phone);

    // Check if the phone number contains at least one number.
    const hasNumber = /\d/.test(phone);

    // Check if the phone number does not contain any special characters (non-alphanumeric).
    const hasNoSpecialCharacter = !/[!@#$%^&*(),.?":{}|<>]/.test(phone);

    // Check if the phone number is 8 characters long, or 11 characters long if it starts with a '+'.
    let isLengthValid = phone.length === 8;
    if (/[+]/.test(phone)) {
      isLengthValid = phone.length === 11;
    }

    return (
      hasNoUppercase &&
      hasNoLowercase &&
      hasNumber &&
      hasNoSpecialCharacter &&
      isLengthValid
    );
  };

  // Function to handle phone number change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPhone = e.target.value;
    const isValid = validatePhone(newPhone);
    setPhoneValid(isValid);
    onChange(newPhone, isValid);
  };

  // Render the component
  return (
    <FormControl isInvalid={!isPhoneValid}>
      <Input
        variant="standardTextInput"
        type="tel"
        value={value}
        onChange={handleChange}
        placeholder={placeholder || "Phone"}
      />
      <FormErrorMessage>
        {isPhoneValid
          ? ""
          : "Phone number must be 8 digits long, or 11 digits long if it starts with a '+'. It should not contain any letters or special characters."}
      </FormErrorMessage>
    </FormControl>
  );
};

// Exporting the component
export default PhoneInput;
