/**********************************************************
/ NAME: Login()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a login form. 
 * When the form is submitted, it calls a function to log the user in.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 
/********************************************************** 
 * FUNCTIONS:
 * movingOn() - Calls a function to log the user in and 
 * navigates to the home page if the login is successful
 * handleClick() - Toggles the visibility of the password
 * 
/********************************************************** 
 * COMPONENTS:
 * - Input: A component that renders an input field
 * - VStack: A container that stacks its children vertically
 * - Button: A button that submits the form when clicked
 * - Link: A component that renders a link to a specified URL
 * - Text: A component that renders text
 * - Image: A component that renders an image
 * - Alert: A component that renders an alert
 * - AlertIcon: A component that renders an icon in an alert
 * - AlertDescription: A component that renders a description in an alert
 * - InputGroup: A component that groups related input components
 * - InputRightElement: A component that renders an element on the right side of an input field
 * - LoginLayout: A component that renders a layout for the login page
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a login form.
/*********************************************************/
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUser, logIn } from "../databasefunctions/users";
import {
  Input,
  VStack,
  Button,
  Link,
  Text,
  Image,
  Alert,
  AlertIcon,
  AlertDescription,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import logo from "../../assets/logo.png";
import LoginLayout from "../layout/LoginLayout";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const grant_type = "";
  const client_id = "";
  const client_secrect = "";
  const scope = "";
  //const [value, setValue] = React.useState("Carrier");
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState("");
  const [show, setShow] = React.useState(false);

  const handleClick = () => setShow(!show);
  let isError = true;
  //login function
  async function movingOn() {
    const tokenRes = await logIn(
      grant_type,
      email,
      password,
      scope,
      client_id,
      client_secrect
    )
      .then(async () => {
        const user = await getUser();
        if (user.insurance === false) {
          navigate("/update-insurance");
        } else navigate("/dashboard");
      })
      .catch((error) => {
        isError = true;
        setErrorMessage(error.data.detail);
      });
    await getUser();
    if (isError === true) setHasError(true);
    //console.log(errorMessage)
    if (tokenRes !== undefined) {
      setToken("test");
    }
  }
  useEffect(() => {
    if (token !== "") {
      //console.log("test");

      navigate("/dashboard");
    } else {
      return;
    }
  });

  return (
    <LoginLayout>
      <VStack maxW="md" gap={4}>
        <Image src={logo} h={200} />
        {hasError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Input
          variant="standardTextInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-post"
        />
        <InputGroup>
          <Input
            variant="standardTextInput"
            type={show ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Passord"
          />
          <InputRightElement width="4.5rem" mt={4}>
            <Button
              h="1.75rem"
              size="sm"
              variant="verify"
              onClick={handleClick}
            >
              {show ? "Skjul" : "Vis"}
            </Button>
          </InputRightElement>
        </InputGroup>
        {/* <RadioGroup gap="8" onChange={setValue} value={value}>
          <Radio value="Carrier">Transportør</Radio>
          <Radio ml="4" value="CargoOwner">
            Pakke eier
          </Radio>
        </RadioGroup> */}
        <Button size="lg" onClick={movingOn} variant="primary">
          Login
        </Button>
        <Text>
          <Link href="/reset" color="teal.500">
            Glemt passord
          </Link>
        </Text>
        <Text>
          Registrer deg{" "}
          <Link href="/register" color="teal.500" zIndex={10000}>
            Opprett bruker.
          </Link>
        </Text>
      </VStack>
    </LoginLayout>
  );
}
export default Login;
