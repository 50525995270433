/**********************************************************
/ NAME: TruckRow
/********************************************************** 
 * SUMMARY: 
 * TruckRow creates a line for each truck with the key 
 * information for each truck. It also allows users to delete 
 * and update information for the truck.
 * 
/********************************************************** 
 * INPUT: 
 * truck: an object containing information about each truck
 * 
/********************************************************** 
 * FUNCTIONS:
 * goToTruckOverview(): lets the user move to a page to update the 
 * information about the truck
 * deleteTruck(): lets the user delete the truck
 * 
/********************************************************** 
 * COMPONENTS:
 * - Box: A component that renders a box
 * - Button: A component that renders a button
 * - Grid: A component that renders a grid
 * - Heading: A component that renders a heading
 * - Stack: A container that stacks its children
 * - Divider: A component that renders a divider
 * - Center: A component that centers its children
 * - MdKeyboardReturn: A component that renders a return icon
 * - BrowserView: A component that renders its children only on desktop devices
 * - MobileView: A component that renders its children only on mobile devices
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a row with truck information.
/*********************************************************/
import { Box, Button, Center, Divider, Grid, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteVehicle } from "../databasefunctions/vehicles";
import { BrowserView, MobileView } from "react-device-detect";
import { Alert } from "../layout/AlertDialog";

interface TruckRowProps {
  truck: {
    car_reg_number: string;
    model: string;
    brand: string;
    cooling_compatible: boolean;
    height_cm: string;
    length_cm: string;
    width_cm: string;
    toxic_compatible: string;
    vehicle_group: string;
    trailer_pk: string;
    compatible_loads: string[];
  };
}
export default function TruckRow(props: TruckRowProps) {
  const {
    car_reg_number,
    model,
    brand,
    cooling_compatible,
    height_cm,
    length_cm,
    width_cm,
    toxic_compatible,
    vehicle_group,
    trailer_pk,
    compatible_loads,
  } = props.truck;

  const navigate = useNavigate();
  const [renderMe, setRenderMe] = useState<boolean>(true);

  const LoadTypes = compatible_loads.map((load) => {
    return { value: load, label: load };
  });

  function goToTruckOverview() {
    navigate("/update-truck", {
      state: {
        registrationNumber: car_reg_number,
        truckModel: model,
        truckMake: brand,
        truckHeight: height_cm,
        truckLength: length_cm,
        truckWidth: width_cm,
        truckPayload: 0,
        truckVehicleGroup: vehicle_group,
        trailerRegistrationNumber: trailer_pk,
        trailerLoadTypeList: LoadTypes,
      },
    });
  }
  async function deleteTruck(car_reg_number: string) {
    //console.log("y")
    await deleteVehicle(car_reg_number);
    setRenderMe(!renderMe);
  }

  if (!renderMe) {
    return <></>;
  }
  return (
    <>
      <BrowserView>
        <Grid mb={3} templateColumns="10vw 17vw 14vw 12vw 12vw 5vw" gap={2}>
          <Box>
            <Alert
              onConfirm={() => deleteTruck(car_reg_number)}
              subject="lastebilen"
            />
          </Box>
          <Box fontSize={"1.0rem"}>{car_reg_number}</Box>
          <Box fontSize={"1.0rem"}>{brand}</Box>
          <Box fontSize={"1.0rem"}>{model}</Box>
          <Box fontSize={"1.0rem"}>{trailer_pk}</Box>
          <Box>
            <Button variant="update" onClick={() => goToTruckOverview()}>
              Update
            </Button>
          </Box>

          <Divider variant="thick" m="-2" width={"80vw"}></Divider>
        </Grid>
      </BrowserView>
      <MobileView>
        <Grid mb={3} templateColumns="26vw 26vw 26vw" gap={2}>
          <VStack>
            <Button variant="update" onClick={() => goToTruckOverview()}>
              Endre
            </Button>
            <Box>
              <Alert
                onConfirm={() => deleteTruck(car_reg_number)}
                subject="lastebilen"
              />
            </Box>
          </VStack>
          <Center fontSize={"1.0rem"}>{car_reg_number}</Center>
          <Center fontSize={"1.0rem"}>{brand}</Center>
        </Grid>
        <Divider variant="thick" ml="-2" mt="2" mb="2" width={"85vw"}></Divider>
      </MobileView>
    </>
  );
}
