import {  Button,  Heading, VStack,Text } from "@chakra-ui/react";
import {  useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../../firestore/firebaseConfig";
import OneColumnLayout from "../layout/OneColLayout";
import { useLocation } from "react-router-dom";
interface CargoMatchState {
  matchStatus: number;
  matchDistance: number;
  matchDistanceDiff:number;
  matchWeight:number;
}

export default function CargoMatchView() {
  const [user] = useAuthState(auth);
  const { state } = useLocation();
  const {
    matchStatus,
    matchDistance,
    matchDistanceDiff,
    matchWeight
  } = state as CargoMatchState;
  const [status] = useState(matchStatus);
  const [distance] = useState(matchDistance);
  let [distanceDiff] = useState(matchDistanceDiff);
  const [weight] = useState(matchWeight);
  //console.log(status)
  //console.log(distance)
  let CO2emissions=0;
if (status===200){
  if (distanceDiff==undefined){distanceDiff=0}
  CO2emissions=Math.round(1056*distanceDiff/1000000+distance*((30000-weight)/30000)*1056/1000000)
  //console.log(CO2emissions)
  //console.log(distanceDiff)
  //console.log(weight)
  return (
    <OneColumnLayout
      sectionLabel="Match"
      sectionHeading="Matching"
      sectionIntro=""
      content={
        <VStack>
          <Heading>Congratulations! We found a match</Heading>
          <Text>Using this match you will save {CO2emissions} kg of CO2</Text>
          <Text>The transport company will revert back with a quote within the next 20 minutes</Text>
          <Button as="a" href="/cargos">
            Go back
          </Button>
          </VStack>
      }
    />
  
  );
}else return (
  <OneColumnLayout
      sectionLabel="Match"
      sectionHeading="Matching"
      sectionIntro=""
      content={
        <VStack>
          <Heading>No Match this time</Heading>
          <Text>We continously get more trips on this page</Text>
          <Text>Either wait for the algorithm to run automatically, or try again in a few hours</Text>
          <Button as="a" href="/cargos">
            Go back
          </Button>
          </VStack>
      }
    />

);

}
