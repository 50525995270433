import {
  Box,
  ButtonGroup,
  VStack,
  Image,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
  Text,
  FormControl,
} from "@chakra-ui/react";
import { Formik } from "formik";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UpdateInsurance, storeFile } from "../databasefunctions/users";

import logo from "../../assets/logo.png";
import "react-phone-input-2/lib/style.css";
import { Terms } from "../layout/InsuranceDialog";

const initialValues = {
  expiryDate: "",
};

/**********************************************************
/ NAME: UpdateInsuranceView()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a form for updating insurance 
 * information. It allows the user to input new insurance details 
 * and updates the information when the form is submitted.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * updateInsuranceDetails(values: any) - Updates the insurance details
 * onSubmit(values: any) - Updates the insurance details if the input is valid
 * 
/********************************************************** 
 * COMPONENTS:
 * - Box: A component that renders a box
 * - VStack: A container that stacks its children vertically
 * - Input: A component that renders an input field
 * - Button: A component that renders a button
 * - Text: A component that renders text
 * - Formik: A form library for React
 * - Form: A component that renders a form
 * - Field: A component that renders a field
 * - ErrorMessage: A component that renders an error message
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a form for updating insurance information.
/*********************************************************/
function UpdateInsuranceView() {
  const navigate = useNavigate();

  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [insuranceFile, setInsuranceFile] = useState<File>();
  let isError = false;
  const onSubmit = async (values: any) => {
    if (!insuranceFile) return;
    await storeFile(insuranceFile, "Liability insurance", true);
    await UpdateInsurance(true, values.expiryDate)
      .then()
      .catch((error) => {
        isError = true;
        setErrorMessage(error.data.detail);
      });
    //console.log("Hei")

    if (isError === true) {
      setHasError(true);
    } else {
      navigate("/dashboard");
    }
  };
  const handleFileUploadInsurance = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files![0].type === "application/pdf") {
      console.log(event.target.files![0].type);
      setHasError(false);
      setInsuranceFile(event.target.files![0]);
    }
    else {
      setHasError(true);
      setErrorMessage("The file must be a PDF file");
    }
    
  };
  useEffect(() => { }, [hasError, insuranceFile]);
  return (
    <>
      <VStack>
        <Image src={logo} boxSize="15vw" marginTop="2vh" />
        {hasError && (
          <Alert status="error" maxWidth={500}>
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // validationSchema={validationSchema}
        >
          {({ handleSubmit, values, errors }) => (
            <Box
              borderWidth="2px"
              rounded="lg"
              borderColor="blackAlpha.400"
              shadow="2px 2px 4px rgba(0,0,0,0.3)"
              maxWidth="90vw"
              minWidth="30vw"
              p={6}
              m="10px auto"
              as="form"
              onSubmit={handleSubmit as any}
            >
              <Text variant="label">Utløpsdato på Forsikringsbevis</Text>
              <FormControl isRequired>
                <Input
                
            variant="standardTextInput"
            type="datetime-local"
            name="expiryDate"
            onChange={(e) => (values.expiryDate = e.target.value)}
          />
              </FormControl>
              <Input
          variant="standardFileInput"
          mt="4"
          pt="1.5"
          type="file"
          name="file"
          accept=".pdf"
          onChange={handleFileUploadInsurance}
              ></Input>

              <ButtonGroup mt={4}>
          <Terms onConfirm={() => onSubmit(values)} subject={values} />
              </ButtonGroup>
            </Box>
          )}
        </Formik>
            </VStack>
          </>
        );
}

export default UpdateInsuranceView;
