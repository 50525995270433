/**********************************************************
/ NAME: NewTrip()
/********************************************************** 
 * SUMMARY: 
 * NewTrip allows users to create a new trip by filling out a 
 * form with trip details. The form includes fields for trip name, 
 * truck registration number, compatibility options, available dimensions 
 * and weight, start and end dates, origin and destination, and additional notes.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * createTrip() - Creates a new trip in the database
 * getSelectedTrucks() - Retrieves the selected trucks
 * getLoadingRequirements() - Retrieves the loading requirements
 * 
/********************************************************** 
 * COMPONENTS:
 * - useState: A React hook that lets you add state to function components
 * - useEffect: A React hook that lets you perform side effects in function components
 * - useNavigate: A hook that returns a function that navigates to different pages
 * - OneColumnLayout: A component that renders a one column layout
 * - AsyncSelect: A component that renders an asynchronous select input
 * - MdKeyboardReturn: A component that renders a return icon
 * - Input: A component that renders an input field
 * - Button: A component that renders a button
 * - Text: A component that renders text
 * - Alert: A component that renders an alert
 * - AlertIcon: A component that renders an icon in an alert
 * - AlertDescription: A component that renders a description in an alert
 * - Box: A component that renders a box
 * - SimpleGrid: A component that renders a simple grid
 * - ButtonGroup: A component that groups related buttons
 * - FormControl: A component that renders a form control
 * - NumberInput: A component that renders a number input field
 * - NumberInputField: A component that renders a number input field
 * - Formik: A form library for React
 * - ResetButton: A component that renders a reset button
 * - SubmitButton: A component that renders a submit button
 * - TextareaControl: A component that renders a textarea control
 * - Map: A component that renders a map
 * - Spinner: A component that renders a spinner
 * - StandaloneSearchBox: A component that renders a standalone search box
 * - BrowserView: A component that renders its children only on desktop devices
 * - MobileView: A component that renders its children only on mobile devices
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a form for creating a new trip.
/*********************************************************/
import {
  SimpleGrid,
  AspectRatio,
  Button,
  FormControl,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertDescription,
  NumberInput,
  NumberInputField,
  Link as ChakraLink,
  Heading,
  Tooltip,
  Link,
  IconButton,
} from "@chakra-ui/react";
import { Box, ButtonGroup, Input, Text } from "@chakra-ui/react";
import { Formik } from "formik";
import { ResetButton, SubmitButton, TextareaControl } from "formik-chakra-ui";
import { MdKeyboardReturn } from "react-icons/md";
//import * as Yup from "yup";
import Map from "./Map";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { GOOGLE_API_KEY } from "../../assets/static";
import { useEffect, useState } from "react";
import { Route } from "../../types/RoutePoint";
import OneColumnLayout from "../layout/OneColLayout";
import { useNavigate, Link as ReactRouterLink } from "react-router-dom";
import { createTrip } from "../databasefunctions/trips";
import { Spinner } from "@chakra-ui/spinner";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import { BrowserView, MobileView } from "react-device-detect";
import { ReactSelectMultiStyles } from "../Styles/components/ReactSelectStyles";
import { getSelectedTrucks } from "../databasefunctions/vehicles";
import AsyncSelect from "react-select/async";

import { getLoadingRequirements } from "../databasefunctions/cargos";
import { HiInformationCircle } from "react-icons/hi";

const initialValues = {
  tripName: "",
  truckRegNumber: "",
  coolingCompatible: false,
  toxicCompatible: false,
  availableHeightcm: 0,
  availableLengthcm: 0,
  availableWidthcm: 0,
  availableWeightkg: 0,
  startDate: "",
  endDate: "",
  origin: "",
  destination: "",
  note: "",
};

export default function NewTrip() {
  const navigate = useNavigate();
  // const { projectID } = state as LandTransportScenarioState;
  const [scenarioRoute, setScenarioRoute] = useState<Route | null>(null);
  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [vehicleRegNumber, setVehicleRegNumber] = useState<{
    value: string;
    label: string;
    description: string;
  }>({ value: "", label: "", description: "" });
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isVehiclesLoaded, setIsVehicleLoaded] = useState(false);
  const [ArrivalDateError, setArrivalDateError] = useState(false);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [LoadingRequirements, setLoadingRequirements] = useState<
    [{ value: string; label: string }]
  >([{ value: "", label: "Ingen" }]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });
  let isError = false;
  let waypoints: string[] = ["[]"];
  const onSubmit = async (values: any) => {
    //console.log("Test");
    let LoadingReq: any[] = [];
    LoadingRequirements.forEach((element: any) => {
      if (element.value !== "") {
        LoadingReq.push(`${element.value}`);
      }
    });
    console.log(values.endDate, values.startDate);
    let startDate = new Date(values.startDate).getTime();
    let endDate = new Date(values.endDate).getTime();
    console.log(endDate, startDate);
    let dateError = false;
    if (endDate <= startDate) {
      dateError = true;
      setArrivalDateError(dateError);
      console.log("1");
    }

    console.log(ArrivalDateError);
    //console.log(values.availableWidthcm);
    if (dateError === true) {
      isError = true;
      setErrorMessage("Ankomsttid må være etter avgangstid");
    } else {
      if (vehicleRegNumber.value !== "") {
        await createTrip(
          values.tripName,
          vehicleRegNumber.value,
          //values.truckRegNumber,
          values.availableHeightcm,
          values.availableLengthcm,
          values.availableWidthcm,
          values.availableWeightkg,
          values.startDate,
          values.endDate,
          waypoints,
          origin,
          destination,
          LoadingReq,
          values.note
        )
          .then()
          .catch((error) => {
            isError = true;
            setErrorMessage(error.data.detail);
            //console.log(error.data.detail)
            //console.log(hasError);
          });
      } else {
        isError = true;
        setErrorMessage("Venligst velg et kjøretøy");
      }
    }
    if (isError) {
      setHasError(true);
    } else {
      navigate("/trips", {
        state: {
          notes: values.notes,
          scenarioName: values.scenarioName,
          // projectID: projectID,
        },
      });
    }
  };

  // const validationSchema = Yup.object({
  //   scenarioName: Yup.string().required("You need to provide a scenario name"),
  // });

  function newRoute(
    route: Route,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    setScenarioRoute(route);
    setFieldValue("tripName", route.scenarioName);
    setFieldValue("origin", route.startPoint.name);
    setFieldValue("destination", route.endPoint.name);
  }
  useEffect(() => {
    //console.log(vehicleRegNumber);
  }, [origin, destination]);
  function HandleSelectVehicle(data: any) {
    //console.log(data.value)
    setVehicleRegNumber(data);
    //console.log(vehicleRegNumber)
  }
  function HandleSelectLoadingRequirements(data: any) {
    setLoadingRequirements(data);
  }

  const loadOptionsLoading: any = (callback: Function) => {
    const LoadingRequirements = getLoadingRequirements();
    return LoadingRequirements;
  };

  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
    setScenarioRoute(null);
  }
  const loadOptionsVehicles: any = async (callback: Function) => {
    const Vehicles: any = await getSelectedTrucks();
    if (Vehicles.length > 0) {
      console.log(Vehicles.length);
      setIsVehicleLoaded(true);
    }
    return Vehicles;
  };
  let LoadingReq: any[] = [];
  LoadingRequirements.forEach((element: any) => {
    if (element.value !== "") {
      LoadingReq.push(`${element.value}`);
    }
  });
  const customStyles = ReactSelectMultiStyles();
  // const validationSchema = Yup.object().shape({
  //   tripName: Yup.string().required("Please enter a trip name"),
  //   truckRegNumber: Yup.string().required("Please select a truck"),
  //   availableHeightcm: Yup.number()
  //     .required("Please enter a value")
  //     .positive("Please enter a positive value"),
  //   availableLengthcm: Yup.number()
  //     .required("Please enter a value")
  //     .positive("Please enter a positive value"),
  //   availableWidthcm: Yup.number()
  //     .required("Please enter a value")
  //     .positive("Please enter a positive value"),
  //   availableWeightkg: Yup.number()
  //     .required("Please enter a value")
  //     .positive("Please enter a positive value"),
  //   startDate: Yup.date().required("Please enter a start date"),
  //   endDate: Yup.date().required("Please enter an end date"),
  //   origin: Yup.string().required("Please enter an origin"),
  //   destination: Yup.string().required("Please enter a destination"),
  // });
  const errorMessageVehicles = (
    <ChakraLink as={ReactRouterLink} to="/register-trucks">
      Du har ikke registrert noen lastebilier. Trykk her for å registrere
    </ChakraLink>
  );
  function IsPositive(value: any): boolean {
    return Number(value) >= 0;
  }

  return (
    <OneColumnLayout
      sectionLabel="Create trip"
      sectionHeading=""
      sectionIntro=""
      content={
        <>
          <Heading>
            Registrer transport
            <Tooltip
              label="Se Statens Vegvesens ruteplanlegger her"
              fontSize="md"
              bg="white"
              color="black"
              border="1px solid #333"
              boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
              borderRadius="md"
            >
              <Link
                isExternal
                href="https://www.vegvesen.no/trafikk/kart#/ruteplanlegger?lat=59.43854&lng=10.6673&zoom=10&layer=fer,tra,ctv,tfl"
              >
                <IconButton
                  aria-label="More Information"
                  variant="notification"
                  icon={<HiInformationCircle />}
                ></IconButton>
              </Link>
            </Tooltip>
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width="full">
            <Formik
              initialValues={initialValues}
              //enableReinitialize={true}
              onSubmit={onSubmit}
              //validationSchema={validationSchema}
              onReset={handleReset}
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                <>
                  {/* {!hasResult && ( */}

                  <Box
                    borderWidth="1px"
                    rounded="lg"
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    p={6}
                    m="10px auto"
                    as="form"
                    onSubmit={handleSubmit as any}
                    borderColor="black"
                    maxWidth={"87vw"}
                  >
                    {hasError && (
                      <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>{errorMessage}</AlertDescription>
                      </Alert>
                    )}
                    <BrowserView>
                      <SimpleGrid columns={2} columnGap="4">
                        <Text variant="label">Fra</Text>
                        <Text variant="label">Til</Text>
                        {isLoaded ? (
                          <StandaloneSearchBox>
                            <FormControl isRequired>
                              <Input
                                variant="standardTextInput"
                                placeholder="Adresse"
                                name="origin"
                                onChange={(e) => setOrigin(e.target.value)}
                                onBlur={(e) => {
                                  setOrigin(e.target.value);
                                }}
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                  setOrigin(e.target.value);
                                }}
                              />
                            </FormControl>
                          </StandaloneSearchBox>
                        ) : (
                          <Box>
                            <Spinner size="xl" />
                          </Box>
                        )}
                        {isLoaded ? (
                          <StandaloneSearchBox>
                            <FormControl isRequired>
                              <Input
                                variant="standardTextInput"
                                type="text"
                                placeholder="Adresse"
                                name="destination"
                                onChange={(e) => setDestination(e.target.value)}
                                onBlur={(e) => {
                                  setDestination(e.target.value);
                                }}
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                  setDestination(e.target.value);
                                }}
                              />
                            </FormControl>
                          </StandaloneSearchBox>
                        ) : (
                          <Box>
                            <Spinner size="xl" />
                          </Box>
                        )}
                        <Text variant="label">Start-dato</Text>
                        <Text variant="label">Ankomst-dato</Text>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="datetime-local"
                            name="startDate"
                            onChange={(e) =>
                              (values.startDate = e.target.value)
                            }
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="datetime-local"
                            name="endDate"
                            onChange={(e) => (values.endDate = e.target.value)}
                          />
                        </FormControl>
                      </SimpleGrid>
                    </BrowserView>
                    <MobileView>
                      <Text variant="label">Fra</Text>
                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              placeholder="Adresse"
                              name="origin"
                              onChange={(e) => setOrigin(e.target.value)}
                              onBlur={(e) => {
                                setOrigin(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                setOrigin(e.target.value);
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}
                      <Text variant="label">Til</Text>
                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              type="text"
                              placeholder="Adresse"
                              name="destination"
                              onChange={(e) => setDestination(e.target.value)}
                              onBlur={(e) => {
                                setDestination(e.target.value);
                              }}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                setDestination(e.target.value);
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}
                      <Text variant="label">Start-dato</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="startDate"
                          onChange={(e) => (values.startDate = e.target.value)}
                        />
                      </FormControl>
                      <Text variant="label">Ankomst-dato</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="endDate"
                          onChange={(e) => (values.endDate = e.target.value)}
                        />
                      </FormControl>
                    </MobileView>
                    <Text variant="label" mb="-2">
                      Registreringsnummer
                    </Text>
                    <FormControl isInvalid={!isVehiclesLoaded}>
                      <AsyncSelect
                        defaultOptions
                        loadOptions={loadOptionsVehicles}
                        value={vehicleRegNumber}
                        onChange={(selectedOptions) =>
                          HandleSelectVehicle(selectedOptions)
                        }
                        styles={customStyles}
                      />
                      <FormErrorMessage>
                        {isVehiclesLoaded ? "" : errorMessageVehicles}
                      </FormErrorMessage>
                    </FormControl>
                    {/* <Input
                    name="truckRegNumber"
                    type="string"
                    variant='standardTextInput'
                    onChange={(e) => (values.truckRegNumber = e.target.value)}
                  /> */}
                    <Text variant="label" mb="-1" textTransform="uppercase">
                      Ledig rom
                    </Text>

                    <SimpleGrid columns={3} columnGap="4">
                      
                      <Text variant="label">Lengde [cm]</Text>
                      <Text variant="label">Bredde [cm]</Text>
                      <Text variant="label">Høyde [cm]</Text>
                      
                      <FormControl isRequired>
                        <NumberInput
                          name="availableLengthcm"
                          variant="primary"
                          max={500}
                          onChange={(value) =>
                            (values.availableLengthcm = Number(value))
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </FormControl>
                      <FormControl isRequired>
                        <NumberInput
                          name="availableWidthcm"
                          variant="primary"
                          max={250}
                          onChange={(value) =>
                            (values.availableWidthcm = Number(value))
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </FormControl>
                      <FormControl
                        isRequired
                        isInvalid={!IsPositive(values.availableHeightcm)}
                      >
                        <NumberInput
                          name="availableHeightcm"
                          variant="primary"
                          max={500}
                          onChange={(value) =>
                            (values.availableHeightcm = Number(value))
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                        <FormErrorMessage>Må være positivt</FormErrorMessage>
                      </FormControl>
                    </SimpleGrid>

                    <Text variant="label">Vekt [kg]</Text>
                    <FormControl isRequired>
                      <NumberInput
                        name="availableWeightkg"
                        variant="primary"
                        max={250000}
                        onChange={(value) =>
                          (values.availableWeightkg = Number(value))
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </FormControl>
                    <Text mb="-2" variant="label">
                      Har lastebilen med seg noe laste/losse utstyr?
                    </Text>
                    <AsyncSelect
                      defaultOptions
                      loadOptions={loadOptionsLoading}
                      value={LoadingRequirements}
                      isMulti
                      onChange={(selectedOptions) =>
                        HandleSelectLoadingRequirements(selectedOptions)
                      }
                      styles={customStyles}
                    />

                    <TextareaControl
                      name="note"
                      label="Ytterligere merknader"
                      helperText="Valgfritt - hvis du trenger å notere noe om dette oppdraget."
                      mt={4}
                    />
                    <SimpleGrid rowGap={8}>
                      <ButtonGroup mt={4}>
                        <SubmitButton variant="primary">Lagre</SubmitButton>
                        <ResetButton>Tilbakestill</ResetButton>
                      </ButtonGroup>
                      <Button
                        width="-moz-initial"
                        leftIcon={<MdKeyboardReturn />}
                        variant="return"
                        as="a"
                        href="/trips"
                      >
                        {" "}
                        Tilbake
                      </Button>
                    </SimpleGrid>
                  </Box>

                  <AspectRatio ratio={16 / 9}>
                    <Map
                      onRouteChange={(route) => newRoute(route, setFieldValue)}
                      origin={origin}
                      destination={destination}
                      // key={mapKey}
                      key={`${origin}-${destination}`}
                    />
                  </AspectRatio>
                </>
              )}
            </Formik>
          </SimpleGrid>
        </>
      }
    />
  );
}
