/**********************************************************
/ NAME: TripRow()
/********************************************************** 
 * SUMMARY: 
 * This component represents a single row in the upcoming 
 * trips list. It displays the details of a single trip item.
 * 
/********************************************************** 
 * INPUT: 
 * It takes a trip object as a prop which contains the 
 * details of the trip.
 * 

/********************************************************** 
 * FUNCTIONS:
 * BidAcceptedClick() : Opens a new tab with the payment link
 * GiveOfferButtonClick() : Sends the user to a page to give an offer
 * EvaluateOfferButtonClick() : Sends the user to a page to evaluate the offer
 * 
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns a jsx element that represents a single row in 
 * the upcoming trips list.
/*********************************************************/
import { Button, Link, Grid, Divider, Center } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { isMobile } from "react-device-detect";
import { CreatePaymentLink } from "../databasefunctions/Payments";
import { MdOutlineFindReplace } from "react-icons/md";
import { findTripMatch } from "../databasefunctions/trips";
import { dbAcceptMatch } from "../databasefunctions/matches";

interface TripRowProps {
  trip: {
    route_number: string;
    name: string;
    origin: string;
    start_date_time: string;
    end_date_time: string;
    destination: string;
    vehicle_pk: string;
    available_height_cm: string;
    available_length_cm: string;
    available_width_cm: string;
    available_weight_kg: string;
    note: string;
    loading_capabilities: string[];
    available_matches: {
      tracking_number: string;
      offer: string;
      status: string;
      duration_diff_min: number;
      distance_diff_m: number;
      package: {
        distance_m: number;
        duration_min: number;
        pick_up_point: string;
        drop_of_point: string;
        package_length_cm: number;
        package_width_cm: number;
        package_height_cm: number;
        package_weight_kg: number;
        earliest_start_time: string;
        latest_start_time: string;
        earliest_end_time: string;
        latest_end_time: string;
        loads_types: string[];
        icoterm: { code: string; discription: string };
        note: string;
      };
    };
  };
}
export default function TripRow(props: TripRowProps) {
  const {
    route_number,
    origin,
    start_date_time,

    vehicle_pk,

    available_matches,

    note,
    loading_capabilities,
  } = props.trip;
  const navigate = useNavigate();
  const [renderMe] = useState<boolean>(true);
  const [searchMatch, setSearchMatch] = useState<boolean>(false);

  function GiveOfferButtonClick() {
    //console.log(available_matches['package']['icoterm'])
    //console.log(available_matches['package']['loads_types'])
    navigate("/give-offer", {
      state: {
        distance_diff: available_matches["distance_diff_m"],
        distance_package: available_matches["package"]["distance_m"],
        duration_diff: available_matches["duration_diff_min"],
        duration_package: available_matches["package"]["duration_min"],
        package_height: available_matches["package"]["package_height_cm"],
        package_length: available_matches["package"]["package_length_cm"],
        package_width: available_matches["package"]["package_width_cm"],
        package_weight: available_matches["package"]["package_weight_kg"],
        pick_up_point: available_matches["package"]["pick_up_point"],
        drop_off_point: available_matches["package"]["drop_of_point"],
        earliest_start_time:
          available_matches["package"]["earliest_start_time"],
        latest_start_time: available_matches["package"]["latest_start_time"],
        earliest_end_time: available_matches["package"]["earliest_end_time"],
        latest_end_time: available_matches["package"]["latest_end_time"],
        load_types: available_matches["package"]["loads_types"],
        loading_requirements: loading_capabilities,
        note: note,
        noteCargo: available_matches["package"]["note"],
        incoterms: available_matches["package"]["icoterm"],
        tracking_number: available_matches["tracking_number"],
        car_reg_number: vehicle_pk,
      },
    });
  }
  function EvaluateOfferButtonClick() {
    navigate("/evaluate-offer-carrier", {
      state: {
        distance_diff: available_matches["distance_diff_m"],
        distance_package: available_matches["package"]["distance_m"],
        duration_diff: available_matches["duration_diff_min"],
        duration_package: available_matches["package"]["duration_min"],
        package_height: available_matches["package"]["package_height_cm"],
        package_length: available_matches["package"]["package_length_cm"],
        package_width: available_matches["package"]["package_width_cm"],
        package_weight: available_matches["package"]["package_weight_kg"],
        pick_up_point: available_matches["package"]["pick_up_point"],
        drop_off_point: available_matches["package"]["drop_of_point"],
        earliest_start_time:
          available_matches["package"]["earliest_start_time"],
        latest_start_time: available_matches["package"]["latest_start_time"],
        earliest_end_time: available_matches["package"]["earliest_end_time"],
        latest_end_time: available_matches["package"]["latest_end_time"],
        load_types: available_matches["package"]["loads_types"],
        incoterms: available_matches["package"]["icoterm"],
        tracking_number: available_matches["tracking_number"],
        car_reg_number: vehicle_pk,
        offer: available_matches["offer"],
      },
    });
  }
  async function BidAcceptedClick() {
    //await dbAcceptMatch(trackingNumber,regNumber);
    await CreatePaymentLink(
      available_matches["tracking_number"],
      vehicle_pk,
      Math.ceil(Number(available_matches["offer"]) / 10)
    ).then(async (response) => {
      //window.open(response, "_blank");
      await dbAcceptMatch(available_matches["tracking_number"], vehicle_pk).then(() => {
        navigate("/deal-signed");
      });
    });
    /*navigate("/payment-status", {
      state: {
        tracking_number: available_matches["tracking_number"],
        car_reg_number: vehicle_pk,
      },
    }); */
    
  }
  let buttonSize = "md";
  if (isMobile) {
    buttonSize = "sm";
  }
  const findMatch = async (route_number: any) => {
    setSearchMatch(true);
    await findTripMatch(route_number);
    //console.log(Match?.data?.details?.distance_diff)
  };
  useEffect(() => {
    //console.log(searchMatch)
  }, [searchMatch, route_number]);

  let OfferButton = (
    <Center>
      <Button
        ml="-5"
        variant="primary"
        size={buttonSize}
        leftIcon={<MdOutlineFindReplace />}
        isActive
        onClick={() => findMatch(route_number)}
      >
        Søk
      </Button>
    </Center>
  );
  //console.log(available_matches['status'])
  if (available_matches["status"] === "Pending") {
    OfferButton = (
      <Center>
        <Button
          ml="-2"
          size={buttonSize}
          variant="giveOffer"
          isActive
          onClick={() => GiveOfferButtonClick()}
        >
          Tilbud
        </Button>
      </Center>
    );
  } else if (available_matches["status"] === "Offer Recieved") {
    OfferButton = (
      <Center>
        <Button
          size={buttonSize}
          colorScheme="teal"
          isActive
          onClick={() => EvaluateOfferButtonClick()}
        >
          Evaluer
        </Button>
      </Center>
    );
  } else if (available_matches["status"] === "Accepted") {
    OfferButton = (
      <Center ml="-5">
        <Link href="/rating">
          <Button size={buttonSize} colorScheme="green">
            Tilbakemelding
          </Button>
        </Link>
      </Center>
    );
  } else if (available_matches["status"] === "Bid Accepted") {
    OfferButton = (
      <Center>
        <Button
          ml="-2"
          size={buttonSize}
          variant="primary"
          isActive
          onClick={() => BidAcceptedClick()}
        >
          Signer
        </Button>
      </Center>
    );
  }
  let dateString = start_date_time.split("T");
  if (!renderMe) {
    return <></>;
  }
  return (
    <>
      <Grid ml="-1" mb={2} templateColumns="32% 32% 36%" gap={2}>
        <Center fontSize={"1.0rem"}>
          {dateString[0]} kl: {dateString[1]}
        </Center>
        <Center fontSize={"1.0rem"}>{origin}</Center>

        {!searchMatch && OfferButton}
      </Grid>
      <Divider variant="thick" m="1" width={"95%"}></Divider>
    </>
  );
}
