import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/login/Login";
import Register from "./components/login/Register";

import Reset from "./components/login/Reset";

import AboutUs from "./components/Div pages/AboutUs";
import TripsOverview from "./components/trip/Trips";
import CargoOverview from "./components/cargo/Cargos";
import NewTrip from "./components/trip/NewTrip";
import NewCargo from "./components/cargo/NewCargo";
import UpdateCargo from "./components/updateInfo/UpdateCargo";
import RegisterTrucks from "./components/Trucks/RegisterTruck";
import RegisterCompany from "./components/registerInfo/RegisterCarrier";
import CargoMatchView from "./components/Matches/CargosMatch";
import GiveOfferView from "./components/Matches/GiveOffer";
import EvaluateOfferView from "./components/Matches/EvaluateOfferCarrier";
import EvaluateOfferViewCargoOwner from "./components/Matches/EvaluateOfferCargoOwner";
import TrucksOverview from "./components/Trucks/Trucks";
import UpdateTruck from "./components/updateInfo/UpdateTruck";
import UpdateTrip from "./components/updateInfo/UpdateTrip";
import Contact from "./components/Div pages/Contact";
import Dashboard from "./components/dashboard/Dashboard";
import VerifyEmail from "./components/login/VerifyEmail";
import PaymentStatus from "./components/Matches/PaymentStatus";
import UpdateCompany from "./components/updateInfo/UpdateCompany";
import { myNewTheme } from "./components/Styles/theme";
import NewCargoNewUser from "./components/cargo/NewCargoNewUser";
import RegisterCompanyFromCargo from "./components/login/RegisterUserFromCargo";
import CustomerResponseForm from "./components/respons_form/ResponsForm";
import UpdateInsuranceView from "./components/login/UpdateInsurance";
import DealSigned from "./components/Matches/DealSigned";
// import DrawerExample from "./components/layout/Menu";
function App() {
  return (
    <Router>
      <ChakraProvider theme={myNewTheme}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset" element={<Reset />} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/trips" element={<TripsOverview />} />
          <Route path="/create-trip" element={<NewTrip />} />
          <Route path="/cargos" element={<CargoOverview />} />
          <Route path="/create-cargo" element={<NewCargo />} />
          <Route path="/create-cargo-new-user" element={<NewCargoNewUser />} />
          <Route path="/cargo-match" element={<CargoMatchView />} />
          <Route path="/give-offer" element={<GiveOfferView />} />
          <Route
            path="/evaluate-offer-carrier"
            element={<EvaluateOfferView />}
          />
          <Route
            path="/evaluate-offer-cargo-owner"
            element={<EvaluateOfferViewCargoOwner />}
          />
          <Route path="/register-trucks" element={<RegisterTrucks />} />
          <Route path="/verify" element={<VerifyEmail />} />
          <Route path="/register-company" element={<RegisterCompany />} />
          <Route
            path="/register-company-from-cargo"
            element={<RegisterCompanyFromCargo />}
          />
          <Route path="/trucks-overview" element={<TrucksOverview />} />
          <Route path="/update-truck" element={<UpdateTruck />} />
          <Route path="/update-trip" element={<UpdateTrip />} />
          <Route path="/update-cargo" element={<UpdateCargo />} />
          <Route path="/update-company" element={<UpdateCompany />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/payment-status" element={<PaymentStatus />} />
          <Route path="/deal-signed" element={<DealSigned />} />
          <Route path="/rating" element={<CustomerResponseForm />} />
          <Route path="/update-insurance" element={<UpdateInsuranceView />} />
          {/* <Route path="/menu-test" element={<DrawerExample />} /> */}
        </Routes>
      </ChakraProvider>
    </Router>
  );
}
export default App;
