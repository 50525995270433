/**********************************************************
/ NAME: filterOptions()
/********************************************************** 
 * SUMMARY: 
 * This function filters options based on a search string.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - option: An object with a label property
 * - searchString: The string to search for in the option labels
 * 
/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns a boolean indicating whether the option label 
 * includes the search string.
/*********************************************************/
const filterOptions = (option: { label: string }, searchString: string) => {
  if (!searchString) {
    return true;
  }
  return option.label.toLowerCase().includes(searchString.toLowerCase());
};

export { filterOptions };
