import { useState, useEffect } from "react";

import { getTrucksStatus } from "../databasefunctions/vehicles";
import { getTrips } from "../databasefunctions/trips";
import { getCargos } from "../databasefunctions/cargos";
import { Button, Center, Heading } from "@chakra-ui/react";
import { getStatistics } from "../databasefunctions/company";
/**********************************************************
/ NAME: NumberofTrucks()
/********************************************************** 
 * SUMMARY: 
 * Gets the number of trucks with and without jobs
 * 
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * getTrucksStatus() - Gets the number of trucks with and without jobs
 * 
/********************************************************** 
 * COMPONENTS:
 * 
/*********************************************************/
export function NumberofTrucks() {
  const [trucks, setTrucks] = useState<any>(null);

  useEffect(() => {
    if (!trucks) {
      getTrucksStatus()?.then((p) => setTrucks(p));
    }
  }, [trucks]);

  ////console.log(trucks.length)
  //console.log(trucks)
  if (trucks) {
    //console.log(trucks.length)
    return (
      <>
        <Center>
          <Heading fontSize={"1.4rem"}>Kjøretøy</Heading>
        </Center>
        <Center border="1px" fontSize={"1.1rem"} rounded="lg" mb="3">
          Kjøretøy med oppdrag: {trucks.vehicles_with_jobs}
        </Center>
        <Center border="1px" fontSize={"1.1rem"} rounded="lg" mb="3">
          Kjøretøy uten oppdrag: {trucks.vehicles_without_jobs}
        </Center>
        <Center>
          <Button variant="primary" as="a" href="/trucks-overview" id="openTruckList">
            Registrer
          </Button>
        </Center>
      </>
    );
  } else return <></>;
}
/**********************************************************
/ NAME: Statistics()
/********************************************************** 
 * SUMMARY: 
 * Fetches the statistics from the database and displays them
 * to the user
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * getStatistics() - Fetches the statistics from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * 
/********************************************************** 
 * RETURN:
 * Returns a jsx element with the statistics
/*********************************************************/
export function Statistics() {
  const [statistics, setStatistics] = useState<any>(null);

  useEffect(() => {
    if (!statistics) {
      getStatistics()?.then((p) => setStatistics(p));
    }
  }, [statistics]);

  ////console.log(trucks.length)
  //console.log(statistics)
  if (statistics) {
    return (
      <>
        <Center>
          <Heading>Statistikk</Heading>
        </Center>
        <Center>Antall Oppdrag: {statistics.total_jobs}</Center>
        <Center>Totalt inntjent: {statistics.total_earnings}</Center>
        <Center>CO2-spart: {statistics.co2_saved}</Center>
      </>
    );
  } else return <></>;
}
/**********************************************************
/ NAME: NumberofTrips()
/********************************************************** 
 * SUMMARY: 
 * Fetches the number of trips from the database and displays them
 * to the user
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * getTrips() - Fetches the trips from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * 
/********************************************************** 
 * RETURN:
 * Returns a jsx element with the number of trips. 
 * If the trips data is not available, it returns an empty JSX fragment.
/*********************************************************/
export function NumberofTrips() {
  const [trips, setTrips] = useState<any>(null);

  useEffect(() => {
    if (!trips) {
      getTrips()?.then((p) => setTrips(p));
    }
  }, [trips]);

  ////console.log(trucks.length)
  //console.log(trips)
  if (trips) {
    const count = trips.reduce(
      (acc: number, obj: { available_matches: { [x: string]: string } }) => {
        if (obj.available_matches["status"] === "Pending") {
          return acc + 1;
        } else if (obj.available_matches["status"] === "Offer Recieved") {
          return acc + 1;
        } else {
          return acc;
        }
      },
      0
    );
    //console.log(count)
    return {
      totalTrips: trips.length,
      pendingOrOfferRecievedTrips: count,
    };
  }
}
/**********************************************************
/ NAME: NumberofCargo()
/********************************************************** 
 * SUMMARY: 
 * Fetches the number of cargo from the database and displays them
 * to the user
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * getCargos() - Fetches the cargo from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * 
/********************************************************** 
 * RETURN:
 * Returns a jsx element with the number of cargo. If the 
 * cargo data is not available, it returns an empty JSX fragment.
/*********************************************************/
export function NumberofCargo() {
  const [cargo, setCargo] = useState<any>(null);

  useEffect(() => {
    if (!cargo) {
      getCargos()?.then((p) => setCargo(p));
    }
  }, [cargo]);

  ////console.log(trucks.length)
  //console.log(cargo)
  if (cargo) {
    const count = cargo.reduce(
      (acc: number, obj: { pending_matches: { [x: string]: string } }) => {
        if (obj.pending_matches["status"] === "Offer Recieved") {
          return acc + 1;
        } else {
          return acc;
        }
      },
      0
    );
    //console.log(count)
    return {
      totalcargo: cargo.length,
      OfferRecievedcargo: count,
    };
  }
}
