import axios from "axios";
import { BACKEND_URL } from "../../assets/static";
import { findToken } from "../login/Token";
/**********************************************************
/ NAME: createCargo()
/********************************************************** 
 * SUMMARY: 
 * This function creates a new cargo in the database with the provided details.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - name: The name of the cargo
 * - packageHeightcm, packageLengthcm, packageWidthcm, packageWeightkg: The dimensions and weight of the package
 * - firstStartDate, lastStartDate, firstEndDate, lastEndDate: The start and end dates for the cargo delivery
 * - wayPoints: The waypoints for the cargo delivery
 * - origin, destination: The origin and destination of the cargo
 * - loads: The types of loads for the cargo
 * - incoterm: The international commercial terms for the cargo
 * - minGoodsDamageScore, weightedGoodsDamageScore, minPunctualityScore, weightedPunctualityScore, weightedGroupMembership: The scores for various aspects of the cargo delivery
 * - environmentalCertificate: Whether the cargo requires an environmental certificate
 * - loadingRequirements: The loading requirements for the cargo
 * - notes: Any additional notes for the cargo
 * 

/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything.
/*********************************************************/
async function createCargo(
  name: string,
  packageHeightcm: string,
  packageLengthcm: string,
  packageWidthcm: string,
  packageWeightkg: string,
  firstStartDate: string,
  lastStartDate: string,
  firstEndDate: string,
  lastEndDate: string,
  wayPoints: string[],
  origin: string,
  destination: string,
  loads: string[],
  incoterm: string,
  minGoodsDamageScore: number,
  weightedGoodsDamageScore: number,
  minPunctualityScore: number,
  weightedPunctualityScore: number,
  weightedGroupMembership: number,
  environmentalCertificate: boolean,
  loadingRequirements: string[] | null,
  notes: string
) {
  //console.log(origin);

  var data = JSON.stringify({
    pick_up_point: `${origin}`,
    drop_of_point: `${destination}`,
    package_height_cm: `${packageHeightcm}`,
    package_length_cm: `${packageLengthcm}`,
    package_width_cm: `${packageWidthcm}`,
    package_weight_kg: `${packageWeightkg}`,
    earliest_start_time: `${firstStartDate}`,
    latest_start_time: `${lastStartDate}`,
    earliest_end_time: `${firstEndDate}`,
    latest_end_time: `${lastEndDate}`,
    loads_types: `${loads}`,
    waypoints: [],
    name: `${name}`,
    icoterm: { code: `${incoterm}` },
    need_env_certificate: `${environmentalCertificate}`,
    punctuality_importance: `${weightedPunctualityScore}`,
    punctuality_min: `${minPunctualityScore}`,
    goods_damage_importance: `${weightedGoodsDamageScore}`,
    goods_damage_min: `${minGoodsDamageScore}`,
    group_membership_importance: `${weightedGroupMembership}`,
    loading_requirements: `${loadingRequirements}`,
    note: `${notes}`,
  });
  var data2 = JSON.parse(data);

  data2.loads_types = loads;
  if (loadingRequirements) {
    data2.loading_requirements = loadingRequirements;
  } else {
    delete data2.loading_requirements;
  }

  const token: string = findToken();
  let urlstring;

  urlstring = `${BACKEND_URL}/package`;

  return await axios
    .post(urlstring, data2, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res.data;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: getCargos()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all available cargo packages from the database.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of cargo packages. Each package is an object containing the details of the cargo.
/*********************************************************/
async function getCargos() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/all_available_packages`;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        let cargos: any[] = [];
        cargos.push(...res.data.packages);
        //console.log(cargos);
        return cargos;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: updateCargoDb()
/********************************************************** 
 * SUMMARY: 
 * This function updates the details of a specific cargo in the database.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - trackingNumber: The tracking number of the cargo
 * - packageHeightcm, packageLengthcm, packageWidthcm, packageWeightkg: The updated dimensions and weight of the package
 * - firstStartDate, lastStartDate, firstEndDate, lastEndDate: The updated start and end dates for the cargo delivery
 * - wayPoints: The updated waypoints for the cargo delivery
 * - origin, destination: The updated origin and destination of the cargo
 * - loads: The updated types of loads for the cargo
 * - incoterm: The updated international commercial terms for the cargo

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything.
/*********************************************************/
async function updateCargoDb(
  trackingNumber: string,
  packageHeightcm: string,
  packageLengthcm: string,
  packageWidthcm: string,
  packageWeightkg: string,
  firstStartDate: string,
  lastStartDate: string,
  firstEndDate: string,
  lastEndDate: string,
  wayPoints: string[],
  origin: string,
  destination: string,
  loads: string[],
  incoterm: string
) {
  //console.log(origin);

  var data = JSON.stringify({
    pick_up_point: `${origin}`,
    drop_of_point: `${destination}`,
    package_height_cm: `${packageHeightcm}`,
    package_length_cm: `${packageLengthcm}`,
    package_width_cm: `${packageWidthcm}`,
    package_weight_kg: `${packageWeightkg}`,
    earliest_start_time: `${firstStartDate}`,
    latest_start_time: `${lastStartDate}`,
    earliest_end_time: `${firstEndDate}`,
    latest_end_time: `${lastEndDate}`,
    loads_types: `${loads}`,
    waypoints: [],
    tracking_number: `${trackingNumber}`,
    icoterm: { code: `${incoterm}` },
  });
  var data2 = JSON.parse(data);

  data2.loads_types = loads;
  const token: string = findToken();
  let urlstring;

  urlstring = `${BACKEND_URL}/package`;

  await axios
    .patch(urlstring, data2, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: deleteCargo()
/********************************************************** 
 * SUMMARY: 
 * This function deletes a specific cargo from the database.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - trackingNumber: The tracking number of the cargo to be deleted
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything.
/*********************************************************/
async function deleteCargo(trackingNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/package?tracking_number=${trackingNumber}`;

    await axios
      .delete(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        return;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: getLoadTypes()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all available load types from the database.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of load types. Each load type is an 
 * object containing the value and label of the load type.
/*********************************************************/
async function getLoadTypes() {
  try {
    let urlstring;
    urlstring = `${BACKEND_URL}/loads_types`;
    return await axios.get(urlstring).then((res) => {
      //console.log(res);
      //console.log(res.data);
      let cargos: any[] = [];
      res.data.forEach((element: any) => {
        cargos.push({ value: `${element}`, label: `${element}` });
      });
      //cargos.push(...res.data);
      //console.log(cargos);
      return cargos;
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}
/**********************************************************
/ NAME: getIncoterms()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all available Incoterms from the database.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of Incoterms. Each Incoterm is an 
 * object containing the value and label of the Incoterm.
/*********************************************************/
async function getIncoterms() {
  try {
    let urlstring;
    urlstring = `${BACKEND_URL}/all_icoterms`;
    return await axios.get(urlstring).then((res) => {
      //console.log(res);
      //console.log(res.data);
      let incoterms: any[] = [];
      Object.values(res.data.icoterm).forEach((element: any) => {
        //console.log(element)
        incoterms.push({
          value: `${element.code}`,
          label: `${element.code}`,
          description: `${element.discription}`,
        });
      });
      //cargos.push(...res.data);
      //console.log(incoterms);
      return incoterms;
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}
/**********************************************************
/ NAME: getLoadingRequirements()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all available loading requirements 
 * from the database.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of loading requirements. Each loading requirement is an 
 * object containing the value and label of the requirement.
/*********************************************************/
async function getLoadingRequirements() {
  try {
    let urlstring;
    urlstring = `${BACKEND_URL}/loading_requirements`;
    return await axios.get(urlstring).then((res) => {
      //console.log(res);
      //console.log(res.data);
      let loadingRequirements: any[] = [];
      res.data.forEach((element: any) => {
        loadingRequirements.push({ value: `${element}`, label: `${element}` });
      });
      //LoadingRequirements.push(...res.data);
      //console.log(loadingRequirements);
      return loadingRequirements;
    });
  } catch (err) {
    console.error(err);
    return [];
  }
}
/**********************************************************
/ NAME: getUpcomingCargos()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all upcoming cargo packages from the database.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of upcoming cargo packages. Each package is an object containing the details of the cargo.
/*********************************************************/
async function getUpComingCargos() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/up_coming_packages`;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        let cargos: any[] = [];
        cargos.push(...res.data.packages);
        //console.log(cargos);
        return cargos;
      });
  } catch (err) {
    console.error(err);
  }
}
/**********************************************************
/ NAME: getNeedMatchesCargo()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all cargo packages that need matches 
 * from the database.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of cargo packages that need matches. 
 * Each package is an object containing the details of the cargo.
/*********************************************************/
async function getNeedMatchesCargo() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/need_match_packages`;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        let cargos: any[] = [];
        cargos.push(...res.data.packages);
        //console.log(cargos);
        return cargos;
      });
  } catch (err) {
    console.error(err);
  }
}
/**********************************************************
/ NAME: storePackageFiles()
/********************************************************** 
 * SUMMARY: 
 * This function uploads a file for a specific cargo package to the database.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - trackingNumber: The tracking number of the cargo
 * - file: The file to be uploaded
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything.
/*********************************************************/
async function storePackageFiles(trackingNumber: string, file: File) {
  const token: string = findToken();
  const formData = new FormData();
  formData.append("file", file, file.name);

  try {
    await axios.post(
      `${BACKEND_URL}/package_files?tracking_number=${trackingNumber}`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    //console.log(response.data);
  } catch (error) {
    console.error(error);
  }
}
export {
  createCargo,
  getCargos,
  updateCargoDb,
  deleteCargo,
  getLoadTypes,
  getIncoterms,
  storePackageFiles,
  getLoadingRequirements,
  getUpComingCargos,
  getNeedMatchesCargo,
};
