import axios from "axios";
import { findToken } from "../login/Token";
import { BACKEND_URL } from "../../assets/static";
/**********************************************************
/ NAME: createTrip()
/********************************************************** 
 * SUMMARY: 
 * This function creates a trip with the provided details and sends it to the server.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - name: The name of the trip
 * - regNumber: The registration number of the vehicle
 * - availableHeightcm: The available height in cm
 * - availableLengthcm: The available length in cm
 * - availableWidthcm: The available width in cm
 * - availableWeightkg: The available weight in kg
 * - startDate: The start date of the trip
 * - endDate: The end date of the trip
 * - wayPoints: The waypoints of the trip
 * - origin: The origin of the trip
 * - destination: The destination of the trip
 * - loadingRequirements: The loading requirements of the trip
 * - note: Any additional notes
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the trip details to the server.
/*********************************************************/
async function createTrip(
  name: string,
  regNumber: string,
  availableHeightcm: string,
  availableLengthcm: string,
  availableWidthcm: string,
  availableWeightkg: string,
  startDate: string,
  endDate: string,
  wayPoints: string[],
  origin: string,
  destination: string,
  loadingRequirements: string[],
  note: string
) {
  //console.log(availableWidthcm);
  //console.log(availableWeightkg);
  //console.log(startDate);
  //console.log(origin);

  var data = JSON.stringify({
    origin: `${origin}`,
    destination: `${destination}`,
    available_height_cm: `${availableHeightcm}`,
    available_length_cm: `${availableLengthcm}`,
    available_width_cm: `${availableWidthcm}`,
    available_weight_kg: `${availableWeightkg}`,
    start_date_time: `${startDate}`,
    end_date_time: `${endDate}`,
    waypoints: [],
    name: `${name}`,
    vehicle_pk: `${regNumber}`,
    loading_capabilities: `${loadingRequirements}`,
    note: `${note}`,
  });
  var data2 = JSON.parse(data);

  data2.loading_capabilities = loadingRequirements;
  const token: string = findToken();

  let urlstring;

  urlstring = `${BACKEND_URL}/route`;

  //console.log(createTrip);
  await axios
    .post(urlstring, data2, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: getTrips()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all available trips from the server.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of all available trips.
/*********************************************************/
async function getTrips() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/all_available_routes`;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        let trips: any[] = [];
        trips.push(...res.data.routes);
        return trips;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: deleteTrip()
/********************************************************** 
 * SUMMARY: 
 * This function deletes a specific trip from the server 
 * using the trip's number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - tripNumber: The number of the trip
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends a 
 * delete request to the server.
/*********************************************************/
async function deleteTrip(tripNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;
    urlstring = `${BACKEND_URL}/route?route_number=${tripNumber}`;
    await axios
      .delete(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        return;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: updateTrip()
/********************************************************** 
 * SUMMARY: 
 * This function updates the details of a specific trip on 
 * the server using the trip's number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - routeNumber: The number of the trip
 * - regNumber: The registration number of the vehicle
 * - availableHeightcm: The available height in cm
 * - availableLengthcm: The available length in cm
 * - availableWidthcm: The available width in cm
 * - availableWeightkg: The available weight in kg
 * - startDate: The start date of the trip
 * - endDate: The end date of the trip
 * - wayPoints: The waypoints of the trip
 * - origin: The origin of the trip
 * - destination: The destination of the trip
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the 
 * updated trip details to the server.
/*********************************************************/
async function updateTrip(
  routeNumber: string,
  regNumber: string,
  availableHeightcm: string,
  availableLengthcm: string,
  availableWidthcm: string,
  availableWeightkg: string,
  startDate: string,
  endDate: string,
  wayPoints: string[],
  origin: string,
  destination: string
) {
  //console.log(availableWidthcm);
  //console.log(availableWeightkg);
  //console.log(startDate);
  //console.log(origin);

  var data = JSON.stringify({
    origin: `${origin}`,
    destination: `${destination}`,
    available_height_cm: `${availableHeightcm}`,
    available_length_cm: `${availableLengthcm}`,
    available_width_cm: `${availableWidthcm}`,
    available_weight_kg: `${availableWeightkg}`,
    start_date_time: `${startDate}`,
    end_date_time: `${endDate}`,
    waypoints: [],
    route_number: `${routeNumber}`,
    vehicle_pk: `${regNumber}`,
  });

  const token: string = findToken();

  let urlstring;

  urlstring = `${BACKEND_URL}/route`;

  //console.log(createTrip);
  await axios
    .patch(urlstring, data, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}
/**********************************************************
/ NAME: getUpcomingTrips()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all upcoming trips from the server.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 
/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of all upcoming trips.
/*********************************************************/
async function getUpComingTrips() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/up_coming_routes`;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        let trips: any[] = [];
        trips.push(...res.data.route);
        return trips;
      });
  } catch (err) {
    console.error(err);
  }
}
/**********************************************************
/ NAME: getNeedMatchesTrips()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all trips that need matches from the server.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 
/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of all trips that need matches.
/*********************************************************/
async function getNeedMatchesTrips() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/need_match_routes`;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        let trips: any[] = [];
        trips.push(...res.data.route);
        return trips;
      });
  } catch (err) {
    console.error(err);
  }
}

async function findTripMatch(tripNumber: string) {
  const token: string = findToken();

  let urlstring;

  urlstring = `${BACKEND_URL}/find_package_match?route_number=${tripNumber}`;

  return await axios
    .post(urlstring, "", { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      return res.data;
      
    })
    .catch((error) => {
      //console.log(error)
      throw error.response;
    });
}
export {
  createTrip,
  getTrips,
  updateTrip,
  deleteTrip,
  getUpComingTrips,
  getNeedMatchesTrips,
  findTripMatch,
};
