import React from "react";
import CreatableSelect from "react-select/creatable";
import { ReactSelectMultiStyles } from "../Styles/components/ReactSelectStyles";
interface Option {
  label: string;
  value: string;
}
interface CreateableProps {
  CreateableValue: Option[];
  setCreateableValue: (val: Option[]) => void;
  options: Option[];
}

type OptionType = { value: string; label: string };
/**********************************************************
/ NAME: Createable()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a CreatableSelect 
 * component from "react-select/creatable". It allows the 
 * user to select multiple options and also create new options.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following props:
 * - CreateableValue: An array of options that are currently selected
 * - setCreateableValue: A function that updates the selected options
 * - options: An array of options that can be selected
 * 

/********************************************************** 
 * FUNCTIONS:
 * handleSelect(data: any) - Updates the selected options 
 * with the provided data
 * handleCreate(inputValue: string) - Creates a new option with 
 * the provided inputValue and adds it to the selected options
 * 
/********************************************************** 
 * COMPONENTS:
 * - CreatableSelect: A component that allows the user to s
 * elect multiple options and also create new options
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a CreatableSelect component.
/*********************************************************/
function Createable({ CreateableValue, setCreateableValue }: CreateableProps) {
  const customStyles = ReactSelectMultiStyles();
  function handleSelect(data: any) {
    setCreateableValue(data);
  }

  const handleCreate = (inputValue: string) => {
    const newOption: OptionType = {
      value: inputValue.toLowerCase(),
      label: inputValue,
    };
    setCreateableValue([...CreateableValue, newOption]);
  };

  return (
    <CreatableSelect
      value={CreateableValue}
      onChange={handleSelect}
      onCreateOption={handleCreate}
      isClearable
      isSearchable
      isMulti
      styles={customStyles}
    />
  );
}

export default Createable;
