import { useColorMode } from "@chakra-ui/react"
//const colorMode=useColorMode()
  
export function ReactSelectMultiStyles() {
    const colorMode=useColorMode();
    return{
  control: (base: any) => ({
    
    ...base,
    height: "fitContent",
    minHeight: 40,
    autoSize:true,
    maxHeight:300,
    color: colorMode.colorMode==="dark" ? 'white' : '#333333',
    border: '1px solid',
    borderRadius:"7px",
    backgroundColor: colorMode.colorMode==="dark" ? 'gray.100' : '#EDF2F7',
    '&:hover': {
        border: '1px solid',
        borderColor: '#1c7817',
      },
      '&:focus': {
        borderColor: '#1c7817'
      },
    
    marginTop: 10,
    fontSize: 20
  }),

  menu: (base: any) => ({
    ...base,
    backgroundColor: colorMode.colorMode==="dark" ? '#333333' : '#EDF2F7',
  }),
  option: (base: any, state: any) => ({
    ...base,
    '&:hover': {
      backgroundColor: '#A5D6A2', // medium grey background on hover
    },
    '&:active':{backgroundColor: '#646464', // medium grey background on hover

    }
  }),

  multiValue: (base: any) => ({
    ...base,
    marginTop: 0,
    marginBottom: 5,
    backgroundColor: colorMode.colorMode==="dark" ? "#5EAE59" : "#5EAE59"
    ,
    color: "#fff"

  }),
  MultiValueLabel: (base: any) => ({
    ...base,
    color: "#fff"
  })
}
}

export function ReactSelectSingleStyles()  {
    const colorMode=useColorMode();
    return{
    control: (base: any, state:any) => ({
      ...base,
      height: 40,
      minHeight: 40,
      color: colorMode.colorMode==="dark" ? 'white' : '#333333',
      border: '1px solid',
      borderRadius:"7px",
      backgroundColor: colorMode.colorMode==="dark" ? 'gray.100' : '#EDF2F7',
      
      marginTop: 10,
      fontSize: 18
    }),
    menu: (base: any) => ({
        ...base,
        
        backgroundColor: colorMode.colorMode==="dark" ? '#333333' : '#EDF2F7',
      }),
      singleValue: (base: any) => ({
        ...base,
        marginTop: 0,
        marginBottom: 5,
        
        color: colorMode.colorMode==="dark" ? 'white' : '#333333',
    
      }),
      option: (base: any, state: any) => ({
        ...base,
        '&:hover': {
          backgroundColor: '#646464', // medium grey background on hover
        },
        '&:active':{backgroundColor: '#646464', // medium grey background on hover

        }
      }),
  }
}