import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const standardTextInput = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'black',
    background: 'gray.100',
    borderRadius: '7',
    borderWidth:"thin",
    mt:"4",
    mb:"4",
    fontSize:"xl",
    _hover:{
        transform: "scale(1.02)",
        borderColor:"primary"
    },
    _focus:{
        borderColor:"primary",
        borderWidth:"medium"
    },
    // Let's also provide dark mode alternatives
    _dark: {
      borderColor: 'white',
      background: 'gray.800',
    },
    
  },
  addon: {
    border: '1px solid',
    borderColor: 'gray.200',
    background: 'gray.200',
    borderRadius: '7',
    color: 'gray.500',

    _dark: {
      borderColor: 'gray.600',
      background: 'gray.600',
      color: 'gray.400',
    },
  },
})

const standardFileInput = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'black',
    background: 'gray.100',
    borderRadius: '7',
    borderWidth:"thin",
    mt:"4",
    mb: "4",
    paddingTop: "-10",
    paddingBottom: "10",
    fontSize:"xl",
    _hover:{
        transform: "scale(1.02)",
        borderColor:"primary"
    },
    _focus:{
        borderColor:"primary",
        borderWidth:"medium"
    },
    // Let's also provide dark mode alternatives
    _dark: {
      borderColor: 'white',
      background: 'gray.800',
    },
    
  },
  addon: {
    border: '1px solid',
    borderColor: 'gray.200',
    background: 'gray.200',
    borderRadius: '7',
    color: 'gray.500',

    _dark: {
      borderColor: 'gray.600',
      background: 'gray.600',
      color: 'gray.400',
    },
  },
})

export const inputTheme = defineMultiStyleConfig({
  variants: { standardTextInput, standardFileInput},
})