import axios from "axios";
import { BACKEND_URL } from "../../assets/static";
import { findToken } from "../login/Token";
import { saveInfoToSS } from "./storeandfind";
/**********************************************************
/ NAME: getCompanyInformation()
/********************************************************** 
 * SUMMARY: 
 * This function fetches the information of a company from the database using the company's registration number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - regNumber: The registration number of the company
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * saveInfoToSS() - Saves the company's name and address to session storage
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything.
/*********************************************************/
async function getCompanyInformation(regNumber: string) {
  const token: string = findToken();

  let urlstring;

  urlstring = `${BACKEND_URL}/compeny?organization_num=${regNumber}`;

  //console.log(getCompanyInformation);
  await axios
    .get(urlstring, { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      //console.log(res.data["forretningsadresse"]["adresse"]);
      let companyAddress: string = `${res.data["forretningsadresse"]["adresse"]} ${res.data["forretningsadresse"]["postnummer"]} ${res.data["forretningsadresse"]["poststed"]}`;
      //console.log(companyAddress);
      saveInfoToSS("companyName", res.data["name"]);
      saveInfoToSS("companyAddress", companyAddress);

      return;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: saveCargoOwner()
/********************************************************** 
 * SUMMARY: 
 * This function saves the details of a cargo owner to the database.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - regNumber: The registration number of the cargo owner
 * - accountingEmail: The accounting email of the cargo owner
 * - companyAddress: The address of the cargo owner
 * - name: The name of the cargo owner
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything.
/*********************************************************/
async function saveCargoOwner(
  regNumber: string,
  accountingEmail: string,
  companyAddress: string,
  name: string
) {
  const token: string = findToken();
  var data = JSON.stringify({
    business_reg_nr: `${regNumber}`,
    accounting_email: `${accountingEmail}`,
    company_adress: `${companyAddress}`,
    company_name: `${name}`,
  });
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/cargo_owner`;

    await axios
      .patch(urlstring, data, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: saveCarrier()
/********************************************************** 
 * SUMMARY: 
 * This function saves the details of a carrier to the database.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - regNumber: The registration number of the carrier
 * - accountingEmail: The accounting email of the carrier
 * - companyAddress: The address of the carrier
 * - name: The name of the carrier
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything.
/*********************************************************/
async function saveCarrier(
  regNumber: string,
  accountingEmail: string,
  companyAddress: string,
  name: string
) {
  var data = JSON.stringify({
    business_reg_nr: `${regNumber}`,
    accounting_email: `${accountingEmail}`,
    company_adress: `${companyAddress}`,
    company_name: `${name}`,
  });
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/carrier`;

    await axios.patch(urlstring, data, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: saveFile()
/********************************************************** 
 * SUMMARY: 
 * This function uploads a file to the server.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - fileType: The type of the file
 * - file: The file to be uploaded
 * - required: A boolean indicating whether the file is required or not
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything.
/*********************************************************/
async function saveFile(fileType: string, file: string, required: boolean) {
  const token: string = findToken();
  const formData = new FormData();
  formData.append("file", file);
  try {
    let urlstring;
    urlstring = `${BACKEND_URL}/file?file_type=${fileType}&is_required=${required}`;
    await axios
      .post(urlstring, formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: getCollaboratorNames()
/********************************************************** 
 * SUMMARY: 
 * This function fetches the name of a collaborator from the 
 * database using the collaborator's registration number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - regNumber: The registration number of the collaborator
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the name of the collaborator.
/*********************************************************/
async function getCollaboratorNames(regNumber: string) {
  const token: string = findToken();

  let urlstring;

  urlstring = `${BACKEND_URL}/compeny?organization_num=${regNumber}`;

  //console.log(getCompanyInformation);
  return await axios
    .get(urlstring, { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      let companyName: string = `${res.data["name"]}, `;
      //console.log(companyName);
      return companyName;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: getStatistics()
/********************************************************** 
 * SUMMARY: 
 * This function fetches statistical data from the server.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the statistical data.
/*********************************************************/
async function getStatistics() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/status
    `;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);

        return res.data;
      });
  } catch (err) {
    console.error(err);
  }
}
export {
  getCompanyInformation,
  saveCargoOwner,
  saveCarrier,
  saveFile,
  getCollaboratorNames,
  getStatistics,
};
