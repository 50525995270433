/**********************************************************
/ NAME: NewCargoNewUser()
/********************************************************** 
 * SUMMARY: 
 * NewCargoNewUser is the component that lets the user create a new cargo
 * Before the user has created a user profile
 * 
/********************************************************** 
 * INPUT: 
 * packageNumber: The package number of the cargo
 * LoadingRequirements: The loading requirements of the cargo
 * LoadTypes: The load types of the cargo
 * requiredHeightcm: The required height of the cargo
 * requiredLengthcm: The required length of the cargo
 * requiredWidthcm: The required width of the cargo
 * requiredWeightkg: The required weight of the cargo
 * firstStartDate: The first start date of the cargo
 * lastStartDate: The last start date of the cargo
 * firstEndDate: The first end date of the cargo
 * lastEndDate: The last end date of the cargo
 * origin: The origin of the cargo
 * destination: The destination of the cargo
 * notes: The notes of the cargo
 * 
/********************************************************** 
 * FUNCTIONS:
 * handleSubmit(): Handles the submit of the form
 * handleReset(): Handles the reset of the form
 * handleLoadTypeChange(): Handles the change of the load type
 * handleIncoTermChange(): Handles the change of the inco term
 * handleLoadingRequirementsChange(): Handles the change of the loading requirements
 * getLoadTypes(): Gets the load types from the database
 * getIncoTerms(): Gets the inco terms from the database
 * getLoadingRequirements(): Gets the loading requirements from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * 
/*********************************************************/
import {
  SimpleGrid,
  AspectRatio,
  FormControl,
  Alert,
  AlertIcon,
  AlertDescription,
  NumberInput,
  NumberInputField,
  IconButton,
  Heading,
  Link,
} from "@chakra-ui/react";
import { Box, ButtonGroup, Input, Text, Button } from "@chakra-ui/react";
import { Formik } from "formik";
import { SubmitButton, TextareaControl } from "formik-chakra-ui";

//import * as Yup from "yup";
import Map from "./Map";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import {
  ReactSelectMultiStyles,
  ReactSelectSingleStyles,
} from "../Styles/components/ReactSelectStyles";
import { useState, useEffect } from "react";
import { Route } from "../../types/RoutePoint";
import OneColumnLayout from "../layout/OneColLayoutNewUser";
import { useNavigate } from "react-router-dom";
import { getIncoterms } from "../databasefunctions/cargos";
import { getLoadTypes } from "../databasefunctions/cargos";
import AsyncSelect from "react-select/async";
import { MdKeyboardReturn } from "react-icons/md";
import { GOOGLE_API_KEY } from "../../assets/static";
import { Spinner } from "@chakra-ui/spinner";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import { BrowserView, MobileView } from "react-device-detect";
import { HiInformationCircle } from "react-icons/hi";

const initialValues = {
  packageName: "",
  requireCooling: false,
  requireToxic: false,
  requiredHeightcm: 0,
  requiredLengthcm: 0,
  requiredWidthcm: 0,
  requiredWeightkg: 0,
  firstStartDate: "",
  lastStartDate: "",
  firstEndDate: "",
  lastEndDate: "",
  origin: "",
  destination: "",
  notes: "",
};

export default function NewCargoNewUser() {
  const navigate = useNavigate();
  // const { projectID } = state as LandTransportScenarioState;
  const [LoadType, setLoadType] = useState<{ value: string; label: string }>();
  const [incoTerm, setIncoTerms] = useState<{
    value: string;
    label: string;
    description: string;
  }>({ value: "", label: "", description: "" });
  const [LoadingRequirements] = useState<[{ value: string; label: string }]>([
    { value: "", label: "Ingen spesielle behov" },
  ]);
  const [renderMe, setRenderMe] = useState<boolean>(false);
  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });
  let isError = false;

  const onSubmit = async (values: any) => {
    //console.log("Test");
    //console.log(values.availableWidthcm);
    let loadTypes: any[] = [];
    if (LoadType) {
      loadTypes.push(`${LoadType.value}`);
    }
    let LoadingReq: any[] = [];
    LoadingRequirements.forEach((element: any) => {
      if (element.value !== "") {
        LoadingReq.push(`${element.value}`);
      }
    });
    //console.log(values)
    if (incoTerm.value !== "") {
      navigate("/register-company-from-cargo", {
        state: {
          cargoName: values.packageName,
          cargoPickUpPoint: values.origin,
          cargoEarliestStartDate: values.firstStartDate,
          cargoLatestStartDate: values.lastStartDate,
          cargoEarliestEndDate: values.firstEndDate,
          cargoLatestEndDate: values.lastEndDate,
          cargoDropOfPoint: values.destination,
          cargoIncoTerms: incoTerm,
          cargoLoadTypes: loadTypes,

          cargoPackageHeightcm: values.requiredHeightcm,
          cargoPackageLengthcm: values.requiredLengthcm,
          cargoPackageWidthcm: values.requiredWidthcm,
          cargoPackageWeightkg: values.requiredWeightkg,

          cargoPackageNotes: values.notes,
        },
      });
    } else {
      isError = true;
      setErrorMessage("Incoterm mangler");
    }
    if (isError) {
      setHasError(true);
    }
  };

  // const validationSchema = Yup.object({
  //   scenarioName: Yup.string().required("You need to provide a scenario name"),
  // });

  function newRoute(
    route: Route,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    setFieldValue("tripName", route.scenarioName);
    setFieldValue("origin", route.startPoint.name);
    setFieldValue("destination", route.endPoint.name);
  }

  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
  }

  function handleSelectLoad(data: any) {
    setLoadType(data);
  }

  function HandleSelectInco(data: any) {
    setIncoTerms(data);
    setRenderMe(true);
    //console.log(incoTerm)
  }

  const filterOptions = (option: { label: string }, searchString: string) => {
    if (!searchString) {
      return true;
    }
    return option.label.toLowerCase().includes(searchString.toLowerCase());
  };
  useEffect(() => {
    //console.log(incoTerm)
  }, [incoTerm]);
  const loadOptionsLoads: any = (callback: Function) => {
    const LoadTypes = getLoadTypes();
    return LoadTypes;
  };

  const customStyles = ReactSelectMultiStyles();

  const loadOptionsInco: any = (callback: Function) => {
    const Incoterms = getIncoterms();
    return Incoterms;
  };

  const customStylesInco = ReactSelectSingleStyles();
  // const validateLastStartDate=(firstStartDate:string, lastStartDate:string)=> {
  //   let error
  //   const firstDate=new Date(firstStartDate)
  //   const lastDate= new Date(lastStartDate)
  //   //console.log(firstDate, lastDate)
  //    if (firstDate>=lastDate) {
  //     error = true
  //   }
  //   return error
  // }

  // Add a function that checks that lastStartDate is after firstStartDate
  // Add a function that checks that lastEndDate is after firstEndDate

  return (
    <OneColumnLayout
      sectionLabel="Create Cargo"
      sectionHeading=""
      sectionIntro=""
      content={
        <>
          <Heading>
            Registrer gods
            <Link
              isExternal
              href="https://www.altinn.no/starte-og-drive/e-guide-for-godstransport-pa-vei/"
            >
              <IconButton
                aria-label="User"
                variant="notification"
                icon={<HiInformationCircle />}
              ></IconButton>
            </Link>
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width="full">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              //validationSchema={validationSchema}
              onReset={handleReset}
              enableReinitialize
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                <>
                  {/* {!hasResult && ( */}

                  <Box
                    borderWidth="1px"
                    borderColor="blackAlpha.600"
                    rounded="lg"
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    p={6}
                    m="10px auto"
                    as="form"
                    onSubmit={handleSubmit as any}
                    maxWidth="87vw"
                    width="full"
                  >
                    {hasError && (
                      <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>{errorMessage}</AlertDescription>
                      </Alert>
                    )}

                    <BrowserView>
                      <SimpleGrid columns={2} columnGap="4">
                        <Text variant="label">Fra</Text>
                        <Text variant="label">Til</Text>

                        {isLoaded ? (
                          <StandaloneSearchBox>
                            <FormControl isRequired>
                              <Input
                                variant="standardTextInput"
                                name="origin"
                                placeholder="Adresse"
                                onChange={(e) =>
                                  (values.origin = e.target.value)
                                }
                                onBlur={(e) => {
                                  values.origin = e.target.value;
                                }}
                                // onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                  values.origin = e.target.value;
                                }}
                              />
                            </FormControl>
                          </StandaloneSearchBox>
                        ) : (
                          <Box>
                            <Spinner size="xl" />
                          </Box>
                        )}

                        {isLoaded ? (
                          <StandaloneSearchBox>
                            <FormControl isRequired>
                              <Input
                                variant="standardTextInput"
                                type="text"
                                placeholder="Adresse"
                                name="destination"
                                onBlur={(e) => {
                                  values.destination = e.target.value;
                                }}
                                onChange={(e) =>
                                  (values.destination = e.target.value)
                                }
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                  values.destination = e.target.value;
                                }}
                              />
                            </FormControl>
                          </StandaloneSearchBox>
                        ) : (
                          <Box>
                            <Spinner size="xl" />
                          </Box>
                        )}
                      </SimpleGrid>
                    </BrowserView>
                    <MobileView>
                      <Text variant="label">Fra</Text>
                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              name="origin"
                              placeholder="Adresse"
                              onChange={(e) => (values.origin = e.target.value)}
                              onBlur={(e) => {
                                values.origin = e.target.value;
                              }}
                              // onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                values.origin = e.target.value;
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}
                      <Text variant="label">Til</Text>
                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              type="text"
                              placeholder="Adresse"
                              name="destination"
                              onChange={(e) =>
                                (values.destination = e.target.value)
                              }
                              onBlur={(e) => {
                                values.destination = e.target.value;
                              }}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                values.destination = e.target.value;
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}
                    </MobileView>
                    <Text
                      textTransform="uppercase"
                      variant="label"
                      mb="0"
                      fontWeight="bold"
                    >
                      Sendingsdato
                    </Text>
                    <BrowserView>
                      <SimpleGrid columns={2} columnGap={2}>
                        <Text variant="label">Tidligst</Text>
                        <Text variant="label">Siste</Text>

                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="datetime-local"
                            name="firstStartDate"
                            onChange={(e) =>
                              (values.firstStartDate = e.target.value)
                            }
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="datetime-local"
                            name="lastStartDate"
                            onChange={(e) => {
                              values.lastStartDate = e.target.value;
                            }}
                          />
                        </FormControl>
                      </SimpleGrid>
                    </BrowserView>
                    <MobileView>
                      <Text variant="label">Tidligst</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="firstStartDate"
                          onChange={(e) =>
                            (values.firstStartDate = e.target.value)
                          }
                        />
                      </FormControl>
                      <Text variant="label">Siste</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="lastStartDate"
                          onChange={(e) =>
                            (values.lastStartDate = e.target.value)
                          }
                        />
                      </FormControl>
                    </MobileView>

                    <Text textTransform="uppercase" variant="label" mb="0">
                      Ankomstdato
                    </Text>
                    <BrowserView>
                      <SimpleGrid columns={2} columnGap={2}>
                        <Text variant="label">Tidligst</Text>
                        <Text variant="label">Siste</Text>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="datetime-local"
                            name="firstEndDate"
                            onChange={(e) =>
                              (values.firstEndDate = e.target.value)
                            }
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <Input
                            type="datetime-local"
                            name="lastEndDate"
                            onChange={(e) =>
                              (values.lastEndDate = e.target.value)
                            }
                            variant="standardTextInput"
                          />
                        </FormControl>
                      </SimpleGrid>
                    </BrowserView>
                    <MobileView>
                      <Text variant="label">Tidligst</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="firstEndDate"
                          onChange={(e) =>
                            (values.firstEndDate = e.target.value)
                          }
                        />
                      </FormControl>
                      <Text variant="label">Siste</Text>
                      <FormControl isRequired>
                        <Input
                          type="datetime-local"
                          name="lastEndDate"
                          onChange={(e) =>
                            (values.lastEndDate = e.target.value)
                          }
                          variant="standardTextInput"
                        />
                      </FormControl>
                    </MobileView>
                    <Text textTransform="uppercase" variant="label" mb="0">
                      Dimensjoner
                    </Text>
                    <SimpleGrid columns={3} columnGap="2">
                      
                      <Text variant="label">Lengde [cm]</Text>
                      <Text variant="label">Bredde [cm]</Text>
                      <Text variant="label">Høyde [cm]</Text>
                      
                      <FormControl isRequired>
                        <NumberInput
                          name="requiredLengthcm"
                          variant="primary"
                          max={1500}
                          onChange={(value) =>
                            (values.requiredLengthcm = Number(value))
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </FormControl>
                      <FormControl isRequired>
                        <NumberInput
                          name="requiredWidthcm"
                          variant="primary"
                          max={220}
                          onChange={(value) =>
                            (values.requiredWidthcm = Number(value))
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </FormControl>
                      <FormControl isRequired>
                        <NumberInput
                          name="requiredHeightcm"
                          variant="primary"
                          max={500}
                          onChange={(value) =>
                            (values.requiredHeightcm = Number(value))
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </FormControl>
                    </SimpleGrid>
                    <Text variant="label">Vekt [kg]</Text>
                    <FormControl isRequired>
                      <NumberInput
                        name="requiredWeightkg"
                        variant="primary"
                        max={50000}
                        onChange={(value) =>
                          (values.requiredWeightkg = Number(value))
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </FormControl>
                    <Text variant="label" mb="-2">
                      Varetype
                    </Text>

                    <AsyncSelect
                      defaultOptions
                      loadOptions={loadOptionsLoads}
                      value={LoadType}
                      onChange={(selectedOptions) =>
                        handleSelectLoad(selectedOptions)
                      }
                      filterOption={filterOptions}
                      styles={customStyles}
                    />
                    <Text variant="label" mb="-2">
                      Incoterms
                    </Text>
                    <AsyncSelect
                      defaultOptions
                      loadOptions={loadOptionsInco}
                      value={incoTerm}
                      onChange={(selectedOptions) =>
                        HandleSelectInco(selectedOptions)
                      }
                      filterOption={filterOptions}
                      styles={customStylesInco}
                    />
                    {renderMe && (
                      <Box
                        borderWidth="1px"
                        rounded="lg"
                        shadow="1px 1px 3px rgba(0,0,0,0.3)"
                        p={6}
                        m="5px auto"
                      >
                        <Text mt="-4" fontSize="md" fontWeight="semibold">
                          Description
                        </Text>
                        <Text mt="0" mb="-4" ml="-4" mr="-4" fontSize="sm">
                          {incoTerm.description}
                        </Text>
                      </Box>
                    )}

                    <TextareaControl
                      name="notes"
                      label="Ytterligere merknader"
                      helperText="Valgfritt - hvis du trenger å notere noe om dette godset."
                    />

                    <SimpleGrid rowGap={8}>
                      <ButtonGroup mt={4}>
                        <SubmitButton variant="primary">Lagre</SubmitButton>
                        {/* <ResetButton>Tilbakestill</ResetButton> */}
                      </ButtonGroup>
                      <Button
                        mt="6"
                        leftIcon={<MdKeyboardReturn />}
                        variant="return"
                        as="a"
                        href="/cargos"
                      >
                        {" "}
                        Tilbake
                      </Button>
                    </SimpleGrid>
                  </Box>
                  <AspectRatio ratio={16 / 9}>
                    <Map
                      onRouteChange={(route) => newRoute(route, setFieldValue)}
                      key={mapKey}
                    />
                  </AspectRatio>
                </>
              )}
            </Formik>
          </SimpleGrid>
        </>
      }
    />
  );
}
