import {
  Input,
  Button,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  SimpleGrid,
  ButtonGroup,
  FormControl,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OneColumnLayout from "../layout/OneColLayout";
import {
  getVehicleInformation,
  saveTrailerInformation,
  updateVehicleInformation,
} from "../databasefunctions/vehicles";
import { findInfo } from "../databasefunctions/storeandfind";
import { getLoadTypes } from "../databasefunctions/cargos";
import AsyncSelect from "react-select/async";
import { MdKeyboardReturn } from "react-icons/md";
import { ReactSelectMultiStyles } from "../Styles/components/ReactSelectStyles";
import { Formik } from "formik";
import { ResetButton, SubmitButton } from "formik-chakra-ui";
import { filterOptions } from "../GeneralFunctions/ReactSelectFuncs";

//import { Checkbox } from "semantic-ui-react";
//import MenuItem from '@mui/material/MenuItem';
//import Select, { SelectChangeEvent } from '@mui/material/Select';
// import { Tooltip } from "@mui/material";
interface TruckState {
  registrationNumber: string;
  truckModel: string;
  truckMake: string;
  truckVehicleGroup: string;
  truckHeight: number;
  truckWidth: number;
  truckLength: number;
  truckPayload: string;
  trailerRegistrationNumber: string;
  trailerLoadTypeList: [{ value: string; label: string }];
}
/**********************************************************
/ NAME: UpdateTruck()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a form for updating truck 
 * information. It allows the user to input new truck details 
 * and updates the information when the form is submitted.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * getVehicleInformation() - Retrieves the vehicle information
 * saveTrailerInformation() - Saves the trailer information
 * updateVehicleInformation() - Updates the vehicle information
 * findInfo() - Finds the company information
 * getLoadTypes() - Retrieves the load types
 * 
/********************************************************** 
 * COMPONENTS:
 * - Box: A component that renders a box
 * - Button: A component that renders a button
 * - Text: A component that renders text
 * - SimpleGrid: A component that renders a simple grid
 * - FormControl: A component that renders a form control
 * - ButtonGroup: A component that groups related buttons
 * - Alert: A component that renders an alert
 * - AlertIcon: A component that renders an icon in an alert
 * - AlertDescription: A component that renders a description in an alert
 * - OneColumnLayout: A component that renders a one column layout
 * - Formik: A form library for React
 * - ResetButton: A component that renders a reset button
 * - SubmitButton: A component that renders a submit button
 * - useState: A React hook that lets you add state to function components
 * - useEffect: A React hook that lets you perform side effects in function components
 * - useLocation: A hook that returns the current location object which contains information about the current URL
 * - useNavigate: A hook that returns a function that navigates to different pages
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a form for updating truck information.
/*********************************************************/
function UpdateTruck() {
  const { state } = useLocation();
  const {
    registrationNumber,
    truckModel,
    truckMake,
    truckVehicleGroup,
    truckHeight,
    truckWidth,
    truckLength,
    truckPayload,
    trailerRegistrationNumber,
    trailerLoadTypeList,
  } = state as TruckState;
  const navigate = useNavigate();
  const [registrationNumberTruck, setRegistrationNumberTruck] =
    useState(registrationNumber);
  const [vehicleModel, setVehicleModel] = useState(truckModel);
  const [vehicleMake, setVehicleMake] = useState(truckMake);
  const [vehicleGroup, setVehicleGroup] = useState(truckVehicleGroup);
  const [vehicleHeight, setVehicleHeight] = useState(truckHeight);
  const [vehicleWidth, setVehicleWidth] = useState(truckWidth);
  const [vehicleLength, setVehicleLength] = useState(truckLength);
  const [vehiclePayload, setVehiclePayload] = useState(truckPayload);

  const [registrationNumberTrailer, setRegistrationNumberTrailer] = useState(
    trailerRegistrationNumber
  );
  const [trailerModel, setTrailerModel] = useState("");
  const [trailerVehicleGroup, setTrailerVehicleGroup] = useState("");
  const [trailerMake, setTrailerMake] = useState("");
  const [trailerHeight, setTrailerHeight] = useState(0);
  const [trailerWidth, setTrailerWidth] = useState(0);
  const [trailerLength, setTrailerLength] = useState(0);
  const [trailerPayload, setTrailerPayload] = useState("");
  const [trailerLoadType, setTrailerLoadType] = useState(trailerLoadTypeList);
  let isError = true;
  const [hasError, setHasError] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState("");
  // const [enginePower, setEnginePower] = useState("");
  // const [curbWeight, setCurbWeight] = useState("");
  // const [maxAllowedWeight, setMaxAllowedWeight] = useState("");
  // const [curbWeightTrailer, setCurbWeightTrailer] = useState("");
  // const [maxAllowedWeightTrailer, setMaxAllowedWeightTrailer] = useState("");
  const initalValues = {
    vehicleModel,
    vehicleMake,
    vehicleGroup,
    vehicleHeight,
    vehicleWidth,
    vehicleLength,
    vehiclePayload,
    trailerModel,
    trailerMake,
    trailerVehicleGroup,
    trailerHeight,
    trailerWidth,
    trailerLength,
    trailerPayload,
    trailerLoadType,
  };

  const getInformationTruck = async () => {
    // Checks if registration number is empty
    if (registrationNumber !== "") {
      // If not empty, gets vehicle information from API
      await getVehicleInformation(registrationNumber)
        .then(() => {
          isError = false;
          // Sets truck make to vehicle brand
          setVehicleMake(findInfo("vehicleBrand"));
          // Sets truck model to vehicle model
          setVehicleModel(findInfo("vehicleModel"));
          // Sets Vehicle height to vehicle height
          let tH = Number(findInfo("vehicleHeight")) / 1000;
          setVehicleHeight(tH);
          // Sets Vehicle width to vehicle width
          let tW = Number(findInfo("vehicleWidth")) / 1000;
          setVehicleWidth(tW);
          // Sets Vehicle length to vehicle length
          let tL = Number(findInfo("vehicleLength")) / 1000;
          setVehicleLength(tL);
          // Sets Vehicle payload to vehicle payload
          setVehiclePayload(findInfo("vehiclePayload"));
          // Sets Vehicle vehicle group to vehicle group
          setVehicleGroup(findInfo("vehicleGroup"));
        })
        // If there is an error, sets error message and sets isError to true
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail);
          //console.log(hasError);
        });
    } else {
      // If registration number is empty, sets error message and sets isError to true
      isError = true;
      setErrorMessage("Registreringsnummer på bil mangler");
    }
    // Sets hasError to isError
    setHasError(isError);
  };

  const getInformationTrailer = async () => {
    if (registrationNumberTrailer !== "") {
      await getVehicleInformation(registrationNumberTrailer)
        .then(() => {
          isError = false;
          setTrailerMake(findInfo("vehicleBrand"));
          setTrailerModel(findInfo("vehicleModel"));
          let tH = Number(findInfo("vehicleHeight")) / 1000;
          setTrailerHeight(tH);
          let tW = Number(findInfo("vehicleWidth")) / 1000;
          setTrailerWidth(tW);
          let tL = Number(findInfo("vehicleLength")) / 1000;
          setTrailerLength(tL);
          setTrailerPayload(findInfo("vehiclePayload"));
          setTrailerVehicleGroup(findInfo("vehicleGroup"));
          if (trailerVehicleGroup === "Motorsykkel") {
            isError = true;
            setErrorMessage(
              "Registreringsnummeret til hengeren tilhører en motorsykkel"
            );
          } else if (trailerVehicleGroup === "Moped") {
            isError = true;
            setErrorMessage(
              "Registreringsnummeret til hengeren tilhører en moped"
            );
          } else if (trailerVehicleGroup === "Traktor") {
            isError = true;
            setErrorMessage(
              "Registreringsnummeret til hengeren tilhører en traktor"
            );
          } else if (trailerVehicleGroup === "Lett motorsykkel") {
            isError = true;
            setErrorMessage(
              "Registreringsnummeret til hengeren tilhører en lett motorsykkel"
            );
          }
          setHasError(isError);
        })
        .catch((error) => {
          isError = true;
          setHasError((isError) => !isError);
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError)
        });
    } else {
      isError = true;
      setErrorMessage("Registreringsnummer på henger mangler");
    }
    setHasError(isError);
  };

  useEffect(() => {
    //console.log(hasError)
    //console.log(errorMessage)
  }, [errorMessage, hasError]);

  // This function is used to save the vehicle information into the database
  // It is called when the user clicks the submit button on the form
  // It takes in the vehicle registration number, vehicle group, vehicle model, vehicle make, vehicle height, vehicle width, vehicle length, trailer registration number, and load types
  // It then makes a request to the server to save the data
  // The request is made using the saveVehicleInformation function in the saveVehicleInformation.ts file
  // The function then navigates to the trucks overview page
  const onSubmit = async (values: any) => {
    let loadTypes: any[] = [];
    if (trailerLoadType) {
      trailerLoadType.forEach((element: any) => {
        loadTypes.push(`${element.value}`);
      });
    }

    isError = false;
    if (registrationNumber !== "") {
      await updateVehicleInformation(
        registrationNumberTruck,
        vehicleGroup,
        vehicleModel,
        vehicleMake,
        vehicleHeight,
        vehicleWidth,
        vehicleLength,
        registrationNumberTrailer,
        loadTypes
      )
        .then()
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          setHasError(true);
        });
    } else {
      isError = true;
      setErrorMessage("Missing vehicle registration number");
    }
    if (registrationNumberTrailer !== "") {
      await saveTrailerInformation(
        registrationNumberTrailer,
        trailerVehicleGroup,
        trailerModel,
        trailerMake,
        trailerHeight,
        trailerWidth,
        trailerLength,
        loadTypes,
        registrationNumberTruck
      )
        .then()
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          setHasError(true);
        });
    }
    if (isError === false) {
      navigate("/trucks-overview");
    }
  };

  const loadOptions: any = (callback: Function) => {
    const LoadTypes = getLoadTypes();
    return LoadTypes;
  };

  function handleSelect(data: any) {
    setTrailerLoadType(data);
  }
  function handleReset() {}

  const customStyles = ReactSelectMultiStyles();
  // const handleChange(option)=>{
  //   setTrailerLoadType(option)
  // }
  return (
    <OneColumnLayout
      sectionLabel="Truck Registration"
      sectionHeading="KJØRETØY OG HENGER"
      sectionIntro="Please give accurate information about your vehicle here, as it will be used to find possible freight."
      content={
        <Formik
          initialValues={initalValues}
          onSubmit={onSubmit}
          //validationSchema={validationSchema}
          onReset={handleReset}
        >
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <>
              {/* {!hasResult && ( */}

              <Box
                borderWidth="1px"
                borderColor="blackAlpha.600"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                maxWidth="88vw"
                minWidth="30vw"
                width={800}
                p={6}
                m="10px auto"
                as="form"
                onSubmit={handleSubmit as any}
              >
                <Text
                  fontSize="xl"
                  fontWeight={"bold"}
                  textTransform="uppercase"
                >
                  {" "}
                  Kjøretøy
                </Text>
                {hasError && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                )}
                <Text variant="label">Reg. nummer</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={registrationNumber}
                    placeholder="BD10000"
                    onChange={(e) => setRegistrationNumberTruck(e.target.value)}
                  />
                </FormControl>

                <Button
                  variant="verify"
                  onClick={getInformationTruck}
                  sx={{ m: 1 }}
                >
                  Bekreft
                </Button>

                <Text variant="label">Merke</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={vehicleMake}
                    placeholder="Merke"
                    onChange={(e) => setVehicleMake(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Modell</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={vehicleModel}
                    placeholder="Modell"
                    onChange={(e) => setVehicleModel(e.target.value)}
                  />
                </FormControl>
                <SimpleGrid columns={3} columnGap={2}>
                  
                  <Text variant="label">Lengde[m]</Text>
                  <Text variant="label">Bredde[m]</Text>
                  <Text variant="label">Høyde[m]</Text>
                  
                  <FormControl isRequired>
                    <Input
                      type="number"
                      variant="standardTextInput"
                      value={vehicleLength}
                      placeholder="___ m"
                      onChange={(e) => setVehicleLength(Number(e.target.value))}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <Input
                      type="number"
                      variant="standardTextInput"
                      value={vehicleWidth}
                      placeholder="___ m"
                      onChange={(e) => setVehicleWidth(Number(e.target.value))}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <Input
                      type="number"
                      variant="standardTextInput"
                      value={vehicleHeight}
                      placeholder="___ m"
                      onChange={(e) => setVehicleHeight(Number(e.target.value))}
                    />
                  </FormControl>
                </SimpleGrid>
                <Text variant="label">Nyttelast [kg]</Text>
                <FormControl isRequired>
                  <Input
                    variant="standardTextInput"
                    value={vehiclePayload}
                    placeholder="___ kg"
                    onChange={(e) => setVehiclePayload(e.target.value)}
                  />
                </FormControl>
                <Text
                  fontSize="xl"
                  fontWeight={"bold"}
                  textTransform="uppercase"
                >
                  {" "}
                  Tilhenger
                </Text>
                <Text variant="label">Reg. nummer</Text>
                <FormControl>
                  <Input
                    variant="standardTextInput"
                    value={registrationNumberTrailer}
                    placeholder="BD1000"
                    onChange={(e) =>
                      setRegistrationNumberTrailer(e.target.value)
                    }
                  />
                </FormControl>

                <Button
                  variant="verify"
                  onClick={getInformationTrailer}
                  sx={{ m: 1 }}
                >
                  Bekrefte
                </Button>

                <Text variant="label">Merke</Text>
                <FormControl>
                  <Input
                    variant="standardTextInput"
                    value={trailerMake}
                    placeholder="Merke"
                    onChange={(e) => setTrailerMake(e.target.value)}
                  />
                </FormControl>
                <Text variant="label">Modell</Text>
                <FormControl>
                  <Input
                    variant="standardTextInput"
                    value={trailerModel}
                    placeholder="Modell"
                    onChange={(e) => setTrailerModel(e.target.value)}
                  />
                </FormControl>
                <SimpleGrid columns={3} columnGap={2}>
                  
                  <Text variant="label">Lengde[m]</Text>
                  <Text variant="label">Bredde[m]</Text>
                  <Text variant="label">Høyde[m]</Text>

                  
                  <FormControl>
                    <Input
                      type="number"
                      variant="standardTextInput"
                      value={trailerLength}
                      placeholder="___ m"
                      onChange={(e) => setTrailerLength(Number(e.target.value))}
                    />
                  </FormControl>
                  <FormControl>
                    <Input
                      type="number"
                      variant="standardTextInput"
                      value={trailerWidth}
                      placeholder="___ m"
                      onChange={(e) => setTrailerWidth(Number(e.target.value))}
                    />
                  </FormControl>
                  <FormControl>
                    <Input
                      type="number"
                      variant="standardTextInput"
                      value={trailerHeight}
                      placeholder="___ m"
                      onChange={(e) => setTrailerHeight(Number(e.target.value))}
                    />
                  </FormControl>
                </SimpleGrid>
                <Text variant="label">Nyttelast [kg]</Text>
                <FormControl>
                  <Input
                    variant="standardTextInput"
                    value={trailerPayload}
                    placeholder="___ kg"
                    onChange={(e) => setTrailerPayload(e.target.value)}
                  />
                </FormControl>
                <Text mb="-2" variant="label">
                  Kompatible lasttyper
                </Text>
                <AsyncSelect
                  defaultOptions
                  loadOptions={loadOptions}
                  value={trailerLoadType}
                  filterOption={filterOptions}
                  onChange={(selectedOptions) => handleSelect(selectedOptions)}
                  isMulti
                  styles={customStyles}
                />
                {/* <Select name="loads">
              <option value="Pallets">Pallets</option>
              <option value="cool">Cooling</option>
              <option value="Timber">Timber</option>
              <option value="Bulk">Bulk</option>
              <option value="Frozen">Frozen</option>
            </Select> */}

                <SimpleGrid rowGap={8}>
                  <ButtonGroup mt={4}>
                    <SubmitButton variant="primary">Lagre</SubmitButton>
                    <ResetButton>Tilbakestill</ResetButton>
                  </ButtonGroup>
                  <Button
                    mt="6"
                    leftIcon={<MdKeyboardReturn />}
                    variant="return"
                    as="a"
                    href="/trucks-overview"
                  >
                    {" "}
                    Tilbake
                  </Button>
                </SimpleGrid>
              </Box>
            </>
          )}
        </Formik>
      }
    />
  );
}

export default UpdateTruck;
