import { Grid, GridItem, Heading } from "@chakra-ui/react";
import  { ReactNode} from "react";
import { Footer } from "./FooterNewUser";
import Header from "./HeaderNewUser";

import MenuDrawer from "./Menu";

interface OneColProps {
  content: ReactNode;
  sectionLabel: string;
  sectionHeading: string;
  sectionIntro: string;
}
function OneColumnLayout(props: OneColProps) {


//   // menu = <MenuDrawer isOpen />;

//   //console.log(isOpen);
  return (
    <Grid
      minH="100vh"
      templateRows="80px min-content 8fr 50px"
      templateColumns={"1fr"}
    >
      <GridItem colSpan={2}>
        <Header disclosureProps />
      </GridItem>
      <MenuDrawer isOpen />
      {/* <GridItem
        colSpan={2}
        marginLeft={4}
        marginTop="4"
        alignSelf={"flex-start"}
      >
        <SectionLabel size="lg">{props.sectionLabel}</SectionLabel>
      </GridItem> */}
      <GridItem colSpan={2} marginLeft={4} marginTop={4}>
        <Heading alignSelf={"flex-start"} fontSize={"2em"}>
          {props.sectionHeading}
        </Heading>
      </GridItem>
      {/* <GridItem colSpan={2} marginLeft={4} marginTop={8}>
        <Text fontSize={"1.2em"} alignSelf={"flex-start"}>
          {props.sectionIntro}
        </Text>
      </GridItem> */}
      <GridItem p={6}>{props.content}</GridItem>
      <GridItem colSpan={2}>
        <Footer />
      </GridItem>
    </Grid>
  );
}

export default OneColumnLayout;
