export type PackageAddress = {
    name?: string;
    street_address?: string;
    street_address2?: string;
    zip_code?: string;
    city?: string;
    country?: string;
    country_code?: string;
    state?: string;
    phone_number?: string;
    sort_order?: number;
};

export type PackageInfoForIntegration = {
        _id: string;
        company: string;
        customer: string;
        tracking_number: string;
        pickup_address: PackageAddress,
        delivery_address: PackageAddress,
        load_types: string;
        load_description: string;
        load_value: number;
        service: string;
        vehicleCode: string;
        priceCode: string;
        price: number;
        integration_id: string;
        integration_state: string;
        integration_state_message: string;
        integration_state_date: string;
        state: string;
};

export enum Role {
    package_owner = "package_owner",
    carrier = "carrier"
};