
import { extendTheme } from '@chakra-ui/react'
import { buttonStyles as Button}  from './components/buttonStyles'
import { inputTheme } from './components/inputStyles'
import { textStyles as Text } from './components/TextStyles'
import { dividerTheme } from './components/dividerStyles'
import {containerStyles as Container} from './components/containerStyles'
import { numberInputTheme } from './components/NumberInputStyles'
export const myNewTheme =extendTheme({
    colors: {
        primary:"#1c7817",
        secondary: "#003B81",
        highlight: "#008074",
        subtle: "#FAFAFA",
        giveOffer: "#17964D",
        logo:"#1c7817",
        giveOffer2: "#F4BF5D",
        header:"white",
        iconColour: "#504538",
        newPrimary:"#008858",
        borderPrimary:"#0b3009",
        subtleDark:"#CCCCCC"


    },
    components: {
        Button,
        Input:inputTheme,
        InputControl:inputTheme,
        Text,
        Divider:dividerTheme,
        Container,
        NumberInput:numberInputTheme,
    }
    
})