import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Input,
  VStack,
  Button,
  Link,
  Text,
  Box,
  ButtonGroup,
  Image,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import LoginLayout from "../layout/LoginLayout";
import {
  logIn,
  SendPasswordResetCode,
  UpdatePassword,
} from "../databasefunctions/users";
import { Formik, Form } from "formik";
import { SubmitButton } from "formik-chakra-ui";
import logo from "../../assets/logo.png";
import PasswordInput from "./PasswordInput";
const initialValues = {
  verificationCode: "",
  email: "",
  password: "",
  phoneNumber: "",
  carrier: false,
  cargoOwner: false,
};
/**********************************************************
/ NAME: Reset()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a form for resetting a password. 
 * It sends a password reset code to the user's email and updates 
 * the password when the form is submitted.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * sendEmail() - Sends a password reset code to the user's email
 * handlePasswordChange(newPassword: string, isPasswordValid: boolean) - Updates the password and checks if it is valid
 * onSubmit(values: any) - Updates the password if the password is valid and the verification code is correct
 * 
/********************************************************** 
 * COMPONENTS:
 * - Input: A component that renders an input field
 * - VStack: A container that stacks its children vertically
 * - Button: A button that submits the form when clicked
 * - Link: A component that renders a link to a specified URL
 * - Text: A component that renders text
 * - Box: A component that renders a box
 * - ButtonGroup: A component that groups related buttons
 * - Image: A component that renders an image
 * - Alert: A component that renders an alert
 * - AlertIcon: A component that renders an icon in an alert
 * - AlertDescription: A component that renders a description in an alert
 * - LoginLayout: A component that renders a layout for the login page
 * - Formik: A form library for React
 * - Form: A component that renders a form
 * - SubmitButton: A button that submits a form when clicked
 * - PasswordInput: A component that renders a password input field with a show/hide button
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a form for resetting a password.
/*********************************************************/
function Reset() {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  const [isPasswordValid, setPasswordValid] = useState(true);
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  let isError = false;
  const sendEmail = async () => {
    await SendPasswordResetCode(email).then(() => {
      setEmailSent(true);
    });
  };

  const handlePasswordChange = (
    newPassword: string,
    isPasswordValid: boolean
  ) => {
    setPassword(newPassword);
    setPasswordValid(isPasswordValid);
  };
  const onSubmit = async (values: any) => {
    if (isPasswordValid) {
      await UpdatePassword(email, values.verificationCode, password)
        .then()
        .catch((error: { data: { detail: React.SetStateAction<string> } }) => {
          isError = true;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError)
        });
      if (isError === true) {
        setHasError(true);
      } else {
        await logIn("", email, password, "", "", "");
        navigate("/dashboard", {
          state: {
            userCarrier: values.carrier,
            userCargoOwner: values.cargoOwner,
          },
        });
      }
    }
  };

  return (
    <LoginLayout>
      <VStack>
        {hasError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Image src={logo} boxSize="15vw" marginTop="2vh" />
        <Box
          borderWidth="2px"
          rounded="lg"
          borderColor="blackAlpha.400"
          shadow="2px 2px 4px rgba(0,0,0,0.3)"
          maxWidth="90vw"
          minWidth="30vw"
          p={6}
          m="10px auto"
          as="form"
        >
          <Text variant="label">Epost</Text>
          <Input
            variant="standardTextInput"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-post"
          />
          {!emailSent && (
            <Button variant="primary" onClick={() => sendEmail()}>
              Send kode på epost
            </Button>
          )}
        </Box>
        {emailSent && (
          <>
            <Button variant="update" onClick={() => sendEmail()}>
              Send kode på nytt
            </Button>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ handleSubmit, values, errors }) => (
                <Box
                  borderWidth="2px"
                  rounded="lg"
                  borderColor="blackAlpha.400"
                  shadow="2px 2px 4px rgba(0,0,0,0.3)"
                  maxWidth="90vw"
                  minWidth="30vw"
                  p={6}
                  m="10px auto"
                  as="form"
                  onSubmit={handleSubmit as any}
                >
                  <Form>
                    <Text variant="label">Verifikasjonskode</Text>
                    <Input
                      variant="standardTextInput"
                      type="text"
                      name="verificationCode"
                      id="verificationCode"
                      placeholder="123456"
                      onChange={(e) =>
                        (values.verificationCode = e.target.value)
                      }
                    />

                    <Text variant="label">Nytt passord</Text>
                    <PasswordInput
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Passord"
                    />
                  </Form>
                  <ButtonGroup mt={4}>
                    <SubmitButton variant="primary">
                      Oppdater passord
                    </SubmitButton>
                  </ButtonGroup>
                </Box>
              )}
            </Formik>
          </>
        )}

        <Text>
          Har du ikke konto?{" "}
          <Link href="/register" color="teal.500">
            Opprett bruker
          </Link>{" "}
          her.
        </Text>
      </VStack>
    </LoginLayout>
  );
}
export default Reset;
