import {   Heading,Text } from "@chakra-ui/react";

import OneColumnLayout from "../layout/OneColLayout";


function AboutUs() {
 
  return (
    <OneColumnLayout
      sectionLabel="About us"
      sectionHeading="Always Cargo is a state of the art matching algorithm "
      sectionIntro=""
      content={
        
        <form>
          <Heading> We will share more when we're ready</Heading>
          <Text>Stay tuned!</Text>
   
        </form>
      }
    />
  );
}

export default AboutUs;
