import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const thick = defineStyle({
    borderWidth: '2px', // change the width of the border
    borderColor: "black",
    borderStyle: "solid", // change the style of the border
    borderRadius: 10, // set border radius to 10
    _dark:{
        borderColor:"subtleDark"
    }
})

export const dividerTheme = defineStyleConfig({
    variants: { thick },
})