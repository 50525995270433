import {   Heading,Text } from "@chakra-ui/react";

import OneColumnLayout from "../layout/OneColLayout";


function Contact() {
 
  return (
    <OneColumnLayout
      sectionLabel="Contact"
      sectionHeading="If you want to get in touch"
      sectionIntro=""
      content={
        
        <form>
          <Heading> Contact us at</Heading>
          <Text>br@alwayscargo.no</Text>
   
        </form>
      }
    />
  );
}

export default Contact;
