/**********************************************************
/ NAME: UpComingTripsOverview()
/********************************************************** 
 * SUMMARY: 
 * This component fetches and displays the upcoming trips 
 * from the database in a grid format. It also provides a 
 * button to register a new trip.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * getUpComingTrips() - Fetches the upcoming trips from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * TripRow - Represents a row in the grid, displaying information about a trip
 * 
/********************************************************** 
 * RETURN:
 * Returns a jsx element with the grid of upcoming trips 
 * and a button to register a new trip.
/*********************************************************/
import { Button, Grid, Heading, Divider, Center, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getUpComingTrips } from "../databasefunctions/trips";

import ProjectRow from "./UpComingTripRow";

import { GiTruck } from "react-icons/gi";

function UpComingTripsOverview() {
  const [trips, setTrips] = useState<any>(null);

  useEffect(() => {
    if (!trips) {
      getUpComingTrips()?.then((p) => setTrips(p));
    }
  }, [trips]);
  return (
    <>
      <Grid templateColumns="10% 80% 10%" gap={2}>
        <Icon as={GiTruck} w={10} h={10} />
        <Center>
          <Heading fontSize={"1.4rem"}> Mine Matcher</Heading>
        </Center>
      </Grid>
      <Grid mb={6} templateColumns="32% 32% 36%" gap={2} ml="-1">
        <Center>
          <Heading fontSize={"1.2rem"}> Dato</Heading>
        </Center>
        <Center>
          <Heading fontSize={"1.2rem"}> Fra</Heading>
        </Center>
        <Center>
          <Heading fontSize={"1.2rem"}> Status</Heading>
        </Center>
      </Grid>
      <Divider variant="thick" mt="-4" mb="2" width={"95%"}></Divider>
      {trips?.map((trip: any) => (
        <ProjectRow key={trip.id} trip={trip} />
      ))}
      <Center>
        <Button variant="primary" as="a" href="/trips">
          Se alle
        </Button>
      </Center>
    </>
  );
}
export default UpComingTripsOverview;
