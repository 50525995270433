import {
  Box,
  ButtonGroup,
  VStack,
  Image,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
  Text,
} from "@chakra-ui/react";
import { Formik } from "formik";

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyUserEmail } from "../databasefunctions/users";

import logo from "../../assets/logo.png";
import "react-phone-input-2/lib/style.css";
import { Terms } from "../layout/TermsDialog";
const initialValues = {
  kode: "",
};

interface UserState {
  userCarrier: boolean;
  userCargoOwner: boolean;
}
/**********************************************************
/ NAME: VerifyEmail()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a form for verifying a user's 
 * email. It sends a verification code to the user's email and 
 * verifies the code when the form is submitted.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * verifyUserEmail(kode: string) - Verifies the user's email
 * onSubmit(values: any) - Verifies the user's email if the verification code is correct
 * 
/********************************************************** 
 * COMPONENTS:
 * - Box: A component that renders a box
 * - ButtonGroup: A component that groups related buttons
 * - VStack: A container that stacks its children vertically
 * - Image: A component that renders an image
 * - Input: A component that renders an input field
 * - Alert: A component that renders an alert
 * - AlertIcon: A component that renders an icon in an alert
 * - AlertDescription: A component that renders a description in an alert
 * - Text: A component that renders text
 * - Formik: A form library for React
 * - Terms: A component that renders a dialog for terms and conditions
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a form for verifying a user's email.
/*********************************************************/
function VerifyEmail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { userCarrier, userCargoOwner } = state as UserState;
  const [uCarrier] = useState(userCarrier);
  const [uCargoOwner] = useState(userCargoOwner);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  var isError = false;
  const onSubmit = async (values: any) => {
    //console.log("Hei")
    await verifyUserEmail(values.kode)
      .then(() => {
        isError = false;
      })
      .catch((error) => {
        isError = true;
        setErrorMessage(error.data.detail);
      });
    if (isError === true) {
      setHasError(true);
    } else {
      navigate("/register-company", {
        state: {
          userCarrier: uCarrier,
          userCargoOwner: uCargoOwner,
        },
      });
    }
  };

  return (
    <>
      <VStack>
        <Image src={logo} boxSize="15vw" marginTop="2vh" />
        {hasError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // validationSchema={validationSchema}
        >
          {({ handleSubmit, values, errors }) => (
            <Box
              borderWidth="2px"
              rounded="lg"
              borderColor="blackAlpha.400"
              shadow="2px 2px 4px rgba(0,0,0,0.3)"
              maxWidth="90vw"
              minWidth="30vw"
              p={6}
              m="10px auto"
              as="form"
              onSubmit={handleSubmit as any}
            >
              <Text variant="label">Verifiseringskode</Text>
              <Input
                variant="standardTextInput"
                type="text"
                name="kode"
                id="kode"
                placeholder="123456"
                onChange={(e) => (values.kode = e.target.value)}
              />

              <ButtonGroup mt={4}>
                <Terms onConfirm={() => onSubmit(values)} subject={values} />
              </ButtonGroup>
            </Box>
          )}
        </Formik>
      </VStack>
    </>
  );
}

export default VerifyEmail;
