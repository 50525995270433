import {
  Button,
  Heading,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Grid,
} from "@chakra-ui/react";
import { useState } from "react";
import { dbGiveOffer, dbDeclineMatch, dbAcceptMatch } from "../databasefunctions/matches";
import OneColumnLayout from "../layout/OneColLayout";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CreatePaymentLink } from "../databasefunctions/Payments";
import { Accept } from "../layout/AcceptDialogCarrier";
import { Bid } from "../layout/BidDialogCarrier";
interface CargoMatchState {
  distance_diff: number;
  distance_package: number;
  duration_diff: number;
  duration_package: number;
  package_height: number;
  package_length: number;
  package_width: number;
  package_weight: number;
  pick_up_point: string;
  drop_off_point: string;
  earliest_start_time: string;
  latest_start_time: string;
  earliest_end_time: string;
  latest_end_time: string;
  load_types: string[];
  incoterms: { code: string; discription: string };
  tracking_number: string;
  car_reg_number: string;
  offer: string;
  loading_requirements: string[];
  noteCargo: string;
  note: string;
}
/**********************************************************
/ NAME: EvaluateOfferView()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a view for evaluating an offer. 
 * It displays information about the cargo and the offer.
 * 
/********************************************************** 
 * INPUT: 
 * CargoMatchState: An interface that defines all relevant information 
 * for the cargo and match
 * 

/********************************************************** 
 * FUNCTIONS:
 * sendOffer() - Sends an offer to the carrier
 * declineMatch() - Declines the match
 * acceptMatch() - Accepts the match
 * 
/********************************************************** 
 * COMPONENTS:
 * - useState: A React hook that lets you add state to function components
 * - Accept: A component that renders an accept dialog
 * - Bid: A component that renders a bid dialog
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing information about the cargo and the offer.
/*********************************************************/
function EvaluateOfferView() {
  const { state } = useLocation();
  const {
    distance_diff,
    distance_package,
    duration_package,
    duration_diff,
    package_height,
    package_length,
    package_width,
    package_weight,
    pick_up_point,
    drop_off_point,
    earliest_start_time,
    latest_start_time,
    earliest_end_time,
    latest_end_time,
    load_types,
    incoterms,
    tracking_number,
    car_reg_number,
    offer,
    loading_requirements,
    note,
    noteCargo,
  } = state as CargoMatchState;
  const navigate = useNavigate();
  const [distanceDiff] = useState(distance_diff);
  const [distancePackage] = useState(distance_package);
  const [durationPackage] = useState(duration_package);
  const [durationDiff] = useState(duration_diff);
  const [packageHeight] = useState(package_height);
  const [packageLength] = useState(package_length);
  const [packageWidth] = useState(package_width);
  const [packageWeight] = useState(package_weight);
  const [pickUpPoint] = useState(pick_up_point);
  const [earliestStartTime] = useState(earliest_start_time);
  const [latestStartTime] = useState(latest_start_time);
  const [earliestEndTime] = useState(earliest_end_time);
  const [latestEndTime] = useState(latest_end_time);
  const [dropOffPoint] = useState(drop_off_point);
  const [loadTypes] = useState(load_types);
  const [incoTerms] = useState(incoterms);
  const [trackingNumber] = useState(tracking_number);
  const [regNumber] = useState(car_reg_number);
  const [loadingRequirements] = useState(loading_requirements);
  const [incomingOffer] = useState(offer);
  const [offerValue, setOfferValue] = useState("");

  const sendOffer = async () => {
    await dbGiveOffer(trackingNumber, regNumber, offerValue).then(() => {
      navigate("/trips");
    });
  };
  const declineMatch = async () => {
    await dbDeclineMatch(trackingNumber, regNumber).then(() => {
      navigate("/trips");
    });
  };
  const acceptMatch = async () => {
    //await dbAcceptMatch(trackingNumber,regNumber);
    await CreatePaymentLink(trackingNumber, regNumber, Number(offer) / 10).then(
      async (response) => {
        //window.open(response, "_blank");
        await dbAcceptMatch(trackingNumber, regNumber).then(() => {
          navigate("/deal-signed");
        });
      }
    );
    /*navigate("/payment-status", {
      state: {
        tracking_number: trackingNumber,
        car_reg_number: regNumber,
      },
    });*/
    
  };
  return (
    <OneColumnLayout
      sectionLabel="Offer Evaluation"
      sectionHeading="Du har mottatt et tilbud på jobben"
      sectionIntro=""
      content={
        <>
          <Heading>Tilbudet er: {incomingOffer} NOK</Heading>

          <Input
            value={offerValue}
            placeholder="Motbud"
            onChange={(e) => setOfferValue(e.target.value)}
            margin="10px"
            variant="standardTextInput"
            maxWidth="800px"
            width="90vw"
          />
          <Grid
            maxWidth="800px"
            templateColumns="33% 33% 33%"
            width="90vw"
            gap={2}
          >
            <Button margin="10px" colorScheme={"red"} onClick={declineMatch}>
              Avslå
            </Button>
            <Bid
              onConfirm={() => sendOffer()}
              price={offerValue}
              pickUpPoint={pickUpPoint}
              pickUpTime={earliestStartTime + " - " + latestStartTime}
              incoTerms={incoTerms.code}
              dropOffPoint={dropOffPoint}
              dropOffTime={earliestEndTime + " - " + latestEndTime}
              type={loadTypes}
              size={packageLength + "x" + packageWidth + "x" + packageHeight}
              weight={packageWeight}
              distance={Math.round(distancePackage / 1000)}
              time={Math.round(durationPackage / 60)}
              emissions="0"
              noteCargoOwner={noteCargo}
              noteTrip={note}
              loadingRequirement={loading_requirements}
              trackingNumber={trackingNumber}
              carRegNumber={car_reg_number}
            />

            <Accept
              onConfirm={() => acceptMatch()}
              price={incomingOffer}
              pickUpPoint={pickUpPoint}
              pickUpTime={earliestStartTime + " - " + latestStartTime}
              incoTerms={incoTerms.code}
              dropOffPoint={dropOffPoint}
              dropOffTime={earliestEndTime + " - " + latestEndTime}
              type={loadTypes}
              size={packageLength + "x" + packageWidth + "x" + packageHeight}
              weight={packageWeight}
              distance={Math.round(distancePackage / 1000)}
              time={Math.round(durationPackage / 60)}
              emissions="0"
              noteCargoOwner={noteCargo}
              noteTrip={note}
              loadingRequirement={loadingRequirements}
              trackingNumber={trackingNumber}
              carRegNumber={car_reg_number}
            />
          </Grid>

          <Text>Se mer informasjon om lasten nedenfor</Text>
          <TableContainer>
            <Table variant="simple" width={"80vw"}>
              <Thead>
                <Tr>
                  <Th>Parameter</Th>
                  <Th>Verdi</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Hentepunkt</Td>
                  <Td>{pickUpPoint}</Td>
                </Tr>
                <Tr>
                  <Td>Hentetid</Td>
                  <Td>{earliestStartTime + " - " + latestStartTime}</Td>
                </Tr>
                <Tr>
                  <Td>avleveringspunkt</Td>
                  <Td>{dropOffPoint}</Td>
                </Tr>
                <Tr>
                  <Td>Beregnet tilleggsavstand</Td>
                  <Td>{Math.round(distanceDiff / 1000)} km</Td>
                </Tr>
                <Tr>
                  <Td>Beregnet avstand med gods</Td>
                  <Td>{Math.round(distancePackage / 1000)} km</Td>
                </Tr>
                <Tr>
                  <Td>Estimert tilleggsvarighet</Td>
                  <Td>{durationDiff} min</Td>
                </Tr>
                <Tr>
                  <Td>Estimert varighet med gods</Td>
                  <Td>{durationPackage} min</Td>
                </Tr>
                <Tr>
                  <Td>Dimensjoner</Td>
                  <Td>
                    {packageLength}x{packageHeight}x{packageWidth} cm
                  </Td>
                </Tr>
                <Tr>
                  <Td>Vekt</Td>
                  <Td>{packageWeight} kg</Td>
                </Tr>
                <Tr>
                  <Td>Incoterms</Td>
                  <Td>{incoTerms.code}</Td>
                </Tr>
                <Tr>
                  <Td>Type</Td>
                  <Td>{loadTypes}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </>
      }
    />
  );
}

export default EvaluateOfferView;
