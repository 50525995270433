import axios from "axios";
import { saveTokenToLS, findToken } from "../login/Token";
import { saveInfoToSS } from "./storeandfind";
import { BACKEND_URL } from "../../assets/static";
/**********************************************************
/ NAME: createUser()
/********************************************************** 
 * SUMMARY: 
 * This function creates a new user with the provided details 
 * and sends it to the server.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - name: The name of the user
 * - email: The email of the user
 * - password: The password of the user
 * - phone_number: The phone number of the user
 * - carrier: A boolean indicating if the user is a carrier
 * - cargo_owner: A boolean indicating if the user is a cargo owner
 * 

/********************************************************** 
 * FUNCTIONS:
 * saveTokenToLS() - Saves the authentication token to local storage
 * findToken() - Retrieves the authentication token
 * saveInfoToSS() - Saves information to session storage
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the user 
 * details to the server.
/*********************************************************/
async function createUser(
  name: string,
  email: string,
  password: string,
  phone_number: string,
  carrier: boolean,
  cargo_owner: boolean
) {
  var data1 = JSON.stringify({
    email: `${email}`,
    password: `${password}`,
    phone_number: `${phone_number}`,
    name: `${name}`,
  });
  data1 = JSON.parse(data1);
  if (carrier === true) {
    var carstring = JSON.stringify({
      carrier: {
        vehicle_pk: [],
      },
    });
    // var devstring = JSON.stringify({
    //   deliverer: {
    //     vehicle_pk: [],

    //   },
    // });
    carstring = JSON.parse(carstring);
    //devstring = JSON.parse(devstring);
    data1 = Object.assign(data1, carstring);
    //data1 = Object.assign(data1, devstring);
  }
  if (cargo_owner === true) {
    var cargostring = JSON.stringify({ cargo_owner: {} });
    carstring = JSON.parse(cargostring);
    data1 = Object.assign(data1, carstring);
  }
  //console.log(data1);

  let urlstring;

  urlstring = `${BACKEND_URL}/user`;

  //console.log(createUser);
  await axios
    .post(urlstring, data1, {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: logIn()
/********************************************************** 
 * SUMMARY: 
 * This function logs in a user with the provided details 
 * and sends it to the server.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - grant_type: The grant type for the OAuth2 authentication
 * - username: The username of the user
 * - password: The password of the user
 * - scope: The scope of the OAuth2 authentication
 * - client_id: The client ID for the OAuth2 authentication
 * - client_secret: The client secret for the OAuth2 authentication
 * 

/********************************************************** 
 * FUNCTIONS:
 * saveTokenToLS() - Saves the authentication token to local storage
 * findToken() - Retrieves the authentication token
 * saveInfoToSS() - Saves information to session storage
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the 
 * login details to the server.
/*********************************************************/
async function logIn(
  grant_type: string,
  username: string,
  password: string,
  scope: string,
  client_id: string,
  client_secrect: string
) {
  //console.log(logIn);
  var FormData = require("form-data");

  var data = new FormData();

  data.append("username", username);
  data.append("password", password);

  let urlstring;

  urlstring = `${BACKEND_URL}/token`;

  //console.log(logIn);
  return await axios
    .post(urlstring, data)
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      let token: string = res.data["access_token"];
      //console.log(token);
      saveTokenToLS(token);
      return token;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

async function getDrivers() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/get_all_available_vehicles`;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        ////console.log(res);
        ////console.log(res.data);
        let trucks: any[] = [];
        trucks.push(...res.data);
        //console.log(trucks[1]["car_reg_number"]);
        return trucks;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: getUser()
/********************************************************** 
 * SUMMARY: 
 * This function fetches the details of the currently logged
 *  in user from the server.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * saveInfoToSS() - Saves information to session storage
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the details of the currently logged in user.
/*********************************************************/
async function getUser() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/user`;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        //console.log(res.status);
        saveInfoToSS("name", res.data.name);
        if (res.data.cargo_owner != null) {
          saveInfoToSS("cargo_owner", "true");
        } else {
          saveInfoToSS("cargo_owner", "false");
        }
        if (res.data.carrier != null) {
          saveInfoToSS("carrier", "true");
        } else {
          saveInfoToSS("carrier", "false");
        }

        let userInfo = res.data;
        console.log(userInfo);
        return userInfo;
      });
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function storeFile(file: File, file_type: string, is_required: boolean) {
  const token: string = findToken();
  const formData = new FormData();
  formData.append("file", file, file.name);

  try {
    await axios.post(
      `${BACKEND_URL}/registation_files?file_type=${file_type}.pdf&is_required=${is_required}`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    //console.log(response.data);
  } catch (error) {
    console.error(error);
  }
}

/**********************************************************
  / NAME: verifyUserEmail()
  /********************************************************** 
   * SUMMARY: 
   * This function verifies the email of the user using the 
   * provided email verification code.
   * 
  /********************************************************** 
   * INPUT: 
   * It takes the following parameter:
   * - EmailCode: The email verification code
   * 

  /********************************************************** 
   * FUNCTIONS:
   * findToken() - Retrieves the authentication token
   * 
  /********************************************************** 
   * COMPONENTS:
   * None
   * 
  /********************************************************** 
   * RETURN:
   * This function does not return anything. It sends the email 
   * verification code to the server.
  /*********************************************************/
async function verifyUserEmail(EmailCode: string) {
  const token: string = findToken();

  let urlstring;
  urlstring = `${BACKEND_URL}/verify_user?verification_code=${EmailCode}`;

  await axios
    .post(urlstring, "", { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);

      return;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}
/**********************************************************
/ NAME: SendPasswordResetCode()
/********************************************************** 
 * SUMMARY: 
 * This function sends a password reset code to the user's email.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - email: The email of the user
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends a request 
 * to the server to send a password reset code to the user's email.
/*********************************************************/
async function SendPasswordResetCode(email: string) {
  const token: string = findToken();

  let urlstring;
  urlstring = `${BACKEND_URL}/verify_code?email=${email}`;

  await axios
    .post(urlstring, "", { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);

      return;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}
/**********************************************************
/ NAME: UpdatePassword()
/********************************************************** 
 * SUMMARY: 
 * This function updates the password of the user using the 
 * provided email, verification code, and new password.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - email: The email of the user
 * - verificationCode: The verification code sent to the user's email
 * - password: The new password
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the new 
 * password along with the verification code to the server.
/*********************************************************/
async function UpdatePassword(
  email: string,
  verificationCode: string,
  password: string
) {
  const token: string = findToken();

  let urlstring;
  urlstring = `${BACKEND_URL}/password?email=${email}&verification_code=${verificationCode}&new_password=${password}    `;

  await axios
    .patch(urlstring, "", { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);

      return;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}
/**********************************************************
/ NAME: GetFile()
/********************************************************** 
 * SUMMARY: 
 * This function fetches a specific file from the server 
 * using the file type.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - fileType: The type of the file
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the requested file.
/*********************************************************/
async function GetFile(fileType: string) {
  const token: string = findToken();

  let urlstring;
  urlstring = `${BACKEND_URL}/file?file_type=${fileType} `;

  return await axios
    .get(urlstring, { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      let ReturnFile: File = res.data;
      return ReturnFile;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: storePackageFile()
/********************************************************** 
 * SUMMARY: 
 * This function uploads a file for a specific package to 
 * the server using the package's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - file: The file to be uploaded
 * - trackingNumber: The tracking number of the package
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the file to the server.
/*********************************************************/
async function storePackageFile(file: File, trackingNumber: string) {
  const token: string = findToken();
  const formData = new FormData();
  formData.append("file", file, file.name);

  try {
    await axios.post(
      `${BACKEND_URL}/package_files?tracking_number=${trackingNumber}`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    //console.log(response.data);
  } catch (error) {
    console.error(error);
  }
}
/**********************************************************
/ NAME: AddCollaborators()
/********************************************************** 
 * SUMMARY: 
 * This function adds collaborators to a user's group.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - collaborators: An array of collaborator registration numbers
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the 
 * collaborator registration numbers to the server.
/*********************************************************/
async function AddCollaborators(collaborators: string[]) {
  var data1 = JSON.stringify({
    group_list: `${collaborators}`,
  });
  var data2 = JSON.parse(data1);

  data2.group_list = collaborators;
  // var devstring = JSON.stringify({
  //   deliverer: {
  //     vehicle_pk: [],

  //   },
  // });

  let urlstring;

  urlstring = `${BACKEND_URL}/user`;
  const token: string = findToken();
  //console.log(createUser);
  await axios
    .patch(urlstring, data2, { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}
async function UpdateInsurance(insuranceState: boolean, expiryDate: string) {
  var data1 = JSON.stringify({
    insurance: `${insuranceState}`,
    insurance_date: `${expiryDate}`,
  });
  var data2 = JSON.parse(data1);

  let urlstring;

  urlstring = `${BACKEND_URL}/user`;
  const token: string = findToken();
  //console.log(createUser);
  await axios
    .patch(urlstring, data2, { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}
export {
  createUser,
  logIn,
  getDrivers,
  getUser,
  storeFile,
  verifyUserEmail,
  SendPasswordResetCode,
  UpdatePassword,
  GetFile,
  storePackageFile,
  AddCollaborators,
  UpdateInsurance,
};
