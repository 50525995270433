import { Box } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useState } from "react";
import MapDirectionsRenderer from "./DirectionsRenderer";
import { Route } from "../../types/RoutePoint";
import { GOOGLE_API_KEY } from "../../assets/static"


const midNorway = { lat: 60.843574, lng: 8.2469489 };
const zoom = 7;

interface MapProps {
  onRouteChange: (details: Route) => void;
}

export default function Map(props: MapProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY ?? '',
    libraries: ["places"]
  });

  const [waypoints, setWaypoints] = useState<google.maps.LatLng[]>([]);

  function onClickMap(event: google.maps.MapMouseEvent) {
    if (waypoints.length >= 27) return; //cannot have more than 25 waypoints plus origin and destination
    if (event.latLng) setWaypoints(waypoints.concat(event.latLng));
    else console.log(event);
  }

  function onDirectionsError(error: string) {
    //console.log(error);
    setWaypoints([]);
  }

  return (
    <>
      {isLoaded ? (
        <GoogleMap center={midNorway} zoom={zoom} onClick={onClickMap}>
          <MapDirectionsRenderer
            places={waypoints}
            travelMode={google.maps.TravelMode.DRIVING}
            onRouteChange={props.onRouteChange}
            onError={onDirectionsError}
          />
        </GoogleMap>
      ) : (
        <Box>
          <Spinner size="xl" />
        </Box>
      )}
    </>
  );
}
