import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const primary = definePartsStyle({
    field: {
        border: '1px solid',
        borderColor: 'black',
        background: 'gray.100',
        borderRadius: '7',
        borderWidth:"thin",
        mt:"4",
        mb:"4",
        fontSize:"xl",
        _hover:{
            transform: "scale(1.02)",
            borderColor:"primary"
        },
        _focus:{
            borderColor:"primary",
            borderWidth:"medium"
        },
        // Let's also provide dark mode alternatives
        _dark: {
          borderColor: 'white',
          background: 'gray.800',
        },
        
      },
  stepper: {
    color: "purple.500",
    background: "gray.200"
  }
});

export const numberInputTheme = defineMultiStyleConfig({
  variants: { primary }
});
