import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Icon,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

import { MdDeleteForever } from "react-icons/md";

interface AlertProps {
  onConfirm: () => void;
  subject: string;
}
/**********************************************************
/ NAME: Alert()
/********************************************************** 
 * SUMMARY: 
 * This function component renders an Alert button and an 
 * alert dialog. 
 * This is used to delete a cargo or a trip.
 * When the button is clicked, the alert dialog opens. 
 * When the dialog's confirm button is clicked, the dialog closes 
 * and a callback function is called.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following props:
 * - onConfirm: A callback function that is called when the 
 * dialog's confirm button is clicked
 * - subject: The subject of the alert dialog
 * 

/********************************************************** 
 * FUNCTIONS:
 * onConfirm() - Closes the dialog, calls the onConfirm prop,
 *  and sets the clicked state to true
 * 
/********************************************************** 
 * COMPONENTS:
 * - IconButton: A button that opens the dialog when clicked
 * - AlertDialog: A dialog that opens when the button is 
 * clicked and closes when the dialog's confirm button is clicked
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing an IconButton and an AlertDialog.
/*********************************************************/
export const Alert = (props: AlertProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clicked, setClicked] = useState<boolean>(false);
  function onConfirm() {
    setClicked(true);
    onClose();
    props.onConfirm();
  }

  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <IconButton
        variant="notification"
        onClick={onOpen}
        icon={<MdDeleteForever />}
        color={"red"}
        aria-label={""}
      >
        {!clicked ? (
          <Icon color={"red"} as={MdDeleteForever} />
        ) : (
          <Spinner size={"sm"} />
        )}
      </IconButton>

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={btnRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slett
            </AlertDialogHeader>

            <AlertDialogBody>
              Nå vil denne {props.subject} bli slettet, er du sikker?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={btnRef} onClick={onClose}>
                Tilbake
              </Button>
              <Button colorScheme="red" onClick={onConfirm} ml={3}>
                Slett
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
