import {
  SimpleGrid,
  AspectRatio,
  FormControl,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { Box, ButtonGroup, Input, Text, Button } from "@chakra-ui/react";
import { Formik } from "formik";
import { ResetButton, SubmitButton, TextareaControl } from "formik-chakra-ui";
//import * as Yup from "yup";
import Map from "../trip/Map";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import {
  ReactSelectMultiStyles,
  ReactSelectSingleStyles,
} from "../Styles/components/ReactSelectStyles";
import { useState, useEffect } from "react";
import { Route } from "../../types/RoutePoint";
import OneColumnLayout from "../layout/OneColLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { getIncoterms, updateCargoDb } from "../databasefunctions/cargos";
import { getLoadTypes } from "../databasefunctions/cargos";
import AsyncSelect from "react-select/async";
import { MdKeyboardReturn } from "react-icons/md";
import { GOOGLE_API_KEY } from "../../assets/static";
import { Spinner } from "@chakra-ui/spinner";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import { BrowserView, MobileView } from "react-device-detect";
import { filterOptions } from "../GeneralFunctions/ReactSelectFuncs";

interface CargoState {
  cargoName: string;
  cargoPickUpPoint: string;
  cargoEarliestStartDate: string;
  cargoLatestStartDate: string;
  cargoEarliestEndDate: string;
  cargoLatestEndDate: string;
  cargoDropOfPoint: string;
  cargoIncoTerms: {
    code: string;
    discription: string;
  };
  cargoLoadTypes: [string];
  cargoVehiclePk: string;
  cargoPackageHeightcm: string;
  cargoPackageLengthcm: string;
  cargoPackageWidthcm: string;
  cargoPackageWeightkg: string;
  cargoTrackingNumber: string;
  cargoNote: string;
}
/**********************************************************
/ NAME: UpdateCargo()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a form for updating cargo 
 * information. It allows the user to input new cargo details 
 * and updates the information when the form is submitted.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * useLocation() - Returns the current location object which contains information about the current URL
 * useNavigate() - Returns a function that navigates to different pages
 * getIncoterms() - Retrieves the incoterms
 * updateCargoDb() - Updates the cargo in the database
 * getLoadTypes() - Retrieves the load types
 * 
/********************************************************** 
 * COMPONENTS:
 * - useState: A React hook that lets you add state to function components
 * - OneColumnLayout: A component that renders a one column layout
 * - AsyncSelect: A component that renders an asynchronous select input
 * - MdKeyboardReturn: A component that renders a return icon
 * - Spinner: A component that renders a spinner
 * - StandaloneSearchBox: A component that renders a standalone search box
 * - BrowserView: A component that renders its children only on desktop devices
 * - MobileView: A component that renders its children only on mobile devices
 * - filterOptions: A function that filters options for a select input
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a form for updating cargo information.
/*********************************************************/
export default function UpdateCargo() {
  const { state } = useLocation();
  const {
    cargoName,
    cargoPickUpPoint,
    cargoEarliestStartDate,
    cargoLatestStartDate,
    cargoEarliestEndDate,
    cargoLatestEndDate,
    cargoDropOfPoint,
    cargoIncoTerms,
    cargoLoadTypes,
    cargoVehiclePk,
    cargoPackageHeightcm,
    cargoPackageLengthcm,
    cargoPackageWidthcm,
    cargoPackageWeightkg,
    cargoTrackingNumber,
    cargoNote,
  } = state as CargoState;
  const [cName] = useState(cargoName);
  const [origin, setOrigin] = useState(cargoPickUpPoint);
  const [cEarliestStartDate] = useState(cargoEarliestStartDate);
  const [cEarliestEndDate] = useState(cargoEarliestEndDate);
  const [cLatestStartDate] = useState(cargoLatestStartDate);
  const [cLatestEndDate] = useState(cargoLatestEndDate);
  const [destination, setDestination] = useState(cargoDropOfPoint);
  const [cPHcm] = useState(cargoPackageHeightcm);
  const [cPLcm] = useState(cargoPackageLengthcm);
  const [cPWcm] = useState(cargoPackageWidthcm);
  const [cPWkg] = useState(cargoPackageWeightkg);
  const [cTN] = useState(cargoTrackingNumber);
  const [cIncoT] = useState(cargoIncoTerms);
  const [cLoadT] = useState(cargoLoadTypes);
  const navigate = useNavigate();
  // const { projectID } = state as LandTransportScenarioState;
  const initialValues = {
    packageName: cName,
    requireCooling: false,
    requireToxic: false,
    requiredHeightcm: cPHcm,
    requiredLengthcm: cPLcm,
    requiredWidthcm: cPWcm,
    requiredWeightkg: cPWkg,
    firstStartDate: cEarliestStartDate,
    lastStartDate: cLatestStartDate,
    firstEndDate: cEarliestEndDate,
    lastEndDate: cLatestEndDate,
    origin: origin,
    destination: destination,
    notes: cargoNote,
  };

  const [LoadType, setLoadType] = useState<[{ value: string; label: string }]>([
    { value: cLoadT[0], label: cLoadT[0] },
  ]);
  const [incoTerm, setIncoTerms] = useState<{
    value: string;
    label: string;
    description: string;
  }>({
    value: cIncoT.code,
    label: cIncoT.code,
    description: cIncoT.discription,
  });
  const [renderMe, setRenderMe] = useState<boolean>(false);
  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });
  let isError = false;

  let waypoints: string[] = ["[]"];
  const onSubmit = async (values: any) => {
    //console.log("Test");
    //console.log(values.availableWidthcm);
    let loadTypes: any[] = [];
    LoadType.forEach((element: any) => {
      loadTypes.push(`${element.value}`);
    });
    //console.log(values)
    if (incoTerm.value !== "") {
      await updateCargoDb(
        cTN,
        values.requiredHeightcm,
        values.requiredLengthcm,
        values.requiredWidthcm,
        values.requiredWeightkg,
        values.firstStartDate,
        values.lastStartDate,
        values.firstEndDate,
        values.lastEndDate,
        waypoints,
        origin,
        destination,
        loadTypes,
        incoTerm.value
      )
        .then()
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError);
        });
    } else {
      isError = true;
      setErrorMessage("Incoterm mangler");
    }
    if (isError) {
      setHasError(true);
    } else {
      navigate("/cargos", {
        state: {
          notes: values.notes,
          scenarioName: values.scenarioName,
          // projectID: projectID,
        },
      });
    }
  };

  // const validationSchema = Yup.object({
  //   scenarioName: Yup.string().required("You need to provide a scenario name"),
  // });

  function newRoute(
    route: Route,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    setFieldValue("tripName", route.scenarioName);
    setFieldValue("origin", route.startPoint.name);
    setFieldValue("destination", route.endPoint.name);
  }

  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
  }

  function handleSelectLoad(data: any) {
    setLoadType(data);
  }
  function HandleSelectInco(data: any) {
    setIncoTerms(data);
    setRenderMe(true);
    //console.log(incoTerm)
  }
  useEffect(() => {
    //console.log(incoTerm)
  }, [incoTerm]);
  const loadOptionsLoads: any = (callback: Function) => {
    const LoadTypes = getLoadTypes();
    return LoadTypes;
  };

  const customStyles = ReactSelectMultiStyles();

  const loadOptionsInco: any = (callback: Function) => {
    const Incoterms = getIncoterms();
    return Incoterms;
  };
  const customStylesInco = ReactSelectSingleStyles();
  // const customStylesInco = {
  //   control: (base: any) => ({
  //     ...base,
  //     height: 40,
  //     minHeight: 40,
  //     backgroundColor: "white",
  //     marginTop: 10,
  //     fontSize: 18
  //   })
  // }

  return (
    <OneColumnLayout
      sectionLabel="Create Cargo"
      sectionHeading="Registrer gods"
      sectionIntro=""
      content={
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width="full">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            //validationSchema={validationSchema}
            onReset={handleReset}
          >
            {({ handleSubmit, values, errors, setFieldValue }) => (
              <>
                {/* {!hasResult && ( */}

                <Box
                  borderWidth="1px"
                  borderColor="blackAlpha.600"
                  rounded="lg"
                  shadow="1px 1px 3px rgba(0,0,0,0.3)"
                  p={6}
                  m="10px auto"
                  as="form"
                  onSubmit={handleSubmit as any}
                >
                  {hasError && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errorMessage}</AlertDescription>
                    </Alert>
                  )}
                  <BrowserView>
                    <SimpleGrid columns={2} columnGap="4">
                      <Text variant="label">Fra</Text>
                      <Text variant="label">Til</Text>

                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              name="origin"
                              placeholder="Adresse"
                              value={origin}
                              onChange={(e) => setOrigin(e.target.value)}
                              onBlur={(e) => setOrigin(e.target.value)}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                setOrigin(e.target.value);
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}

                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              type="text"
                              placeholder="Adresse"
                              name="destination"
                              value={destination}
                              onChange={(e) => setDestination(e.target.value)}
                              onBlur={(e) => setDestination(e.target.value)}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                setDestination(e.target.value);
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}
                    </SimpleGrid>
                  </BrowserView>
                  <MobileView>
                    <Text variant="label">Fra</Text>
                    {isLoaded ? (
                      <StandaloneSearchBox>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            name="origin"
                            placeholder="Adresse"
                            value={origin}
                            onChange={(e) => setOrigin(e.target.value)}
                            onBlur={(e) => setOrigin(e.target.value)}
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                              setOrigin(e.target.value);
                            }}
                          />
                        </FormControl>
                      </StandaloneSearchBox>
                    ) : (
                      <Box>
                        <Spinner size="xl" />
                      </Box>
                    )}
                    <Text variant="label">Til</Text>
                    {isLoaded ? (
                      <StandaloneSearchBox>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="text"
                            placeholder="Adresse"
                            name="destination"
                            value={destination}
                            onChange={(e) => setDestination(e.target.value)}
                            onBlur={(e) => setDestination(e.target.value)}
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                              setDestination(e.target.value);
                            }}
                          />
                        </FormControl>
                      </StandaloneSearchBox>
                    ) : (
                      <Box>
                        <Spinner size="xl" />
                      </Box>
                    )}
                  </MobileView>
                  <Text
                    textTransform="uppercase"
                    variant="label"
                    mb="0"
                    fontWeight="bold"
                  >
                    Sendingsdato
                  </Text>
                  <BrowserView>
                    <SimpleGrid columns={2} columnGap={2}>
                      <Text variant="label">Tidligst</Text>
                      <Text variant="label">Siste</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="firstStartDate"
                          value={values.firstStartDate}
                          onChange={(e) =>
                            setFieldValue("firstStartDate", e.target.value)
                          }
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="lastStartDate"
                          value={values.lastStartDate}
                          onChange={(e) =>
                            setFieldValue("lastStartDate", e.target.value)
                          }
                        />
                      </FormControl>
                    </SimpleGrid>
                  </BrowserView>
                  <MobileView>
                    <Text variant="label">Tidligst</Text>
                    <FormControl isRequired>
                      <Input
                        variant="standardTextInput"
                        type="datetime-local"
                        name="firstStartDate"
                        value={values.firstStartDate}
                        onChange={(e) =>
                          setFieldValue("firstStartDate", e.target.value)
                        }
                      />
                    </FormControl>
                    <Text variant="label">Siste</Text>
                    <FormControl isRequired>
                      <Input
                        variant="standardTextInput"
                        type="datetime-local"
                        name="lastStartDate"
                        value={values.lastStartDate}
                        onChange={(e) =>
                          setFieldValue("lastStartDate", e.target.value)
                        }
                      />
                    </FormControl>
                  </MobileView>

                  <Text textTransform="uppercase" variant="label" mb="0">
                    Ankomstdato
                  </Text>
                  <BrowserView>
                    <SimpleGrid columns={2} columnGap={2}>
                      <Text variant="label">Tidligst</Text>
                      <Text variant="label">Siste</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="firstEndDate"
                          value={values.firstEndDate}
                          onChange={(e) =>
                            setFieldValue("firstEndDate", e.target.value)
                          }
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <Input
                          type="datetime-local"
                          name="lastEndDate"
                          value={values.lastEndDate}
                          onChange={(e) =>
                            setFieldValue("lastEndDate", e.target.value)
                          }
                          variant="standardTextInput"
                        />
                      </FormControl>
                    </SimpleGrid>
                  </BrowserView>
                  <MobileView>
                    <Text variant="label">Tidligst</Text>
                    <FormControl isRequired>
                      <Input
                        variant="standardTextInput"
                        type="datetime-local"
                        name="firstEndDate"
                        value={values.firstEndDate}
                        onChange={(e) =>
                          setFieldValue("firstEndDate", e.target.value)
                        }
                      />
                    </FormControl>
                    <Text variant="label">Siste</Text>
                    <FormControl isRequired>
                      <Input
                        type="datetime-local"
                        name="lastEndDate"
                        value={values.lastEndDate}
                        onChange={(e) =>
                          setFieldValue("lastEndDate", e.target.value)
                        }
                        variant="standardTextInput"
                      />
                    </FormControl>
                  </MobileView>
                  <Text textTransform="uppercase" variant="label" mb="0">
                    Dimensjoner
                  </Text>
                  <SimpleGrid columns={3} columnGap="2">
                    
                    <Text variant="label">Lengde [cm]</Text>
                    <Text variant="label">Bredde [cm]</Text>
                    <Text variant="label">Høyde [cm]</Text>
                    
                    <FormControl isRequired>
                      <Input
                        name="requiredLengthcm"
                        variant="standardTextInput"
                        value={values.requiredLengthcm}
                        onChange={(e) =>
                          setFieldValue("requiredLengthcm", e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <Input
                        name="requiredWidthcm"
                        variant="standardTextInput"
                        value={values.requiredWidthcm}
                        onChange={(e) =>
                          setFieldValue("requiredWidthcm", e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <Input
                        name="requiredHeightcm"
                        variant="standardTextInput"
                        value={values.requiredHeightcm}
                        onChange={(e) =>
                          setFieldValue("requiredHeightcm", e.target.value)
                        }
                      />
                    </FormControl>
                  </SimpleGrid>
                  <Text variant="label">Vekt [kg]</Text>
                  <FormControl isRequired>
                    <Input
                      name="requiredWeightkg"
                      variant="standardTextInput"
                      value={values.requiredWeightkg}
                      onChange={(e) =>
                        setFieldValue("requiredWeightkg", e.target.value)
                      }
                    />
                  </FormControl>
                  <Text variant="label" mb="-2">
                    Godstype
                  </Text>

                  <AsyncSelect
                    defaultOptions
                    loadOptions={loadOptionsLoads}
                    value={LoadType}
                    filterOption={filterOptions}
                    onChange={(selectedOptions) =>
                      handleSelectLoad(selectedOptions)
                    }
                    isMulti
                    styles={customStyles}
                  />
                  <Text variant="label" mb="-2">
                    Incoterms
                  </Text>
                  <AsyncSelect
                    defaultOptions
                    loadOptions={loadOptionsInco}
                    value={incoTerm}
                    filterOption={filterOptions}
                    onChange={(selectedOptions) =>
                      HandleSelectInco(selectedOptions)
                    }
                    styles={customStylesInco}
                  />
                  {renderMe && (
                    <Box
                      borderWidth="1px"
                      rounded="lg"
                      shadow="1px 1px 3px rgba(0,0,0,0.3)"
                      p={6}
                      m="5px auto"
                    >
                      <Text mt="-4" fontSize="md" fontWeight="semibold">
                        Description
                      </Text>
                      <Text mt="0" mb="-4" ml="-4" mr="-4" fontSize="sm">
                        {incoTerm.description}
                      </Text>
                    </Box>
                  )}
                  <TextareaControl
                    name="notes"
                    label="Ytterligere merknader"
                    helperText="Valgfritt - hvis du trenger å notere noe om dette godset."
                  />
                  <SimpleGrid rowGap={8}>
                    <ButtonGroup mt={4}>
                      <SubmitButton variant="primary">Lagre</SubmitButton>
                      <ResetButton>Tilbakestill</ResetButton>
                    </ButtonGroup>
                    <Button
                      mt="6"
                      leftIcon={<MdKeyboardReturn />}
                      variant="return"
                      as="a"
                      href="/cargos"
                    >
                      {" "}
                      Tilbake
                    </Button>
                  </SimpleGrid>
                </Box>

                <AspectRatio ratio={16 / 9}>
                  <Map
                    onRouteChange={(route: Route) =>
                      newRoute(route, setFieldValue)
                    }
                    key={`${origin}-${destination}`}
                    origin={origin}
                    destination={destination}
                  />
                </AspectRatio>
              </>
            )}
          </Formik>
        </SimpleGrid>
      }
    />
  );
}
