import axios from "axios";
import { findToken } from "../login/Token";
import { BACKEND_URL , INTEGRATION_UPDATE_PACKAGE_INFO, API_KEY_SEND_OPTER, INTEGRATION_SEND_PACKAGE_EXTERNAL } from "../../assets/static";
import { PackageInfoForIntegration } from "../../types/packageIntegration";
/**********************************************************
/ NAME: findCargoMatch()
/********************************************************** 
 * SUMMARY: 
 * This function fetches the match for a specific cargo from the server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - tracking_number: The tracking number of the cargo
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the match for the cargo.
/*********************************************************/
async function findCargoMatch(tracking_number: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/find_match_test?tracking_number=${tracking_number}`;

    return await axios
      .post(urlstring, "", {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: getExtendedPackageInfoForIntegration()
/**********************************************************
 * SUMMARY:
 * This function fetches the extended package info for a specific cargo from the server using the cargo's tracking number.
 *
/**********************************************************
 * INPUT:
 * It takes the following parameter:
 * - trackingNumber: The tracking number of the cargo
 *
/**********************************************************
 * RETURN:
 * Returns the extended package info for the Opter integration.
/*********************************************************/
async function getExtendedPackageInfoForIntegration(trackingNumber: string): Promise<PackageInfoForIntegration | null> {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${INTEGRATION_UPDATE_PACKAGE_INFO}`;
    const preflight = await axios.options(urlstring);
    console.log("preflight: ", preflight);


    return await axios
      .post(urlstring, { packageId: trackingNumber, action: 'getPackageIntegration'} ,{
        headers: {
          "x-api-key": API_KEY_SEND_OPTER ?? '',
          "Content-Type": "application/json"
        },
        //"withCredentials": true,
        method: 'POST'
      })
      .then((res) => {
        console.log(res);
        //console.log(res.data);
        return res.data.data;
      });
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**********************************************************
/ NAME: updateExtendedPackageInfoForIntegration()
/**********************************************************
 * SUMMARY:
 * This function updates the extended package info for a specific cargo on the server using the cargo's tracking number.
 *
/**********************************************************
 * INPUT:
 * It takes the following parameters:
 * - trackingNumber: The tracking number of the cargo
 * - data: The data to be updated
 *
/**********************************************************
 * RETURN:
 * Returns the response from the server.
/*********************************************************/
async function updateExtendedPackageInfoForIntegration(trackingNumber: string, data: PackageInfoForIntegration) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${INTEGRATION_UPDATE_PACKAGE_INFO}`;

    const preflight = await axios.options(urlstring);
    console.log("prefight: ", preflight);

    return await axios.post(urlstring, { packageId: (!trackingNumber || trackingNumber==='') ? 0 : Number(trackingNumber), data: data, action: 'updatePackageIntegration'} ,{
        headers: {
          "x-api-key": API_KEY_SEND_OPTER ?? '',
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

async function sendPackageInfoToOpter(trackingNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${INTEGRATION_SEND_PACKAGE_EXTERNAL}`;

    const preflight = await axios.options(urlstring);
    console.log("prefight: ", preflight);

    return await axios.post(urlstring, { packageId: (!trackingNumber || trackingNumber==='') ? 0 : Number(trackingNumber), action: 'sendToOpter'} ,{
        headers: {
          "x-api-key": API_KEY_SEND_OPTER ?? '',
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

/** */
async function getStatusFromOpter(trackingNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${INTEGRATION_SEND_PACKAGE_EXTERNAL}`;

    const preflight = await axios.options(urlstring);
    console.log("prefight: ", preflight);

    return await axios.post(urlstring, { packageId: (!trackingNumber || trackingNumber==='') ? 0 : Number(trackingNumber), action: 'checkOrderStatus'} ,{
        headers: {
          "x-api-key": API_KEY_SEND_OPTER ?? '',
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}


/**********************************************************
/ NAME: dbGiveOffer()
/********************************************************** 
 * SUMMARY: 
 * This function sends an offer for a specific cargo to the 
 * server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - tracking_number: The tracking number of the cargo
 * - regNumber: The registration number of the carrier
 * - offer: The offer to be sent
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the response from the server.
/*********************************************************/
async function dbGiveOffer(
  tracking_number: string,
  regNumber: string,
  offer: string
) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/send_offer?tracking_number=${tracking_number}&car_reg_number=${regNumber}&offer_kr=${offer}`;

    return await axios
      .get(urlstring, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: dbDeclineMatch()
/********************************************************** 
 * SUMMARY: 
 * This function sends a decline for a specific cargo match 
 * to the server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - tracking_number: The tracking number of the cargo
 * - regNumber: The registration number of the carrier
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the response from the server.
/*********************************************************/
async function dbDeclineMatch(tracking_number: string, regNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/decline_offer?tracking_number=${tracking_number}&car_reg_number=${regNumber}`;

    return await axios
      .get(urlstring, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: dbAcceptMatch()
/********************************************************** 
 * SUMMARY: 
 * This function sends an acceptance for a specific cargo 
 * match to the server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - tracking_number: The tracking number of the cargo
 * - regNumber: The registration number of the carrier
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the response from the server.
/*********************************************************/
async function dbAcceptMatch(tracking_number: string, regNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/accept_match?tracking_number=${tracking_number}&car_reg_number=${regNumber}`;

    return await axios
      .get(urlstring, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: dbAcceptBid()
/********************************************************** 
 * SUMMARY: 
 * This function sends an acceptance for a specific bid to 
 * the server using the cargo's tracking number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - tracking_number: The tracking number of the cargo
 * - regNumber: The registration number of the carrier
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the response from the server.
/*********************************************************/
async function dbAcceptBid(tracking_number: string, regNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;
    urlstring = `${BACKEND_URL}/accepte_bid?tracking_number=${tracking_number}&car_reg_number=${regNumber}`;

    return await axios
      .get(urlstring, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return res;
      });
  } catch (err) {
    console.error(err);
  }
}

export {
  findCargoMatch,
  dbGiveOffer,
  dbDeclineMatch,
  dbAcceptMatch,
  dbAcceptBid,
  getExtendedPackageInfoForIntegration,
  updateExtendedPackageInfoForIntegration,
  sendPackageInfoToOpter,
  getStatusFromOpter
};
