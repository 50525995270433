import {
  Box,
  useColorModeValue,
  Stack,
  Image,
  IconButton,
  SimpleGrid,
  Center,
  Grid,
} from "@chakra-ui/react";

import { ColorModeSwitcher } from "./ColorModeSwitcher";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";

import React, { useState } from "react";
import { deleteTokenFromLS } from "../login/Token";

import { MdLogout, MdNotifications } from "react-icons/md";
import { FaQuestion, FaUserAlt } from "react-icons/fa";
import { BrowserView, MobileView } from "react-device-detect";
import { getUser } from "../databasefunctions/users";
import { findInfo } from "../databasefunctions/storeandfind";
import { REACT_APP_ENVIRONMENT } from "../../assets/static";

interface HeaderProps {
  disclosureProps: any;
  showTour?: () => void;
}

const Header: React.FC<HeaderProps> = (props): JSX.Element => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [uCargoOwner, setUCargoOwner] = useState<boolean>(false);
  const [uCarrier, setUCarrier] = useState<boolean>(false);
  const home = async () => {
    navigate("/dashboard");
  };
  const logout = async () => {
    deleteTokenFromLS();
    navigate("/");
  };
  const changeUser = async () => {
    const p = await getUser();
    setUser(p);
    //console.log(p)
    //console.log(user)
    const cargoOwner = findInfo("cargo_owner");
    if (cargoOwner === "true") {
      setUCargoOwner(true);
    }

    const carrier = findInfo("carrier");
    if (carrier === "true") {
      setUCarrier(true);
    }
    if (uCargoOwner) {
      navigate("/update-company", {
        state: {
          uCargoOwner: uCargoOwner,
          uCarrier: uCarrier,
          regNumber: user["cargo_owner"].business_reg_nr,
          contactmail: user.email,
          Accountantmail: user["cargo_owner"].accounting_email,
          tlfNumber: user.phone_number,
          compAdress: user["cargo_owner"].company_adress,
          compName: user["cargo_owner"].company_name,
        },
      });
    }
    navigate("/update-company", {
      state: {
        uCargoOwner: uCargoOwner,
        uCarrier: uCarrier,
        regNumber: user["carrier"].business_reg_nr,
        contactmail: user.email,
        Accountantmail: user["carrier"].accounting_email,
        tlfNumber: user.phone_number,
        compAdress: user["carrier"].company_adress,
        compName: user["carrier"].company_name,
      },
    });
  };
  const startTour = () => {
    props.showTour && props.showTour();
  };
  //const disclosureProps = getDisclosureProps();
  ////console.log(disclosureProps);

  return (
    <>
      <BrowserView>
        <Box bg={useColorModeValue("header", "#1A202C")} px={4}>
          <SimpleGrid
            margin="0"
            columns={3}
            h={100}
            width="95vw"
            justifyItems={"center"}
          >
            <Box>{['main','production'].includes(REACT_APP_ENVIRONMENT) ? '' : REACT_APP_ENVIRONMENT}</Box>

            <IconButton
              aria-label="Home"
              variant="notification"
              icon={<Image src={logo} height="5vh" />}
              h="6vh"
              //w="14"
              padding={2}
              justifySelf={"center"}
              alignSelf={"center"}
              mt="2"
              mr="-2"
              onClick={home}
            ></IconButton>

            <Stack
              direction={"row"}
              spacing={7}
              mt="6"
              padding="2"
              justifyContent="flex-end"
            >
              <IconButton
                aria-label="User"
                onClick={logout}
                variant="notification"
                icon={<MdLogout />}
              ></IconButton>
              <IconButton
                aria-label="User"
                onClick={changeUser}
                variant="notification"
                icon={<FaUserAlt />}
              ></IconButton>
              <ColorModeSwitcher justifySelf="end" />
              {props.showTour && 
                <IconButton
                  aria-label="User"
                  onClick={startTour}
                  variant="notification"
                  icon={<FaQuestion />}
                ></IconButton>
              }
            </Stack>
          </SimpleGrid>
        </Box>
      </BrowserView>
      <MobileView>
        <Box bg={useColorModeValue("header", "green.900")} px={4}>
          <Grid mb={2} templateColumns="40% 20% 20% 20%" gap={2} width="90vw">
            <Center>
              <IconButton
                ml="-20"
                aria-label="User"
                onClick={logout}
                variant="notification"
                icon={<MdLogout />}
              ></IconButton>
            </Center>
            <Center>
              <IconButton
                aria-label="Notifications"
                variant="notification"
                icon={<Image src={logo} height="9vh" />}
                h="10vh"
                //w="14"
                padding={2}
                justifySelf={"center"}
                alignSelf={"center"}
                mt="2"
                onClick={home}
              ></IconButton>
            </Center>
            <Center>
              <IconButton
                aria-label="Notifications"
                variant="notification"
                icon={<MdNotifications />}
              ></IconButton>
            </Center>
            <Center>
              <ColorModeSwitcher ml="-5" />
            </Center>
          </Grid>
        </Box>
      </MobileView>
    </>
  );
};
export default Header;
