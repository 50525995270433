import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  useDisclosure,
  ButtonGroup,
  Divider,
} from "@chakra-ui/react";
import * as React from "react";
import { BsTruck, BsPerson } from "react-icons/bs";
import { TbRoute } from "react-icons/tb";
import { useState } from "react";
interface DrawerProps {
  isOpen: boolean;
}

const MenuDrawer: React.FC<DrawerProps> = (DrawerProps) => {
  const { isOpen, onOpen, onClose, getDisclosureProps } = useDisclosure();
  const btnRef = React.useRef(null);
  const [drawerState, setDrawerState] = useState<boolean>(DrawerProps.isOpen);
  //console.log(drawerState);
  const disclosureProps = getDisclosureProps();
  //console.log(isOpen);
  // if (drawerState) {
  //   isOpen;
  // }
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        id="1"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Navigate</DrawerHeader>

          <DrawerBody>
            <Button
              leftIcon={<BsTruck />}
              ml="4"
              width="auto"
              mt="2"
              variant="ghost"
            >
              Trucks overview
            </Button>
            <Divider></Divider>
            <Button
              leftIcon={<BsPerson />}
              ml="4"
              width="auto"
              mt="2"
              variant="ghost"
            >
              Driver overview
            </Button>
            <Divider></Divider>
            <Button
              leftIcon={<TbRoute />}
              ml="4"
              width="auto"
              mt="2"
              variant="ghost"
            >
              Trips overview
            </Button>
            <Divider></Divider>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default MenuDrawer;
