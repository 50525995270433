import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxaKIYerTZmTM8Sh6J-65ONBXDeWsbBYI",
  authDomain: "alwayscargo-22jul.firebaseapp.com",
  projectId: "alwayscargo-22jul",
  storageBucket: "alwayscargo-22jul.appspot.com",
  messagingSenderId: "473713130061",
  appId: "1:473713130061:web:69c2eb28582090eaeb3a04",
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

//connectFunctionsEmulator(functions, 'localhost', 5001);
