import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Spinner,
  Box,
  Text,
  VStack,
  SimpleGrid,
  Center,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

//import { PDFViewer,Document, Page  } from '@react-pdf/renderer';

import { getUser } from "../databasefunctions/users";
import { useNavigate } from "react-router-dom";

interface AlertProps {
  onConfirm: () => void;
  price: string;
  pickUpPoint: string;
  pickUpTime: string;
  incoTerms: string;
  dropOffPoint: string;
  dropOffTime: string;
  type: string[];
  size: string;
  weight: number;
  distance: number;
  time: number;
  emissions: string;
  trackingNumber: string;
  carRegNumber: string;
  loadingRequirement: string[];
  noteCargoOwner: string;
  noteTrip: string;
}

/**********************************************************
/ NAME: Accept()
/********************************************************** 
 * SUMMARY: 
 * This function component renders an Accept button and 
 * an alert dialog. When the button is clicked, 
 * the alert dialog opens. When the dialog's confirm button 
 * is clicked, the dialog closes and a callback function is called.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following props:
 * - onConfirm: A callback function that is called when the 
 * dialog's confirm button is clicked
 * - price: The price of the cargo
 * - pickUpPoint: The pickup point of the cargo
 * - pickUpTime: The pickup time of the cargo
 * - incoTerms: The inco terms of the cargo
 * - dropOffPoint: The drop off point of the cargo
 * - dropOffTime: The drop off time of the cargo
 * - type: The type of the cargo
 * - size: The size of the cargo
 * - weight: The weight of the cargo
 * - distance: The distance of the cargo
 * - time: The time of the cargo
 * - emissions: The emissions of the cargo
 * - trackingNumber: The tracking number of the cargo
 * - carRegNumber: The car registration number of the cargo
 * - loadingRequirement: The loading requirement of the cargo
 * - noteCargoOwner: The note of the cargo owner
 * - noteTrip: The note of the trip
 * 

/********************************************************** 
 * FUNCTIONS:
 * onConfirm() - Closes the dialog, calls the onConfirm prop, 
 * and sets the clicked state to true
 * 
/********************************************************** 
 * COMPONENTS:
 * - Button: A button that opens the dialog when clicked
 * - AlertDialog: A dialog that opens when the button is 
 * clicked and closes when the dialog's confirm button is clicked
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a Button and an AlertDialog.
/*********************************************************/
export const Accept = (props: AlertProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clicked, setClicked] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const navigate = useNavigate();

  function onConfirm() {
    setClicked(true);
    onClose();
    props.onConfirm();
  }
  useEffect(() => {
    if (!user) {
      getUser()?.then((p) => setUser(p)).catch(() => navigate("/login"));
    }
  }, [user]);
  const btnRef = useRef<HTMLButtonElement>(null);

  function handleClick() {
    onOpen();
  }

  return (
    <>
      <Button margin="10px" variant="primary" onClick={handleClick}>
        {!clicked ? <Box>Aksepter</Box> : <Spinner size={"sm"} />}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={btnRef}
        size="full"
      >
        <AlertDialogOverlay>
          <AlertDialogContent width="80%" maxWidth="1000px">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Aksepter Tilbud
            </AlertDialogHeader>

            {user && (
              <>
                <AlertDialogBody>
                  <VStack>
                    <Text fontSize="xl">
                      Ved å akseptere tilbudet, aksepterer du avtalen om
                      transport mellom deg som transportkjøper, og
                      transportøren.
                    </Text>
                    <Text>
                      Avtalen baserer seg på Always Cargo sin standardavtale og
                      blir sendt ut til begge parter etter ingåelse.
                    </Text>
                  </VStack>
                  <Box
                    borderWidth="2px"
                    rounded="lg"
                    borderColor="blackAlpha.400"
                    shadow="2px 2px 4px rgba(0,0,0,0.3)"
                    maxWidth="90vw"
                    minWidth="30vw"
                    p={6}
                    m="10px auto"
                  >
                    <VStack mt="10">
                      <Text fontWeight="bold">Avtale om transport mellom</Text>
                      <Text fontWeight="bold">
                        {user.cargo_owner.company_name} og
                      </Text>
                      <Text fontWeight="bold">Transportør</Text>
                    </VStack>
                    <Box>
                      <Text
                        ml="2"
                        mt="4"
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        Denne Avtalen er inngått mellom
                      </Text>
                      <HStack ml="10" mt="4">
                        <Text>(1)</Text>
                        <Text fontWeight="semibold">
                          {user.cargo_owner.company_name}
                        </Text>
                        <Text>
                          org. nr.: {user.cargo_owner.business_reg_nr}
                        </Text>
                        <Text>{user.cargo_owner.company_adress}</Text>
                      </HStack>
                      <HStack ml="10">
                        <Text fontWeight="semibold">Kontaktperson</Text>
                        <Text>Navn: {user.name}</Text>
                        <Text>TLF: {user.phone_number}</Text>
                        <Text>Email: {user.email}</Text>
                      </HStack>
                      <HStack ml="10">
                        <Text>(2)</Text>
                        <Text fontWeight="semibold">Transportør AS</Text>
                        <Text>org. nr.: XXX XXX XXX</Text>
                        <Text>Firmaadresse</Text>
                      </HStack>
                      <HStack ml="10">
                        <Text fontWeight="semibold">Kontaktperson</Text>
                        <Text>Navn</Text>
                        <Text>TLF</Text>
                        <Text>email</Text>
                      </HStack>

                      <Text
                        ml="2"
                        mt="4"
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        Følgende er avtalt mellom partene:
                      </Text>
                      <Text ml="2" as="i">
                        Dette er en juridisk bindende avtale. Avtalen kan brukes
                        som fakturagrunnlag i forbindelse med oppgjør, og skal
                        skje direkte mellom partene som inngår denne.
                      </Text>
                      <Text mt="4" ml="4">
                        1. Transport av forsendelse med følgende
                        nøkkelinformasjon:
                      </Text>
                      <SimpleGrid columns={2}>
                        <Text ml="8" mt="4">
                          Pris eks. MVA
                        </Text>{" "}
                        <Text mt="4">{props.price}</Text>
                        <Text ml="8" mt="2">
                          Henteadresse
                        </Text>{" "}
                        <Text mt="2">{props.pickUpPoint}</Text>
                        <Text ml="8" mt="2">
                          Hentetidspunkt
                        </Text>{" "}
                        <Text mt="2">{props.pickUpTime}</Text>
                        <Text ml="8" mt="2">
                          Incoterms
                        </Text>{" "}
                        <Text mt="2">{props.incoTerms}</Text>
                        <Text ml="8" mt="2">
                          Leveringsadresse
                        </Text>{" "}
                        <Text mt="2">{props.dropOffPoint}</Text>
                        <Text ml="8" mt="2">
                          Leveringstidspunkt
                        </Text>{" "}
                        <Text mt="2">{props.dropOffTime}</Text>
                        <Text ml="8" mt="2">
                          Forsendelsestype
                        </Text>{" "}
                        <Text mt="2">{props.type}</Text>
                        <Text ml="8" mt="2">
                          Størrelse [cmxcmxcm]
                        </Text>{" "}
                        <Text mt="2">{props.size}</Text>
                        <Text ml="8" mt="2">
                          Vekt[kg]
                        </Text>{" "}
                        <Text mt="2">{props.weight}</Text>
                        <Text ml="8" mt="2">
                          Estimert avstand [km]
                        </Text>{" "}
                        <Text mt="2">{props.distance}</Text>
                        <Text ml="8" mt="2">
                          Estimert tid [timer]
                        </Text>{" "}
                        <Text mt="2">{props.time}</Text>
                        <Text ml="8" mt="2">
                          Estimert utslipp [kg CO2ekv]
                        </Text>{" "}
                        <Text mt="2">{props.emissions}</Text>
                        <Text ml="8" mt="2">
                          Laste/Lossebehov
                        </Text>{" "}
                        <Text mt="2">{props.loadingRequirement}</Text>
                        <Text ml="8" mt="2">
                          Andre merknader fra transportkjøper
                        </Text>{" "}
                        <Text mt="2">{props.noteCargoOwner}</Text>
                        <Text ml="8" mt="2">
                          Andre merknader fra transportør
                        </Text>{" "}
                        <Text mt="2">{props.noteTrip}</Text>
                      </SimpleGrid>
                      <Text mt="4" ml="4">
                        2. Transporten vil utføres av bil med
                        registreringsnummer: {props.carRegNumber}{" "}
                      </Text>
                      <Text mt="4" ml="4">
                        3. Avtale og faktura merkes med{" "}
                      </Text>
                      <HStack mt="2" ml="8">
                        <Text> a) Always Cargo Sitt oppdragsnummer: </Text>
                        <Text ml="2"> {props.trackingNumber} </Text>
                      </HStack>
                      <Text mt="2" ml="8">
                        {" "}
                        b) Partenes referanse/nummer i interne logistikk
                        og/eller regnskapssystemer{" "}
                      </Text>
                      <Text mt="4" ml="4">
                        4. Betaling ved bomtur når varen ikke er klar ved
                        henting, eller ved feil/manglende informasjon som er
                        relevant i forhold til henting og levering: 60 % av
                        transportoppdragets verdi.{" "}
                      </Text>
                      <Text mt="4" ml="4">
                        5. Forbehold: Ved skade skal dette tas opp direkte med
                        avtaleparten. Dersom det er grunnlag for prisreduksjon
                        skal dette forhandles i henhold til av gjeldende lover
                        og regler, eller avtalevilkår som fremgår her:.
                        Tilleggsbestemmelser: _____________________________
                      </Text>
                      <Text mt="4" ml="4">
                        6. Always Cargo skal holdes skadesløse for indirekte
                        eller følgeskader eller tap, inkludert, men ikke
                        begrenset til; tap av profitt, tap av kontrakt, tap av
                        tilbud, skade, dødsfall eller ødeleggelser på personell
                        eller utstyr/eiendom.{" "}
                      </Text>
                      <Text mt="4" ml="4">
                        7. Avtalen er underlagt norsk lov og tvister skal løses
                        ved Agder Tingrett, Kristiansand.{" "}
                      </Text>
                    </Box>
                    <Center mt="20">
                      <SimpleGrid columns={2} columnGap="20">
                        <Text>__________________________</Text>{" "}
                        <Text>__________________________</Text>
                        <Text>For {user.cargo_owner.company_name}</Text>{" "}
                        <Text>Transportør</Text>
                      </SimpleGrid>
                    </Center>
                    <Text fontWeight="bold" mt="10" textTransform="uppercase">
                      Vedlegg
                    </Text>
                    <Text
                      fontWeight="semibold"
                      mt="4"
                      textTransform="uppercase"
                    >
                      Fraktbrev
                    </Text>
                    <Text
                      fontWeight="semibold"
                      mt="4"
                      textTransform="uppercase"
                    >
                      Forsikringsbevis
                    </Text>
                  </Box>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button variant="return" ref={btnRef} onClick={onClose}>
                    Tilbake
                  </Button>
                  <Button variant="primary" onClick={onConfirm} ml={3}>
                    Aksepter
                  </Button>
                </AlertDialogFooter>
              </>
            )}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
