/**********************************************************
/ NAME: TripsOverview()
/********************************************************** 
 * SUMMARY: 
 * TripsOverview fetches all the trips the user has registered 
 * from the database and populates a list using the TripRow component.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * getTrips() - Retrieves the trips
 * 
/********************************************************** 
 * COMPONENTS:
 * - useState: A React hook that lets you add state to function components
 * - useEffect: A React hook that lets you perform side effects in function components
 * - OneColumnLayout: A component that renders a one column layout
 * - TripRow: A component that renders a trip row
 * - Box: A component that renders a box
 * - Button: A component that renders a button
 * - Grid: A component that renders a grid
 * - Heading: A component that renders a heading
 * - Stack: A container that stacks its children
 * - Divider: A component that renders a divider
 * - Center: A component that centers its children
 * - MdKeyboardReturn: A component that renders a return icon
 * - BrowserView: A component that renders its children only on desktop devices
 * - MobileView: A component that renders its children only on mobile devices
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a list of registered trips.
/*********************************************************/
import {
  Box,
  Button,
  Grid,
  Heading,
  Stack,
  Divider,
  Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getTrips } from "../databasefunctions/trips";
import OneColumnLayout from "../layout/OneColLayout";
import ProjectRow from "./TripRow";
import { MdKeyboardReturn } from "react-icons/md";
import { BrowserView, MobileView } from "react-device-detect";

function TripsOverview() {
  const [trips, setTrips] = useState<any>(null);

  useEffect(() => {
    if (!trips) {
      getTrips()?.then((p) => setTrips(p));
    }
  }, [trips]);
  return (
    <OneColumnLayout
      sectionLabel="All trips"
      sectionHeading="Mine oppdrag"
      sectionIntro=""
      content={
        <>
          <BrowserView>
            <Grid
              mb={6}
              templateColumns="7vw 10vw 10vw 10vw 10vw 10vw 10vw 8vw"
              gap={2}
            >
              <Box></Box>
              <Box>
                <Heading fontSize={"1.2rem"}> Fra</Heading>
              </Box>
              <Box>
                <Heading fontSize={"1.2rem"}> Start dato</Heading>
              </Box>
              <Box>
                <Heading fontSize={"1.2rem"}> Til</Heading>
              </Box>

              <Box>
                <Heading fontSize={"1.2rem"}> Kjøretøy</Heading>
              </Box>
              <Box>
                <Heading fontSize={"1.2rem"}> Status</Heading>
              </Box>
            </Grid>
            <Divider variant="thick" mt="-4" mb="2" width={"90vw"}></Divider>
            {trips?.map((trip: any) => (
              <ProjectRow key={trip.id} trip={trip} />
            ))}
            <Stack direction="row" spacing={4} align="center">
              <Button variant="primary" as="a" href="/create-trip">
                Nytt oppdrag
              </Button>
            </Stack>
            <Stack mt="4" direction="row" spacing={4} align="center">
              <Button
                leftIcon={<MdKeyboardReturn />}
                variant="return"
                as="a"
                href="/dashboard"
              >
                Tilbake
              </Button>
            </Stack>
          </BrowserView>
          <MobileView>
            <Grid
              mb={6}
              ml="-2"
              mr="0"
              templateColumns="20vw 24vw 20vw 22vw"
              gap={1}
            >
              <Center>
                <Heading fontSize={"1.2rem"}> </Heading>
              </Center>
              <Center>
                <Heading fontSize={"1.2rem"}> Fra</Heading>
              </Center>
              <Center>
                <Heading fontSize={"1.2rem"}> Dato</Heading>
              </Center>

              <Center>
                <Heading fontSize={"1.2rem"}> Status</Heading>
              </Center>
            </Grid>
            <Divider
              variant="thick"
              mt="-4"
              mb="1"
              ml="-2"
              width={"85vw"}
            ></Divider>
            {trips?.map((trip: any) => (
              <ProjectRow key={trip.id} trip={trip} />
            ))}

            <Center mt="0" mb="4">
              <Button variant="primary" as="a" href="/create-trip">
                Nytt oppdrag
              </Button>
            </Center>

            <Box alignSelf="flex-end">
              <Button
                leftIcon={<MdKeyboardReturn />}
                variant="return"
                as="a"
                href="/dashboard"
              >
                Tilbake
              </Button>
            </Box>
          </MobileView>
        </>
      }
    />
  );
}
export default TripsOverview;
