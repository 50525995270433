import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firestore/firebaseConfig";
import { findInfo } from "../databasefunctions/storeandfind";
/**********************************************************
/ NAME: UserInfo()
/********************************************************** 
 * SUMMARY: 
 * This function component retrieves the name of the currently 
 * logged in user from the database and displays it.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * findInfo("name") - Retrieves the name of the currently logged in user from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * - useEffect: A React hook that performs side effects in function components
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing the text "Logged in as {user}" 
 * if a user is logged in, or an empty JSX element otherwise.
/*********************************************************/
function UserInfo() {
  const user: string = findInfo("name");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  //console.log(user)
  if (user) return <>Logged in as {user}</>;
  return <></>;
}
export default UserInfo;
