/**********************************************************
/ NAME: TripRow
/********************************************************** 
 * SUMMARY: 
 * TripRow creates a line for each trip with the key information 
 * for each trip. It also allows users to delete and update information for the trip.
 * 
/********************************************************** 
 * INPUT: 
 * trip: an object containing information about each trip
 * 
/********************************************************** 
 * FUNCTIONS:
 * deleteTrip(): lets the user delete the trip
 * findTripMatch(): finds matches for the trip
 * CreatePaymentLink(): creates a payment link for the trip
 * 
/********************************************************** 
 * COMPONENTS:
 * - Box: A component that renders a box
 * - Button: A component that renders a button
 * - Link: A component that renders a link
 * - Grid: A component that renders a grid
 * - Divider: A component that renders a divider
 * - VStack: A container that stacks its children vertically
 * - Center: A component that centers its children
 * - Alert: A component that renders an alert dialog
 * - BrowserView: A component that renders its children only on desktop devices
 * - MobileView: A component that renders its children only on mobile devices
 * - MdOutlineFindReplace: A component that renders a find replace icon
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a row with trip information.
/*********************************************************/
import {
  Box,
  Button,
  Link,
  Grid,
  Divider,
  VStack,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { deleteTrip, findTripMatch } from "../databasefunctions/trips";
import { Alert } from "../layout/AlertDialog";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { CreatePaymentLink } from "../databasefunctions/Payments";
import { MdOutlineFindReplace } from "react-icons/md";
import { dbAcceptMatch, sendPackageInfoToOpter } from "../databasefunctions/matches";
import DisplayIntegrationStatus from "../Matches/DisplayIntegrationStatus";


interface TripRowProps {
  trip: {
    route_number: string;
    name: string;
    origin: string;
    start_date_time: string;
    end_date_time: string;
    destination: string;
    vehicle_pk: string;
    available_height_cm: string;
    available_length_cm: string;
    available_width_cm: string;
    available_weight_kg: string;
    note: string;
    loading_capabilities: string[];
    available_matches: {
      tracking_number: string;
      offer: string;
      status: string;
      duration_diff_min: number;
      distance_diff_m: number;
      package: {
        distance_m: number;
        duration_min: number;
        pick_up_point: string;
        drop_of_point: string;
        package_length_cm: number;
        package_width_cm: number;
        package_height_cm: number;
        package_weight_kg: number;
        earliest_start_time: string;
        latest_start_time: string;
        earliest_end_time: string;
        latest_end_time: string;
        loads_types: string[];
        icoterm: { code: string; discription: string };
        note: string;
      };
    };
  };
}

export default function TripRow(props: TripRowProps) {
  const {
    route_number,
    origin,
    start_date_time,
    destination,
    vehicle_pk,
    available_height_cm,
    available_length_cm,
    available_width_cm,
    available_weight_kg,
    available_matches,
    end_date_time,
    note,
    loading_capabilities,
  } = props.trip;
  const navigate = useNavigate();
  const [renderMe, setRenderMe] = useState<boolean>(true);
  const [searchMatch, setSearchMatch] = useState<boolean>(false);
  const [isMatching, setIsMatching] = useState(false);

  const handleMatchButtonClick = () => {
    setIsMatching(true);
    findTripMatch(route_number).then((res) => {
      setIsMatching(false);

    })
  };
    const findMatch = async (route_number: any) => {
      setSearchMatch(true);
      await findTripMatch(route_number);
      //console.log(Match?.data?.details?.distance_diff)
    };
    useEffect(() => {
      //console.log(searchMatch)
    }, [searchMatch, route_number]);

    function goToTripOverview() {
      navigate("/update-trip", {
        state: {
          tripRouteNumber: route_number,
          tripOrigin: origin,
          tripStartDate: start_date_time,
          tripEndDate: end_date_time,
          tripDestination: destination,
          tripVehiclePk: vehicle_pk,
          tripAvailableHeightcm: available_height_cm,
          tripAvailableLengthcm: available_length_cm,
          tripAvailableWidthcm: available_width_cm,
          tripAvailableWeightkg: available_weight_kg,
          tripNote: note,
        },
      });
    }

    async function onDeleteTrip(tripNumber: string) {
      await deleteTrip(tripNumber);
      // deleteProject(projectID).then(() => {
      setRenderMe(false);
      // });
    }

    function GiveOfferButtonClick() {
      //console.log(available_matches['package']['icoterm'])
      //console.log(available_matches['package']['loads_types'])
      navigate("/give-offer", {
        state: {
          distance_diff: available_matches["distance_diff_m"],
          distance_package: available_matches["package"]["distance_m"],
          duration_diff: available_matches["duration_diff_min"],
          duration_package: available_matches["package"]["duration_min"],
          package_height: available_matches["package"]["package_height_cm"],
          package_length: available_matches["package"]["package_length_cm"],
          package_width: available_matches["package"]["package_width_cm"],
          package_weight: available_matches["package"]["package_weight_kg"],
          pick_up_point: available_matches["package"]["pick_up_point"],
          drop_off_point: available_matches["package"]["drop_of_point"],
          earliest_start_time:
            available_matches["package"]["earliest_start_time"],
          latest_start_time: available_matches["package"]["latest_start_time"],
          earliest_end_time: available_matches["package"]["earliest_end_time"],
          latest_end_time: available_matches["package"]["latest_end_time"],
          load_types: available_matches["package"]["loads_types"],
          loading_requirements: loading_capabilities,
          note: note,
          noteCargo: available_matches["package"]["note"],
          incoterms: available_matches["package"]["icoterm"],
          tracking_number: available_matches["tracking_number"],
          car_reg_number: vehicle_pk,
        },
      });
    }
    function EvaluateOfferButtonClick() {
      navigate("/evaluate-offer-carrier", {
        state: {
          distance_diff: available_matches["distance_diff_m"],
          distance_package: available_matches["package"]["distance_m"],
          duration_diff: available_matches["duration_diff_min"],
          duration_package: available_matches["package"]["duration_min"],
          package_height: available_matches["package"]["package_height_cm"],
          package_length: available_matches["package"]["package_length_cm"],
          package_width: available_matches["package"]["package_width_cm"],
          package_weight: available_matches["package"]["package_weight_kg"],
          pick_up_point: available_matches["package"]["pick_up_point"],
          drop_off_point: available_matches["package"]["drop_of_point"],
          earliest_start_time:
            available_matches["package"]["earliest_start_time"],
          latest_start_time: available_matches["package"]["latest_start_time"],
          earliest_end_time: available_matches["package"]["earliest_end_time"],
          latest_end_time: available_matches["package"]["latest_end_time"],
          load_types: available_matches["package"]["loads_types"],
          incoterms: available_matches["package"]["icoterm"],
          tracking_number: available_matches["tracking_number"],
          car_reg_number: vehicle_pk,
          offer: available_matches["offer"],
        },
      });
    }
      function SendToOpter() {
        navigate("/deal-signed", 
          { state: { tracking_number: available_matches["tracking_number"] } })
        
      }
    async function BidAcceptedClick() {
      //await dbAcceptMatch(trackingNumber,regNumber);
      await CreatePaymentLink(
        available_matches["tracking_number"],
        vehicle_pk,
        Math.ceil(Number(available_matches["offer"]) / 10)
      ).then(async (rsesponse) => {
        //window.open(response, "_blank");
        //console.info(response, "---", available_matches["tracking_number"], vehicle_pk );
        await dbAcceptMatch(available_matches["tracking_number"], vehicle_pk).then((acceptResponse) => {
          console.log("acceptResponse: " , acceptResponse);
        
          navigate("/deal-signed", { state: { tracking_number: available_matches["tracking_number"] } });
        });
      }).catch(async (error) => {
        await dbAcceptMatch(available_matches["tracking_number"], vehicle_pk).then((acceptResponse) => {
          console.log("acceptResponse: " , acceptResponse);
        
          navigate("/deal-signed", { state: { tracking_number: available_matches["tracking_number"] } });
      });
      /*navigate("/payment-status", {
        state: {
          tracking_number: available_matches["tracking_number"],
          car_reg_number: vehicle_pk,
        },
      });*/
      
    });
  }
    let buttonSize = "md";
    if (isMobile) {
      buttonSize = "sm";
    }
    let UpdateButton = (
      <Button isDisabled variant="update" onClick={() => goToTripOverview()}>
        Endre
      </Button>
    );
    if (
      available_matches["status"] === "Unmatched" ||
      available_matches["status"] === "Declined"
    ) {
      UpdateButton = (
        <Button isActive variant="update" onClick={() => goToTripOverview()}>
          Endre
        </Button>
      );
    }
    let OfferButton = (


   

      <Box>
        <Button
          variant="primary"
          size={buttonSize}
          leftIcon={<MdOutlineFindReplace />}
          isActive
          onClick={handleMatchButtonClick}
          disabled={isMatching}
        >
          {isMatching ? <Spinner size="sm" color="white" /> : 'Match'}
        </Button>
      </Box>
    );

    //console.log(available_matches['status'])
    if (available_matches["status"] === "Pending") {
      OfferButton = (
        <Box>
          <Button
            size={buttonSize}
            variant="giveOffer"
            isActive
            onClick={() => GiveOfferButtonClick()}
          >
            Tilbud
          </Button>
        </Box>
      );
    } else if (available_matches["status"] === "Offer Recieved") {
      OfferButton = (
        <Box>
          <Button
            size={buttonSize}
            colorScheme="teal"
            isActive
            onClick={() => EvaluateOfferButtonClick()}
          >
            Evaluer
          </Button>
        </Box>
      );
    } else if (available_matches["status"] === "Accepted") {
      OfferButton = (
        <>
        <Box ml="-5">
          <Link href="/rating">
            <Button size={buttonSize} colorScheme="green">
              Tilbakemelding
            </Button>
          </Link>
          <Button
            size={'xs'}
            hidden={false}
            isActive
            onClick={() => SendToOpter()}>Til Opter...</Button>
            <div><DisplayIntegrationStatus tracking_number={available_matches["tracking_number"]}></DisplayIntegrationStatus></div>
        </Box>
        </>
      );
    } else if (available_matches["status"] === "Bid Accepted") {
      OfferButton = (
        <Box>
          <Button
            size={buttonSize}
            variant="primary"
            isActive
            onClick={() => BidAcceptedClick()}
          >
            Signer
          </Button>
        </Box>
      );
    }
    let dateString = start_date_time.split("T");
    if (!renderMe) {
      return <></>;
    }
    return (
      <>
        <BrowserView>
          <Grid
            mb={2}
            templateColumns="7vw 10vw 10vw 10vw 10vw 10vw 10vw 8vw"
            gap={2}
          >
            <Box>
              <Alert
                onConfirm={() => onDeleteTrip(route_number)}
                subject="turen"
              />
            </Box>
            <Box fontSize={"1.0rem"}>{origin}</Box>
            <Box fontSize={"1.0rem"}>{start_date_time}</Box>
            <Box fontSize={"1.0rem"}>{destination}</Box>
            <Box fontSize={"1.0rem"}>{vehicle_pk}</Box>
            <Box fontSize={"1.0rem"}>{available_matches["status"]}</Box>
            {searchMatch && (
              <Box>
                <Button
                  size={buttonSize}
                  leftIcon={<MdOutlineFindReplace />}
                  isDisabled
                  onClick={() => findMatch(route_number)}
                >
                  Søker
                </Button>
              </Box>
            )}
            {!searchMatch && OfferButton}
            <Box>{UpdateButton}</Box>

            <Divider variant="thick" m="1" width={"90vw"}></Divider>
          </Grid>
        </BrowserView>
        <MobileView>
          <Grid mb={2} ml="-2" templateColumns="20vw 24vw 20vw 22vw" gap={1}>
            <VStack>
              <Box>{UpdateButton}</Box>
              <Box>
                <Alert
                  onConfirm={() => onDeleteTrip(route_number)}
                  subject="turen"
                />
              </Box>
            </VStack>
            <Center fontSize={"1.0rem"}>{origin}</Center>
            <Center fontSize={"1.0rem"}>{dateString[0]}</Center>

            <Center>{OfferButton}</Center>
            <Divider
              variant="thick"
              mt="1"
              mb="1"
              ml="-2"
              width={"85vw"}
            ></Divider>
          </Grid>
        </MobileView>
      </>
    );
  }

