/**********************************************************
/ NAME: UpComingCargoOverview()
/********************************************************** 
 * SUMMARY: 
 * This component fetches and displays the upcoming cargos 
 * from the database in a grid format.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * getUpComingCargos() - Fetches the upcoming cargos from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * CargoRow - Represents a row in the grid, displaying 
 * information about a cargo
 * 
/********************************************************** 
 * RETURN:
 * Returns a jsx element with the grid of upcoming cargos.
/*********************************************************/
import { Button, Grid, Heading, Divider, Center, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUpComingCargos } from "../databasefunctions/cargos";
import { auth } from "../../firestore/firebaseConfig";

import CargoRow from "./UpComingCargoRow";

import { GoPackage } from "react-icons/go";
export default function UpComingCargoOverview() {
  const [cargos, setCargo] = useState<any>(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!cargos) {
      getUpComingCargos()?.then((p) => setCargo(p));
      //console.log(cargos)
    }
  }, [cargos, user]);

  return (
    <>
      <Grid templateColumns="10% 80% 10%" gap={2}>
        <Icon as={GoPackage} w={10} h={10} />

        <Center>
          <Heading fontSize={"1.4rem"}> Mine Matcher</Heading>
        </Center>
      </Grid>

      <Grid mb={2} templateColumns="32% 32% 36%" gap={2}>
        <Center>
          <Heading fontSize={"1.2rem"}> Dato</Heading>
        </Center>
        <Center>
          <Heading fontSize={"1.2rem"}> Sted</Heading>
        </Center>

        <Center>
          <Heading fontSize={"1.2rem"}> Status</Heading>
        </Center>
      </Grid>
      <Divider variant="thick" mt="1" mb="1" width={"95%"}></Divider>
      {cargos?.map((cargo: any) => (
        <CargoRow key={cargo.id} cargo={cargo} />
      ))}
      <Center>
        <Button variant="primary" as="a" href="/cargos">
          Se alle
        </Button>
      </Center>
    </>
  );
}
