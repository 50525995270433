import React from 'react';
import { Flex, Box, Heading, VStack, Image, Alert,AlertIcon,AlertDescription,FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import { Formik } from "formik";
import logo from "../../assets/logo.png";
function CustomerSatisfactionForm() {
  const [easyToFind, setEasyToFind] = React.useState(5);
  const [parking, setParking] = React.useState(5);
  const [delay, setDelay] = React.useState(5);
  const [admin, setAdmin] = React.useState(5);
  const [cooperation, setCooperation] = React.useState(5);
  const [aids, setAids] = React.useState(5);

  return (
    <>
      <VStack>
      <Image src={logo} boxSize="15vw" marginTop="2vh" />
        <Flex align="center" justify="center" height="100vh">
        <Box
              borderWidth="2px"
              rounded="lg"
              borderColor="blackAlpha.400"
              shadow="2px 2px 4px rgba(0,0,0,0.3)"
              maxWidth="90vw"
              minWidth="30vw"
              p={6}
              m="10px auto"
              as="form"
            >
          <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg">
            <Box textAlign="center">
              <Heading>Tilbakemeldingr</Heading>
            </Box>
            <Box my={4} textAlign="left">
              <form>
                <FormControl>
                  <FormLabel>Rett adresse, enkelt å finne?</FormLabel>
                  <Box display="flex" alignItems="center">
                    {[1, 2, 3].map((value) => (
                      <Box
                        cursor={'pointer'}
                        key={value}
                        onClick={() => setEasyToFind(value)}
                        bg={easyToFind === value ? 'green.400' : ''}
                        p={2}
                        borderRadius="full"
                      >
                        {value === 1 ? '😠' : value === 2 ? '😐' : '😊'}
                      </Box>
                    ))}
                  </Box>
                </FormControl>

                <FormControl mt={6}>
                  <FormLabel>Parkering/kø-system?</FormLabel>
                  <Box display="flex" alignItems="center">
                    {[1, 2, 3].map((value) => (
                      <Box
                        cursor={'pointer'}
                        key={value}
                        onClick={() => setParking(value)}
                        bg={parking === value ? 'green.400' : ''}
                        p={2}
                        borderRadius="full"
                      >
                        {value === 1 ? '😠' : value === 2 ? '😐' : '😊'}
                      </Box>
                    ))}
                  </Box>
                </FormControl>

                <FormControl mt={6}>
                  <FormLabel>Ventetid/forsinkelse?</FormLabel>
                  <Box display="flex" alignItems="center">
                    {[1, 2, 3].map((value) => (
                      <Box
                        cursor={'pointer'}
                        key={value}
                        onClick={() => setDelay(value)}
                        bg={delay === value ? 'green.400' : ''}
                        p={2}
                        borderRadius="full"
                      >
                        {value === 1 ? '😠' : value === 2 ? '😐' : '😊'}
                      </Box>
                    ))}
                  </Box>
                </FormControl>

                <FormControl mt={6}>
                  <FormLabel>Effektiv admin/fraktpapirer?</FormLabel>
                  <Box display="flex" alignItems="center">
                    {[1, 2, 3].map((value) => (
                      <Box
                        cursor={'pointer'}
                        key={value}
                        onClick={() => setAdmin(value)}
                        bg={admin === value ? 'green.400' : ''}
                        p={2}
                        borderRadius="full"
                      >
                        {value === 1 ? '😠' : value === 2 ? '😐' : '😊'}
                      </Box>
                    ))}
                  </Box>
                </FormControl>

                <FormControl mt={6}>
                  <FormLabel>Samarbeid varelevering/mottak</FormLabel>
                  <Box display="flex" alignItems="center">
                    {[1, 2, 3].map((value) => (
                      <Box
                        cursor={'pointer'}
                        key={value}
                        onClick={() => setCooperation(value)}
                        bg={cooperation === value ? 'green.400' : ''}
                        p={2}
                        borderRadius="full"
                      >
                        {value === 1 ? '😠' : value === 2 ? '😐' : '😊'}
                      </Box>
                    ))}
                  </Box>
                </FormControl>


                <FormControl mt={6}>
                  <FormLabel>Hjelpemidler lasting</FormLabel>
                  <Box display="flex" alignItems="center">
                    {[1, 2, 3].map((value) => (
                      <Box
                        cursor={'pointer'}
                        key={value}
                        onClick={() => setAids(value)}
                        bg={aids === value ? 'green.400' : ''}
                        p={2}
                        borderRadius="full"
                      >
                        {value === 1 ? '😠' : value === 2 ? '😐' : '😊'}
                      </Box>
                    ))}
                  </Box>
                </FormControl>

                <FormControl mt={6}>
                  <FormLabel>tilleggskommentare?</FormLabel>
                  <Input type="textarea" />
                </FormControl>

                <Button width="full" mt={4} type="submit" as="a" href="/dashboard">
                  Submit
                </Button>
              </form>
            </Box>
          </Box>
          </Box>
        </Flex>
      </VStack>
    </>
  );
}

export default CustomerSatisfactionForm;