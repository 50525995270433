import { Box } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import MapDirectionsRenderer from "./DirectionsRenderer";
import { Route } from "../../types/RoutePoint";
import { GOOGLE_API_KEY } from "../../assets/static";

const midNorway = { lat: 60.843574, lng: 8.2469489 };
const zoom = 7;

interface MapProps {
  onRouteChange: (details: Route) => void;
  origin: string;
  destination: string;
}

export default function Map(props: MapProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });

  const [waypoints, setWaypoints] = useState<google.maps.LatLng[]>([]);

  useEffect(() => {
    if (isLoaded) {
      var geocoder = new google.maps.Geocoder();
      getLatLng(props.origin, geocoder).then((location: any) => {
        console.log(location);
        const origingLatLng = new google.maps.LatLng(location); // Oslo

        getLatLng(props.destination, geocoder).then((location: any) => {
          console.log(location);
          const destinationLatLng = new google.maps.LatLng(location); // Bergen

          if (origingLatLng && destinationLatLng) {
            console.log("test");
            setWaypoints(waypoints.concat([origingLatLng, destinationLatLng]));
          }
        });
      });

      // console.log(test1);
      // console.log(test2);
      // var origingLatLng = new google.maps.LatLng(test1); // New York
      // var destinationLatLng = new google.maps.LatLng(test2); // Los Angeles
      // console.log("test");
      // console.log(origin);
      // console.log(destination);
    }
  }, [isLoaded, props.origin, props.destination]);

  // function onClickMap(event: google.maps.MapMouseEvent) {
  //   if (waypoints.length >= 27) return; //cannot have more than 25 waypoints plus origin and destination
  //   if (event.latLng) setWaypoints(waypoints.concat(event.latLng));
  //   else console.log(event);
  // }
  function getLatLng(inputString: string, geocoder: any) {
    return new Promise((resolve, reject) => {
      if (!geocoder) return console.log("no geocoder");
      geocoder.geocode(
        { address: inputString },
        (results: any, status: any) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results === null) return console.log("no results");
            else {
              console.log(results[0].geometry.location);
              const location = results[0].geometry.location;
              resolve(location);
            }
            // Use geolocation here
          } else {
            console.log(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    });
  }
  function onDirectionsError(error: string) {
    //console.log(error);
    setWaypoints([]);
  }

  return (
    <>
      {isLoaded ? (
        <GoogleMap center={midNorway} zoom={zoom}>
          <MapDirectionsRenderer
            places={waypoints}
            travelMode={google.maps.TravelMode.DRIVING}
            onRouteChange={props.onRouteChange}
            onError={onDirectionsError}
          />
        </GoogleMap>
      ) : (
        <Box>
          <Spinner size="xl" />
        </Box>
      )}
    </>
  );
}
