import axios from "axios";
import { BACKEND_URL } from "../../assets/static";
import { findToken } from "../login/Token";
import { saveInfoToSS } from "./storeandfind";
/**********************************************************
/ NAME: getVehicleInformation()
/********************************************************** 
 * SUMMARY: 
 * This function fetches the information of a specific vehicle 
 * from the server using the vehicle's registration number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - regNumber: The registration number of the vehicle
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * saveInfoToSS() - Saves information to session storage
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It fetches the 
 * vehicle information and saves it to session storage.
/*********************************************************/
async function getVehicleInformation(regNumber: string) {
  const token: string = findToken();

  let urlstring;

  urlstring = `${BACKEND_URL}/vehicle_data?car_reg_number=${regNumber}`;

  //console.log(getVehicleInformation);
  await axios
    .get(urlstring, { headers: { Authorization: "Bearer " + token } })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      //console.log(res.data["vehicle_brand"]);
      ////console.log(companyAddress);
      saveInfoToSS("vehicleGroup", res.data["vehicle_group"]);
      saveInfoToSS("vehicleBrand", res.data["vehicle_brand"]);
      saveInfoToSS("vehicleModel", res.data["vehicle_model"]);
      saveInfoToSS("vehiclePayload", res.data["vehicle_payload_kg"]);
      saveInfoToSS("vehicleHeight", res.data["vehicle_dimensions"]["hoyde"]);
      saveInfoToSS("vehicleWidth", res.data["vehicle_dimensions"]["bredde"]);
      saveInfoToSS("vehicleLength", res.data["vehicle_dimensions"]["lengde"]);

      return;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}
/**********************************************************
/ NAME: saveVehicleInformation()
/********************************************************** 
 * SUMMARY: 
 * This function saves the information of a specific vehicle 
 * to the server using the vehicle's registration number and 
 * other details.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - regNumber: The registration number of the vehicle
 * - vehicleGroup: The group of the vehicle
 * - model: The model of the vehicle
 * - brand: The brand of the vehicle
 * - payload: The payload of the vehicle
 * - height: The height of the vehicle
 * - width: The width of the vehicle
 * - length: The length of the vehicle
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the 
 * vehicle information to the server.
/*********************************************************/
async function saveVehicleInformation(
  regNumber: string,
  vehicleGroup: string,
  model: string,
  make: string,
  height: number,
  width: number,
  length: number,
  trailerRegNumber: string,
  loads: string[]
) {
  var data = JSON.stringify({
    car_reg_number: `${regNumber}`,
    vehicle_group: `${vehicleGroup}`,
    brand: `${make}`,
    model: `${model}`,
    height_cm: `${height}`,
    length_cm: `${length}`,
    width_cm: `${width}`,
    compatible_loads: `${loads}`,
    trailer_pk: `${trailerRegNumber}`,
  });
  //console.log(loads)

  var data2 = JSON.parse(data);

  data2.compatible_loads = loads;
  //console.log(data2);
  const token: string = findToken();

  let urlstring;

  urlstring = `${BACKEND_URL}/vehicle`;

  await axios
    .post(urlstring, data2, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: updateVehicleInformation()
/********************************************************** 
 * SUMMARY: 
 * This function updates the information of a specific
 * vehicle on the server using the vehicle's registration 
 * number and other details.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - regNumber: The registration number of the vehicle
 * - vehicleGroup: The group of the vehicle
 * - model: The model of the vehicle
 * - brand: The brand of the vehicle
 * - height: The height of the vehicle
 * - width: The width of the vehicle
 * - length: The length of the vehicle
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the 
 * updated vehicle information to the server.
/*********************************************************/
async function updateVehicleInformation(
  regNumber: string,
  vehicleGroup: string,
  model: string,
  make: string,
  height: number,
  width: number,
  length: number,
  trailerRegNumber: string,
  loads: string[]
) {
  var data = JSON.stringify({
    car_reg_number: `${regNumber}`,
    vehicle_group: `${vehicleGroup}`,
    brand: `${make}`,
    model: `${model}`,
    height_cm: `${height}`,
    length_cm: `${length}`,
    width_cm: `${width}`,
    compatible_loads: `${loads}`,
    trailer_pk: `${trailerRegNumber}`,
  });
  //console.log(loads)

  var data2 = JSON.parse(data);

  data2.compatible_loads = loads;
  //console.log(data2);
  const token: string = findToken();

  let urlstring;

  urlstring = `${BACKEND_URL}/vehicle`;

  await axios
    .patch(urlstring, data2, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return;
    })
    .catch(function (error) {
      //console.log(error)
      throw error.response;
    });
}

/**********************************************************
/ NAME: getTrucks()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all the trucks the user has 
 * registrered from the server.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of all available trucks.
/*********************************************************/
async function getTrucks() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/all_available_vehicles`;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        let trucks: any[] = [];
        trucks.push(...res.data.vehicles);
        //console.log(trucks);
        return trucks;
      });
  } catch (err) {
    console.error(err);
  }
}
/**********************************************************
/ NAME: deleteVehicle()
/********************************************************** 
 * SUMMARY: 
 * This function deletes a specific vehicle from the server 
 * using the vehicle's registration number.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - regNumber: The registration number of the vehicle
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends a delete 
 * request to the server.
/*********************************************************/
async function deleteVehicle(regNumber: string) {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/vehicle?car_reg_number=${regNumber}`;

    await axios
      .delete(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        return;
      });
  } catch (err) {
    console.error(err);
  }
}

/**********************************************************
/ NAME: saveTrailerInformation()
/********************************************************** 
 * SUMMARY: 
 * This function saves the information of a specific trailer
 *  to the server using the trailer's registration number and 
 * other details.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - regNumber: The registration number of the trailer
 * - vehicleGroup: The group of the trailer
 * - model: The model of the trailer
 * - make: The make of the trailer
 * - height: The height of the trailer
 * - width: The width of the trailer
 * - length: The length of the trailer
 * - loads: An array of loads the trailer can carry
 * - vehiclePrimaryKey: The primary key of the vehicle
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the 
 * trailer information to the server.
/*********************************************************/
async function saveTrailerInformation(
  regNumber: string,
  vehicleGroup: string,
  model: string,
  make: string,
  height: number,
  width: number,
  length: number,
  loads: string[],
  vehiclePrimaryKey: string
) {
  var data = JSON.stringify({
    trailer_reg_number: `${regNumber}`,
    vehicle_group: `${vehicleGroup}`,
    brand: `${make}`,
    model: `${model}`,
    height_cm: `${height}`,
    length_cm: `${length}`,
    width_cm: `${width}`,
    compatible_loads: `${loads}`,
    vehicle_pk: `${vehiclePrimaryKey}`,
  });

  var data2 = JSON.parse(data);

  data2.compatible_loads = loads;
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/trailer`;

    await axios
      .post(urlstring, data2, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return;
      });
  } catch (err) {
    console.error(err);
  }
}
/**********************************************************
/ NAME: updateTrailerInformation()
/********************************************************** 
 * SUMMARY: 
 * This function updates the information of a specific 
 * trailer on the server using the trailer's 
 * registration number and other details.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - regNumber: The registration number of the trailer
 * - vehicleGroup: The group of the trailer
 * - model: The model of the trailer
 * - make: The make of the trailer
 * - height: The height of the trailer
 * - width: The width of the trailer
 * - length: The length of the trailer
 * - loads: An array of loads the trailer can carry
 * - vehiclePrimaryKey: The primary key of the vehicle
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything. It sends the 
 * updated trailer information to the server.
/*********************************************************/
async function updateTrailerInformation(
  regNumber: string,
  vehicleGroup: string,
  model: string,
  make: string,
  height: number,
  width: number,
  length: number,
  loads: string[],
  vehiclePrimaryKey: string
) {
  var data = JSON.stringify({
    trailer_reg_number: `${regNumber}`,
    vehicle_group: `${vehicleGroup}`,
    brand: `${make}`,
    model: `${model}`,
    height_cm: `${height}`,
    length_cm: `${length}`,
    width_cm: `${width}`,
    compatible_loads: `${loads}`,
    vehicle_pk: `${vehiclePrimaryKey}`,
  });

  var data2 = JSON.parse(data);

  data2.compatible_loads = loads;
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/trailer`;

    await axios
      .patch(urlstring, data2, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        return;
      });
  } catch (err) {
    console.error(err);
  }
}
/**********************************************************
/ NAME: getSelectedTrucks()
/********************************************************** 
 * SUMMARY: 
 * This function fetches all available trucks from the server.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of all available trucks.
/*********************************************************/
async function getSelectedTrucks() {
  const token: string = findToken();
  try {
    let urlstring;
    urlstring = `${BACKEND_URL}/all_available_vehicles`;
    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);
        let trucks: any[] = [];
        Object.values(res.data.vehicles).forEach((element: any) => {
          //console.log(element)
          trucks.push({
            value: `${element.car_reg_number}`,
            label: `${element.car_reg_number}`,
            description: `${element.car_reg_number}`,
          });
        });
        //cargos.push(...res.data);
        //console.log(trucks);
        return trucks;
      });
  } catch (err) {
    console.error(err);
    return [];
  }
}
/**********************************************************
/ NAME: getTruckStatus()
/********************************************************** 
 * SUMMARY: 
 * This function fetches the status of all trucks from the server.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Retrieves the authentication token
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns an array of all truck statuses.
/*********************************************************/
async function getTrucksStatus() {
  const token: string = findToken();
  try {
    let urlstring;

    urlstring = `${BACKEND_URL}/vehicle_status
    `;

    return await axios
      .get(urlstring, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        //console.log(res);
        //console.log(res.data);

        return res.data;
      });
  } catch (err) {
    console.error(err);
  }
}
export {
  getVehicleInformation,
  saveVehicleInformation,
  getTrucks,
  updateVehicleInformation,
  deleteVehicle,
  saveTrailerInformation,
  updateTrailerInformation,
  getSelectedTrucks,
  getTrucksStatus,
};
