import { Grid, GridItem, Heading } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { Footer } from "./Footer";
import Header from "./Header";

import MenuDrawer from "./Menu";

import { findToken } from "../login/Token";
import { useNavigate } from "react-router-dom";
import { getUser } from "../databasefunctions/users";
interface OneColProps {
  content: ReactNode;
  sectionLabel: string;
  sectionHeading: string;
  sectionIntro: string;
  showTour?: () => void;
}
/**********************************************************
/ NAME: OneColumnLayout()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a layout with a header, 
 * a menu drawer, and a main content area. 
 * It also checks if a token exists and redirects to the home page if it doesn't.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following props:
 * - content: The components to be rendered in the main content area
 * - sectionLabel: The label of the section
 * - sectionHeading: The heading of the section
 * - sectionIntro: The introduction of the section
 * 

/********************************************************** 
 * FUNCTIONS:
 * findToken() - Checks if a token exists
 * 
/********************************************************** 
 * COMPONENTS:
 * - Grid: A container that arranges its children in a grid
 * - GridItem: A component that is placed inside a Grid container
 * - Header: A component that renders a header
 * - MenuDrawer: A component that renders a menu drawer
 * - Heading: A component that renders a heading
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a layout with a header, 
 * a menu drawer, and a main content area.
/*********************************************************/
function OneColumnLayout(props: OneColProps) {
  const navigate = useNavigate();
  const token: string = findToken();
  const [user, setUser] = useState<any>(null); //user object

  useEffect(() => {
    if (token === "") {
      return navigate("/");
    }
    console.log(user);
    console.log(!user);
    if (user === null) {

        getUser().then((u) => setUser(u)).catch(() => navigate("/login")); 
      
    } else {
      console.log(user.insurance);
      if (user?.insurance === false) {
        return navigate("/update-insurance");
      }
    }
    //console.log(user);
  }, [user]); // Add an empty dependency array to useEffect
  //   //console.log(isOpen);
  return (
    <Grid
      minH="100vh"
      templateRows="80px min-content 8fr 50px"
      templateColumns={"1fr"}
    >
      <GridItem colSpan={2}>
        <Header disclosureProps showTour={props.showTour} />
      </GridItem>
      <MenuDrawer isOpen />
      {/* <GridItem
        colSpan={2}
        marginLeft={4}
        marginTop="4"
        alignSelf={"flex-start"}
      >
        <SectionLabel size="lg">{props.sectionLabel}</SectionLabel>
      </GridItem> */}
      <GridItem colSpan={2} marginLeft={4} marginTop={4}>
        <Heading alignSelf={"flex-start"} fontSize={"2em"}>
          {props.sectionHeading}
        </Heading>
      </GridItem>
      {/* <GridItem colSpan={2} marginLeft={4} marginTop={8}>
        <Text fontSize={"1.2em"} alignSelf={"flex-start"}>
          {props.sectionIntro}
        </Text>
      </GridItem> */}
      <GridItem p={6}>{props.content}</GridItem>
      <GridItem colSpan={2}>
        <Footer />
      </GridItem>
    </Grid>
  );
}

export default OneColumnLayout;
