
export const textStyles={
    baseStyle:{},

    sizes:{},

    variants: {
        label: {
            bg: "transparent",
            color: "black",
            fontFamily: "arial",
            fontSize:"16",
            fontWeight: "semibold",
            marginTop:"6px",
            marginBottom:"-4",
            // shadow: "2px 2px 6px rgba(0,0,0,0.3)",
            _dark:{
                
                color: "white",
                
            }
            
        }
    },

    defaultProps: {},

}