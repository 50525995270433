const INPUT_BORDER_THICKNESS ="thin"
const PRIMARY_COLOUR="#000"
const BUTTON_BORDER_THICKNESS="revert"
const LABEL_FONT_WEIGHT="semibold"
const BUTTON_VARIANT="outline"
// prod URL:https://hmwpwlhreavxlytt472xgvfaf40hizwu.lambda-url.eu-north-1.on.aws
const BACKEND_URL= process.env.REACT_APP_BACKEND_URL; //"https://bflwwjyk76fai7j6b2bcrhoxde0sxcjb.lambda-url.eu-north-1.on.aws"
// Dev BACKEND_URL="https://bflwwjyk76fai7j6b2bcrhoxde0sxcjb.lambda-url.eu-north-1.on.aws"
// const BACKEND_URL="http://127.0.0.1:8000"
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY ?? '';  //"AIzaSyBndJoKpNsClohAzxDAKP0Px3Lcbtkg3f4";
const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? 'main';

const INTEGRATION_UPDATE_PACKAGE_INFO = process.env.REACT_APP_INTEGRATION_UPDATE_PACKAGE_INFO
const INTEGRATION_SEND_PACKAGE_EXTERNAL = process.env.REACT_APP_INTEGRATION_SEND_PACKAGE_EXTERNAL
const API_KEY_SEND_OPTER = process.env.REACT_APP_API_KEY_SEND_OPTER


export {INPUT_BORDER_THICKNESS,PRIMARY_COLOUR,BUTTON_BORDER_THICKNESS,LABEL_FONT_WEIGHT,BUTTON_VARIANT,BACKEND_URL, GOOGLE_API_KEY, REACT_APP_ENVIRONMENT, INTEGRATION_UPDATE_PACKAGE_INFO, INTEGRATION_SEND_PACKAGE_EXTERNAL, API_KEY_SEND_OPTER}  