/**********************************************************
/ NAME: findInfo()
/********************************************************** 
 * SUMMARY: 
 * This function retrieves an item from the session storage 
 * using the item's name.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameter:
 * - name: The name of the item
 * 
/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * Returns the item from the session storage. If the item 
 * does not exist, it returns an empty string.
/*********************************************************/
function findInfo(name: string): string {
  let item = sessionStorage.getItem(name);
  if (item === null) {
    item = "";
  }
  return item;
}

/**********************************************************
/ NAME: saveInfoToSS()
/********************************************************** 
 * SUMMARY: 
 * This function saves an item to the session storage.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following parameters:
 * - name: The name of the item
 * - item: The item to be saved
 * 
/********************************************************** 
 * FUNCTIONS:
 * None
 * 
/********************************************************** 
 * COMPONENTS:
 * None
 * 
/********************************************************** 
 * RETURN:
 * This function does not return anything.
/*********************************************************/
function saveInfoToSS(name: string, item: string) {
  sessionStorage.setItem(name, item);
}
export { findInfo, saveInfoToSS };
