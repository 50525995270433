import {
  SimpleGrid,
  AspectRatio,
  Button,
  FormControl,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { Box, ButtonGroup, Input, Text } from "@chakra-ui/react";
import { Formik } from "formik";
import { ResetButton, SubmitButton, TextareaControl } from "formik-chakra-ui";
import { MdKeyboardReturn } from "react-icons/md";
//import * as Yup from "yup";
import Map from "../trip/Map";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { GOOGLE_API_KEY } from "../../assets/static";
import { useEffect, useState } from "react";
import { Route } from "../../types/RoutePoint";
import OneColumnLayout from "../layout/OneColLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { updateTrip } from "../databasefunctions/trips";
import { Spinner } from "@chakra-ui/spinner";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import { BrowserView, MobileView } from "react-device-detect";
import { ReactSelectSingleStyles } from "../Styles/components/ReactSelectStyles";
import { getSelectedTrucks } from "../databasefunctions/vehicles";
import AsyncSelect from "react-select/async";

import * as Yup from "yup";
import React from "react";

interface TripState {
  tripRouteNumber: string;
  tripOrigin: string;
  tripStartDate: string;
  tripEndDate: string;
  tripDestination: string;
  tripVehiclePk: string;
  tripAvailableHeightcm: string;
  tripAvailableLengthcm: string;
  tripAvailableWidthcm: string;
  tripAvailableWeightkg: string;
  tripNote: string;
}
/**********************************************************
/ NAME: UpdateTrip()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a form for updating trip 
 * information. It allows the user to input new trip details 
 * and updates the information when the form is submitted.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props.
 * 

/********************************************************** 
 * FUNCTIONS:
 * useLocation() - Returns the current location object which 
 * contains information about the current URL
 * useNavigate() - Returns a function that navigates to different pages
 * onSubmit(values: any) - Updates the trip details if the input is valid
 * 
/********************************************************** 
 * COMPONENTS:
 * - useState: A React hook that lets you add state to function components
 * - Formik: A form library for React
 * - Field: A component that renders a field
 * - ErrorMessage: A component that renders an error message
 * - Button: A component that renders a button
 * - Box: A component that renders a box
 * - VStack: A container that stacks its children vertically
 * - Input: A component that renders an input field
 * - Text: A component that renders text
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a form for updating trip information.
/*********************************************************/
export default function UpdateTrip() {
  const { state } = useLocation();
  const {
    tripRouteNumber,
    tripOrigin,
    tripStartDate,
    tripEndDate,
    tripDestination,
    tripVehiclePk,
    tripAvailableHeightcm,
    tripAvailableLengthcm,
    tripAvailableWidthcm,
    tripAvailableWeightkg,
    tripNote,
  } = state as TripState;
  const [tRouteNumber] = useState(tripRouteNumber);
  const [tOrigin] = useState(tripOrigin);
  const [tStartDate] = useState(tripStartDate);
  const [tEndDate] = useState(tripEndDate);
  const [tDestination] = useState(tripDestination);
  const [tregNumber] = useState(tripVehiclePk);
  const [tAHcm] = useState(tripAvailableHeightcm);
  const [tALcm] = useState(tripAvailableLengthcm);
  const [tAWcm] = useState(tripAvailableWidthcm);
  const [tAWkg] = useState(tripAvailableWeightkg);
  const navigate = useNavigate();
  const initialValues = {
    RouteNumber: tRouteNumber,
    truckRegNumber: tregNumber,
    coolingCompatible: false,
    toxicCompatible: false,
    availableHeightcm: tAHcm,
    availableLengthcm: tALcm,
    availableWidthcm: tAWcm,
    availableWeightkg: tAWkg,
    startDate: tStartDate,
    endDate: tEndDate,
    origin: tOrigin,
    destination: tDestination,
    note: tripNote,
  };
  //console.log(tRouteNumber)
  // const { projectID } = state as LandTransportScenarioState;
  const [scenarioRoute, setScenarioRoute] = useState<Route | null>(null);
  const [mapKey, setMapKey] = useState<number>(1); //hack to force map to re-render on reset
  const [origin, setOrigin] = useState<string>(tOrigin);
  const [destination, setDestination] = useState<string>(tDestination);
  const [vehicleRegNumber, setVehicleRegNumber] = useState<{
    value: string;
    label: string;
    description: string;
  }>({ value: tregNumber, label: tregNumber, description: tregNumber });
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  //const[vehicleRegNumberObject, setVehicleRegNumberObject]=useState<object>()
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });
  let isError = false;
  let waypoints: string[] = ["[]"];
  const onSubmit = async (values: any) => {
    //console.log("Test");
    //console.log(tRouteNumber);
    if (vehicleRegNumber.value !== "") {
      await updateTrip(
        tRouteNumber,
        vehicleRegNumber.value,
        //values.truckRegNumber,
        values.availableHeightcm,
        values.availableLengthcm,
        values.availableWidthcm,
        values.availableWeightkg,
        values.startDate,
        values.endDate,
        waypoints,
        origin,
        destination
      )
        .then()
        .catch((error) => {
          isError = true;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError);
        });
    } else {
      isError = true;
      setErrorMessage("Venligst velg et kjøretøy");
    }
    if (isError) {
      setHasError(true);
    } else {
      navigate("/trips", {
        state: {
          notes: values.notes,
          scenarioName: values.scenarioName,
          // projectID: projectID,
        },
      });
    }
  };
  //console.log(initialValues)
  // const validationSchema = Yup.object({
  //   scenarioName: Yup.string().required("You need to provide a scenario name"),
  // });

  function newRoute(
    route: Route,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    setScenarioRoute(route);
    setFieldValue("tripName", route.scenarioName);
    setFieldValue("origin", route.startPoint.name);
    setFieldValue("destination", route.endPoint.name);
  }
  useEffect(() => {
    //console.log(vehicleRegNumber)
  }, [vehicleRegNumber, origin, destination]);
  function HandleSelectVehicle(data: any) {
    //console.log(data.value)
    setVehicleRegNumber(data);
    //console.log(vehicleRegNumber)
  }
  function handleReset(_values: any, _formProps: any) {
    setMapKey(Math.round(Math.random() * 100)); //change to update state & force re-render of map .
    setScenarioRoute(null);
  }
  const loadOptionsVehicles: any = (callback: Function) => {
    const Vehicles = getSelectedTrucks();
    return Vehicles;
  };

  const customStyles = ReactSelectSingleStyles();
  const validationSchema = Yup.object().shape({
    tripName: Yup.string().required("Please enter a trip name"),
    truckRegNumber: Yup.string().required("Please select a truck"),
    availableHeightcm: Yup.number()
      .required("Please enter a value")
      .positive("Please enter a positive value"),
    availableLengthcm: Yup.number()
      .required("Please enter a value")
      .positive("Please enter a positive value"),
    availableWidthcm: Yup.number()
      .required("Please enter a value")
      .positive("Please enter a positive value"),
    availableWeightkg: Yup.number()
      .required("Please enter a value")
      .positive("Please enter a positive value"),
    startDate: Yup.date().required("Please enter a start date"),
    endDate: Yup.date().required("Please enter an end date"),
    origin: Yup.string().required("Please enter an origin"),
    destination: Yup.string().required("Please enter a destination"),
  });

  function IsPositive(value: any): boolean {
    return Number(value) >= 0;
  }
  return (
    <OneColumnLayout
      sectionLabel="Create trip"
      sectionHeading="Registrer oppdrag"
      sectionIntro=""
      content={
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width="full">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            //validationSchema={validationSchema}
            onReset={handleReset}
          >
            {({ handleSubmit, values, errors, setFieldValue }) => (
              <>
                {/* {!hasResult && ( */}

                <Box
                  borderWidth="1px"
                  rounded="lg"
                  shadow="1px 1px 3px rgba(0,0,0,0.3)"
                  p={6}
                  m="10px auto"
                  as="form"
                  onSubmit={handleSubmit as any}
                  borderColor="black"
                  maxWidth={"87vw"}
                >
                  {hasError && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{errorMessage}</AlertDescription>
                    </Alert>
                  )}
                  <BrowserView>
                    <SimpleGrid columns={2} columnGap="4">
                      <Text variant="label">Fra</Text>
                      <Text variant="label">Til</Text>
                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              placeholder="Adresse"
                              name="origin"
                              value={origin}
                              onChange={(e) => setOrigin(e.target.value)}
                              onBlur={(e) => setOrigin(e.target.value)}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                setOrigin(e.target.value);
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}
                      {isLoaded ? (
                        <StandaloneSearchBox>
                          <FormControl isRequired>
                            <Input
                              variant="standardTextInput"
                              type="text"
                              placeholder="Adresse"
                              name="destination"
                              value={destination}
                              onChange={(e) => setDestination(e.target.value)}
                              onBlur={(e) => setDestination(e.target.value)}
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                                setDestination(e.target.value);
                              }}
                            />
                          </FormControl>
                        </StandaloneSearchBox>
                      ) : (
                        <Box>
                          <Spinner size="xl" />
                        </Box>
                      )}
                      <Text variant="label">Start-dato</Text>
                      <Text variant="label">Ankomst-dato</Text>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="startDate"
                          value={values.startDate}
                          onChange={(e) =>
                            setFieldValue("startDate", e.target.value)
                          }
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <Input
                          variant="standardTextInput"
                          type="datetime-local"
                          name="endDate"
                          value={values.endDate}
                          onChange={(e) =>
                            setFieldValue("endDate", e.target.value)
                          }
                        />
                      </FormControl>
                    </SimpleGrid>
                  </BrowserView>
                  <MobileView>
                    <Text variant="label">Fra</Text>
                    {isLoaded ? (
                      <StandaloneSearchBox>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            placeholder="Adresse"
                            name="origin"
                            value={origin}
                            onChange={(e) => setOrigin(e.target.value)}
                            onBlur={(e) => setOrigin(e.target.value)}
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                              setOrigin(e.target.value);
                            }}
                          />
                        </FormControl>
                      </StandaloneSearchBox>
                    ) : (
                      <Box>
                        <Spinner size="xl" />
                      </Box>
                    )}
                    <Text variant="label">Til</Text>
                    {isLoaded ? (
                      <StandaloneSearchBox>
                        <FormControl isRequired>
                          <Input
                            variant="standardTextInput"
                            type="text"
                            placeholder="Adresse"
                            name="destination"
                            value={destination}
                            onChange={(e) => setDestination(e.target.value)}
                            onBlur={(e) => setDestination(e.target.value)}
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                              setDestination(e.target.value);
                            }}
                          />
                        </FormControl>
                      </StandaloneSearchBox>
                    ) : (
                      <Box>
                        <Spinner size="xl" />
                      </Box>
                    )}
                    <Text variant="label">Start-dato</Text>
                    <FormControl isRequired>
                      <Input
                        variant="standardTextInput"
                        type="datetime-local"
                        name="startDate"
                        value={values.startDate}
                        onChange={(e) =>
                          setFieldValue("startDate", e.target.value)
                        }
                      />
                    </FormControl>
                    <Text variant="label">Ankomst-dato</Text>
                    <FormControl isRequired>
                      <Input
                        variant="standardTextInput"
                        type="datetime-local"
                        name="endDate"
                        value={values.endDate}
                        onChange={(e) =>
                          setFieldValue("endDate", e.target.value)
                        }
                      />
                    </FormControl>
                  </MobileView>
                  <Text variant="label" mb="-2">
                    Registreringsnummer
                  </Text>
                  <FormControl>
                    <AsyncSelect
                      defaultOptions
                      loadOptions={loadOptionsVehicles}
                      value={vehicleRegNumber}
                      onChange={(selectedOptions) =>
                        HandleSelectVehicle(selectedOptions)
                      }
                      styles={customStyles}
                    />
                  </FormControl>
                  {/* <Input
                    name="truckRegNumber"
                    type="string"
                    variant='standardTextInput'
                    onChange={(e) => (values.truckRegNumber = e.target.value)}
                  /> */}
                  <Text variant="label" mb="-1" textTransform="uppercase">
                    Ledig rom
                  </Text>

                  <SimpleGrid columns={3} columnGap="4">
                    
                    <Text variant="label">Lengde [cm]</Text>
                    <Text variant="label">Bredde [cm]</Text>
                    <Text variant="label">Høyde [cm]</Text>
                    
                    <FormControl isRequired>
                      <Input
                        name="availableLengthcm"
                        variant="standardTextInput"
                        value={values.availableLengthcm}
                        onChange={(e) =>
                          setFieldValue("availableLengthcm", e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <Input
                        name="availableWidthcm"
                        variant="standardTextInput"
                        value={values.availableWidthcm}
                        onChange={(e) =>
                          setFieldValue("availableWidthcm", e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl
                      isRequired
                      isInvalid={!IsPositive(values.availableHeightcm)}
                    >
                      <Input
                        variant="standardTextInput"
                        name="availableHeightcm"
                        value={values.availableHeightcm}
                        onChange={(e) =>
                          setFieldValue("availableHeightcm", e.target.value)
                        }
                      />
                      <FormErrorMessage>Må være positivt</FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>

                  <Text variant="label">Vekt [kg]</Text>
                  <FormControl isRequired>
                    <Input
                      name="availableWeightkg"
                      variant="standardTextInput"
                      value={values.availableWeightkg}
                      onChange={(e) =>
                        setFieldValue("availableWeightkg", e.target.value)
                      }
                    />
                  </FormControl>
                  <TextareaControl
                    name="note"
                    label="Ytterligere merknader"
                    helperText="Valgfritt - hvis du trenger å notere noe om dette oppdraget."
                    mt={4}
                  />
                  <SimpleGrid rowGap={8}>
                    <ButtonGroup mt={4}>
                      <SubmitButton variant="primary">Lagre</SubmitButton>
                      <ResetButton>Tilbakestill</ResetButton>
                    </ButtonGroup>
                    <Button
                      width="-moz-initial"
                      leftIcon={<MdKeyboardReturn />}
                      variant="return"
                      as="a"
                      href="/trips"
                    >
                      {" "}
                      Tilbake
                    </Button>
                  </SimpleGrid>
                </Box>

                <AspectRatio ratio={16 / 9}>
                  <Map
                    onRouteChange={(route: Route) =>
                      newRoute(route, setFieldValue)
                    }
                    key={`${origin}-${destination}`}
                    origin={origin}
                    destination={destination}
                  />
                </AspectRatio>
              </>
            )}
          </Formik>
        </SimpleGrid>
      }
    />
  );
}
