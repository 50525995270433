import { useState } from "react";
import {
  Box,
  Slider as ChakraSlider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
interface SliderProps {
  sliderValue: number;
  setSliderValue: (val: number) => void;
  ariaLabel: string;
}

function ParameterSlider({
  sliderValue,
  setSliderValue,
  ariaLabel,
}: SliderProps) {
  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };

  return (
    <Box pt={6} pb={2}>
      <ChakraSlider
        aria-label={ariaLabel}
        min={0}
        max={5}
        step={0.1}
        value={sliderValue}
        onChange={setSliderValue}
      >
        <SliderMark value={1} {...labelStyles}>
          1
        </SliderMark>
        <SliderMark value={2} {...labelStyles}>
          2
        </SliderMark>
        <SliderMark value={3} {...labelStyles}>
          3
        </SliderMark>
        <SliderMark value={4} {...labelStyles}>
          4
        </SliderMark>
        <SliderMark
          value={sliderValue}
          textAlign="center"
          bg="primary"
          color="white"
          mt="-8"
          ml="-5"
          w="12"
        >
          {sliderValue}
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack bgColor="primary" />
        </SliderTrack>
        <SliderThumb />
      </ChakraSlider>
    </Box>
  );
}

export default ParameterSlider;
