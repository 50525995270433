/**********************************************************
/ NAME: PasswordInput()
/********************************************************** 
 * SUMMARY: 
 * This function component renders a password input field with 
 * a show/hide button. It also validates the password and 
 * calls a function when the password changes.
 * 
/********************************************************** 
 * INPUT: 
 * It takes the following props:
 * - value: The current value of the password input field
 * - onChange: A function that is called when the password changes
 * - placeholder: The placeholder text for the password input field
 * 

/********************************************************** 
 * FUNCTIONS:
 * validatePassword(password: string) - Checks if the password is valid
 * handleClick() - Toggles the visibility of the password
 * handleChange(e: ChangeEvent<HTMLInputElement>) - Updates the password and checks if it is valid
 * 
/********************************************************** 
 * COMPONENTS:
 * - Input: A component that renders an input field
 * - InputGroup: A component that groups related input components
 * - InputRightElement: A component that renders an element on the right side of an input field
 * - Button: A button that toggles the visibility of the password when clicked
 * - FormControl: A component that provides context such as isInvalid and isRequired to form elements
 * - FormErrorMessage: A component that renders an error message
 * 
/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a password input field with a show/hide button.
/*********************************************************/
// Importing necessary libraries and components
import {
  Input,
  InputGroup,
  InputRightElement,
  Button,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useState, ChangeEvent } from "react";

// Defining the props for the PasswordInput component
interface PasswordInputProps {
  value: string;
  onChange: (newValue: string, isValid: boolean) => void;
  placeholder?: string;
}

// PasswordInput component
const PasswordInput: React.FC<PasswordInputProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  // Using hooks to get and set necessary states
  const [show, setShow] = useState(false);
  const [isPasswordValid, setPasswordValid] = useState(true);

  // Function to validate the password
  const validatePassword = (password: string) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLengthValid = password.length >= 6;

    return (
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSpecialCharacter &&
      isLengthValid
    );
  };

  // Function to toggle password visibility
  const handleClick = () => {
    setShow(!show);
  };

  // Function to handle password change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    const isValid = validatePassword(newPassword);
    setPasswordValid(isValid);
    onChange(newPassword, isValid);
  };

  // Render the component
  return (
    <FormControl isInvalid={!isPasswordValid}>
      <InputGroup>
        <Input
          variant="standardTextInput"
          type={show ? "text" : "password"}
          value={value}
          onChange={handleChange}
          placeholder={placeholder || "Password"}
        />
        <InputRightElement width="4.5rem" mt={4}>
          <Button h="1.75rem" size="sm" variant="verify" onClick={handleClick}>
            {show ? "skjul" : "vis"}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>
        {isPasswordValid
          ? ""
          : "Passordet må inneholde minst 6 tegn, inklussive spesialtegn, tall og store og små bokstaver."}
      </FormErrorMessage>
    </FormControl>
  );
};

// Exporting the component
export default PasswordInput;
