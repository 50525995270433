/**********************************************************
/ NAME: NeedMatchesTripsOverview()
/********************************************************** 
 * SUMMARY: 
 * Fetches the trips that need matches from the database and displays them
 * to the user in a grid format. It also provides a button to register a new trip.
 * 
/********************************************************** 
 * INPUT: 
 * None
 * 

/********************************************************** 
 * FUNCTIONS:
 * getNeedMatchesTrips() - Fetches the trips that need matches 
 * from the database
 * 
/********************************************************** 
 * COMPONENTS:
 * ProjectRow - Represents a row in the grid, displaying 
 * information about a trip
 * 
/********************************************************** 
 * RETURN:
 * Returns a jsx element with the grid of trips that need 
 * matches and a button to register a new trip.
/*********************************************************/
import { Button, Grid, Heading, Divider, Center, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getNeedMatchesTrips } from "../databasefunctions/trips";

import ProjectRow from "./UpComingTripRow";

import { GiTruck } from "react-icons/gi";
function NeedMatchesTripsOverview() {
  const [trips, setTrips] = useState<any>(null);

  useEffect(() => {
    if (!trips) {
      getNeedMatchesTrips()?.then((p) => setTrips(p));
    }
  }, [trips]);
  return (
    <>
      <Grid templateColumns="10% 80% 10%" gap={2}>
        <Icon as={GiTruck} w={10} h={10} />
        <Center>
          <Heading fontSize={"1.4rem"}>Ledig transport</Heading>
        </Center>
      </Grid>
      <Grid ml="-2" mb={6} templateColumns="32% 32% 36%" gap={2}>
        <Center>
          <Heading fontSize={"1.2rem"}> Dato</Heading>
        </Center>
        <Center>
          <Heading fontSize={"1.2rem"}> Fra</Heading>
        </Center>
        <Center>
          <Heading fontSize={"1.2rem"}>Match</Heading>
        </Center>
      </Grid>
      <Divider variant="thick" mt="-4" mb="2" width={"95%"}></Divider>
      {trips?.map((trip: any) => (
        <ProjectRow key={trip.id} trip={trip} />
      ))}
      <Center>
        <Button variant="primary" as="a" href="/create-trip" id="addTransportBtn">
          Registrer
        </Button>
      </Center>
    </>
  );
}
export default NeedMatchesTripsOverview;
