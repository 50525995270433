/**********************************************************
/ NAME: buttonStyles
/********************************************************** 
 * SUMMARY: 
 * buttonStyles is an object that contains the base styles, 
 * sizes, and variants for buttons used in the application. 
 * It includes styles for primary, giveOffer, and verify buttons.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any inputs.
 * 

/********************************************************** 
 * FUNCTIONS:
 * No functions are defined within this object.
 * 
/********************************************************** 
 * COMPONENTS:
 * No components are used within this object.
 * 
/********************************************************** 
 * RETURN:
 * Returns an object containing styles for buttons.
/*********************************************************/
export const buttonStyles = {
  baseStyle: {},

  sizes: {},

  variants: {
    primary: {
      bg: "primary",
      color: "white",
      boxShadow: "md",
      fontFamily: "arial",
      fontSize: "18",
      borderColor: "borderPrimary",
      borderWidth: "thin",

      // shadow: "2px 2px 6px rgba(0,0,0,0.3)",
      _hover: {
        color: "white",
        transform: "scale(1.02)",
        boxShadow: "lg",
      },
    },
    giveOffer: {
      bg: "secondary",
      color: "white",
      boxShadow: "md",
      fontFamily: "arial",
      //fontSize:"20",

      // shadow: "2px 2px 6px rgba(0,0,0,0.3)",
      _hover: {
        color: "white",
        transform: "scale(1.02)",
        boxShadow: "lg",
      },
    },
    verify: {
      bg: "header",
      color: "black",
      boxShadow: "md",
      fontFamily: "arial",
      fontSize: "16",
      borderWidth: "thin",

      // shadow: "2px 2px 6px rgba(0,0,0,0.3)",
      _hover: {
        color: "black",
        borderWidth: "thin",
        borderColor: "primary",
        boxShadow: "lg",
      },
    },
    update: {
      bg: "primary",
      color: "white",
      boxShadow: "md",
      borderWidth: "thin",
      //borderColor:"secondary",
      fontFamily: "arial",
      fontSize: "16",
      opacity: "0.8",

      // shadow: "2px 2px 6px rgba(0,0,0,0.3)",
      _hover: {
        bg: "gray.100",
        color: "primary",
        borderWidth: "thin",
        borderColor: "primary",
        boxShadow: "lg",
      },
    },
    return: {
      bg: "gray.200",
      color: "black",
      boxShadow: "md",

      fontFamily: "arial",
      fontSize: "16",

      // shadow: "2px 2px 6px rgba(0,0,0,0.3)",
      _hover: {
        bg: "gray.100",
        color: "black",
        borderWidth: "thin",
        borderColor: "secondary",
        boxShadow: "lg",
      },
    },
    notification: {
      bg: "header",
      color: "primary",

      fontFamily: "arial",
      fontSize: "28",

      // shadow: "2px 2px 6px rgba(0,0,0,0.3)",
      _hover: {
        bg: "header",
        color: "primary",
        borderWidth: "thin",
        borderColor: "primary",
        boxShadow: "lg",
      },
      _dark: {
        bg: "#1A202C",
      },
    },
  },

  defaultProps: {},
};
